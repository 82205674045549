import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Modal, Container, Card, CardBody, Input, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';
import Dropzone from "react-dropzone";

// components
import Header from 'components/views/Utils/Header';
import ModalLandingPreview from 'components/views/Utils/ModalLandingPreview';
// routes
import { COMAPANY, COMAPANY_LANDING } from "routes/server/paths";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const CompanyDetail = ({ company, setCompany, mutationCompanyUpdate, showModal, setShowModal, toggleModal }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoModified, setLogoModified] = useState(false);
  const [backgroundImageModified, setBackgroundImageModified] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [titleColor, setTitleColor] = useState('#c55654');
  const [secondaryColor, setSecondaryColor] = useState('#c55654');
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [bgHeight, setBgHeight] = useState(500);

  useEffect(() => {
    // console.log("company", company);
    if (company) {
      setTitleColor(company.first_color === null ? '#c55654' : company.first_color);
      setSecondaryColor(company.second_color === null ? '#c55654' : company.second_color);
      if (company.logo) {
        setLogo({
          preview: `${COMAPANY}${company.id}${COMAPANY_LANDING}${company.logo}`
        });
      } else {
        setLogo(null);
      }
      if (company.background_img) {
        setBackgroundImage({
          preview: `${COMAPANY}${company.id}${COMAPANY_LANDING}${company.background_img}`
        });
      } else {
        setBackgroundImage(null);
      }
    }

  }, [company]);

  function handleAcceptedFiles(acceptedFiles, fileRejections, uploadType) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      if (uploadType === 'logo') {
        setLogo(image);
        setLogoModified(true);
      } else {
        setBackgroundImage(image);
        setBackgroundImageModified(true);
      }
    }
  }

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (logo == null) {
      useToast("error", t("Message.alert"), t("Message.company.error.logo"));
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("id", company.id);
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("phone", values.phone);
    data.append("web", values.web);
    data.append("cif", "12345678");
    logoModified && data.append("logo", logo);
    backgroundImageModified && data.append("background_img", backgroundImage);
    data.append("first_color", titleColor);
    data.append("second_color", secondaryColor);

    mutationCompanyUpdate.mutate({ token: user.access_token, data });
    setLoading(false);
    setLogo(null);
    setBackgroundImage(null);
    setLogoModified(false);
    setBackgroundImageModified(false);
    toggleModal();
    
    formRef.reset();
  }

  return (
    <>
      <Modal
        size="xl"
        isOpen={showModal}
        toggle={() => {
          setCompany(null);
          setLogoModified(false);
          setBackgroundImageModified(false);
          toggleModal()
        }}
      >
        <div className="modal-content bg-color-gray">
          <div className="modal-header">
            <button
              onClick={() => {
                setCompany(null);
                setLogoModified(false);
                setBackgroundImageModified(false);
                setShowModal(false);
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Container fluid className="content">
              <Row>
                <Col className="col-sm-12 col-md-12 offset-md-0">
                  <Header title={t("CompanyDetail.title")} modal={true} />
                  <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
                    <Card className="card-container shadow-none">
                      <Row className="g-0 mt-5 mb-4">
                        {
                          company && 
                          <>
                            <Col lg={12}>
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                                  <div className="mb-4">
                                    <AvField
                                      name="name"
                                      value={company.name}
                                      className="form-control"
                                      placeholder={t("Placeholder.name")}
                                      type="text"
                                      validate={{
                                        required: { value: true, errorMessage: t("Validation.company_name") },
                                      }}
                                    />
                                  </div>
                                  <div className="mb-4">
                                    <AvField
                                      name="phone"
                                      value={company.phone}
                                      className="form-control"
                                      placeholder={t("Placeholder.phone")}
                                      type="number"
                                      pattern="^\d{9}$"
                                      errorMessage={t("Validation.not_phone")}
                                      validate={{
                                        required: { value: true, errorMessage: t("Validation.phone") },
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                                  <div className="mb-4">
                                    <AvField
                                      name="email"
                                      value={company.email}
                                      className="form-control"
                                      placeholder={t("Placeholder.email")}
                                      type="email"
                                      errorMessage={t("Validation.not_email")}
                                      validate={{
                                        required: { value: true, errorMessage: t("Validation.email") },
                                        email: true
                                      }}
                                    />
                                  </div>
                                  <div className="mb-4">
                                    <AvField
                                      name="web"
                                      value={company.web}
                                      className="form-control"
                                      placeholder={t("Placeholder.web")}
                                      type="text"
                                      pattern="/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi"
                                      errorMessage={t("Validation.not_url")}
                                      validate={{
                                        required: { value: false },
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className='mb-2'>
                                <h5 className="MontSemiBold lh-1 text-black text-center">{t("Company.landing")}</h5>
                                <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.landing_sub")}</h6>
                              </Row>
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                                  <div className="mb-4 d-flex justify-content-center" style={{ height: 230 }}>
                                    {
                                      logo ?
                                        <>
                                          <div style={{ position: "absolute", top: 40, marginLeft: 100, zIndex: 2 }}>
                                            <i
                                              onClick={() => setLogo(null)}
                                              className="mdi mdi-close circle-video"
                                              style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                          </div>
                                          <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0 }}>
                                            <img
                                              id="logo"
                                              style={{ width: '100px', height: '100px' }}
                                              className="bg-light"
                                              alt={logo.name}
                                              src={logo.preview}
                                            />
                                          </div>
                                        </>
                                        :
                                        <>
                                          <Dropzone
                                            maxFiles={1}
                                            accept="image/jpeg, image/png"
                                            onDrop={(acceptedFiles, fileRejections) => {
                                              handleAcceptedFiles(acceptedFiles, fileRejections, "logo");
                                            }}
                                          >
                                            {({ getRootProps, getInputProps }) => (
                                              <div className="dropzone" style={{ width: '100%' }}>
                                                <div
                                                  className="dz-message needsclick mt-0 p-1"
                                                  {...getRootProps()}
                                                >
                                                  <input {...getInputProps()} />
                                                  <div className="mb-0">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                  </div>
                                                  <h4>{t("Company.drag_logo")}</h4>
                                                  <p className='mx-2 mb-0 MontSemiBold fs-6'>{t("VideoNew.Step9.landing.logo.format.title")}</p>
                                                  <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.logo.format.format")}</p>
                                                  <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.logo.format.proportions")}</p>
                                                </div>
                                              </div>
                                            )}
                                          </Dropzone>
                                        </>
                                    }
                                  </div>
                                  <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.title_color_warning")}</h6>
                                  <div className="mb-4 p-3 border-radius-1" style={{ backgroundColor: titleColor + "3D" }}>
                                    <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                                      <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                                        <AvField
                                          type="color"
                                          name="title_color"
                                          defaultValue={titleColor}
                                          value={titleColor}
                                          className="p-0"
                                          style={{ height: 34, width: 34 }}
                                          onChange={(event) => setTitleColor(event.target.value)}
                                        />
                                      </Col>
                                      <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                                        <p className='m-0' style={{ color: titleColor }}>{t("Company.title_color")}</p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                                <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                                  <div className="mb-4 d-flex justify-content-center" style={{ height: 230 }}>
                                    {
                                      backgroundImage ?
                                        <>
                                          <div style={{ position: "absolute", top: 40, right: 0, zIndex: 2 }}>
                                            <i
                                              onClick={() => setBackgroundImage(null)}
                                              className="mdi mdi-close circle-video"
                                              style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                          </div>
                                          <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0, width: '100%' }}>
                                            <img
                                              id="bg-image"
                                              style={{ width: '100%', height: '100px' }}
                                              className="bg-light"
                                              alt={backgroundImage.name}
                                              src={backgroundImage.preview}
                                            />
                                          </div>
                                        </>
                                        :
                                        <>
                                          <Dropzone
                                            maxFiles={1}
                                            accept="image/jpeg, image/png"
                                            onDrop={(acceptedFiles, fileRejections) => {
                                              handleAcceptedFiles(acceptedFiles, fileRejections, "background");
                                            }}
                                          >
                                            {({ getRootProps, getInputProps }) => (
                                              <div className="dropzone" style={{ width: '100%' }}>
                                                <div
                                                  className="dz-message needsclick mt-0 p-1"
                                                  {...getRootProps()}
                                                >
                                                  <input {...getInputProps()} />
                                                  <div className="mb-0">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                  </div>
                                                  <h4>{t("Company.drag_background")}</h4>
                                                  <p className='mx-2 mb-0 MontSemiBold fs-6'>{t("VideoNew.Step9.landing.background.format.title")}</p>
                                                  <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.background.format.format")}</p>
                                                  <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.background.format.proportions")}</p>
                                                </div>
                                              </div>
                                            )}
                                          </Dropzone>
                                        </>
                                    }
                                  </div>
                                  <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.secondary_color_warning")}</h6>
                                  <div className="mb-4 p-3 border-radius-1" style={{ backgroundColor: secondaryColor + "3D" }}>
                                    <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                                      <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                                        <AvField
                                          type="color"
                                          name="secondary_color"
                                          defaultValue={secondaryColor}
                                          value={secondaryColor}
                                          className="p-0"
                                          style={{ height: 34, width: 34 }}
                                          onChange={(event) => setSecondaryColor(event.target.value)}
                                        />
                                      </Col>
                                      <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                                        <p className='m-0' style={{ color: secondaryColor }}>{t("Company.secondary_color")}</p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {/* Landing */}
                            <div className="text-center">
                              <button className='border-none bg-color-transparent text-decoration-underline text-red' onClick={(e) => {
                                e.preventDefault();
                                setBgHeight(backgroundImage != null ? document.getElementById("bg-image").naturalHeight : 500);
                                setShowModalPreview(true);
                              }}>{t("Company.preview")}</button>
                            </div>
                          </>
                        }
                      </Row>
                    </Card>
                    <div className="mt-3 mb-4 text-center">
                      <Button onClick={() => toggleModal()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                      <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                        <Spinner
                          style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border" />
                        {t("CompanyDetail.save")}
                      </Button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Modal>
      <ModalLandingPreview
        showModal={showModalPreview}
        setShowModal={setShowModalPreview}
        titleColor={titleColor}
        secondaryColor={secondaryColor}
        logo={logo?.preview}
        backgroundImage={backgroundImage?.preview}
        bgHeight={bgHeight} />
    </>
  );
}

export default CompanyDetail;
