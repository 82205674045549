import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from "react-query";
import { Container, Row, Col, Card, CardBody, Button, Spinner, Modal } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import ModalLoading from "components/views/Utils/ModalLoading";
import ModalVideoRecord from "components/views/Utils/ModalVideoRecord";
import ModalPhotoCamera from "components/views/Utils/ModalPhotoCamera";
// services
import { PresentationAdd } from 'store/presentation/PresentationService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const ModalPresentationNew = ({ type, showModal, setShowModal, toggleModal }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const [showModalVideoRecord, setShowModalVideoRecord] = useState(false);
  const [showModalPhotoCamera, setShowModalPhotoCamera] = useState(false);
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
    maxFiles: 1
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      setImage(image);
    }
  }, [acceptedFiles]);

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_video"));
      return;
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setVideo(files[0]);
  }

  const mutationPresentationAdd = useMutation('CREATE_PRESENTATION', PresentationAdd, {
    onError: (error) => {
      console.log("error", error);
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.presentation.error.add"));
    },
    onSuccess: (data) => {
      console.log("ok");
      setLoading(false);
      setVideo(null);
      setImage(null);
      formRef.reset();
      useToast("success", t("Message.info"), t("Message.presentation.success.add"));
      queryClient.refetchQueries(['PRESENTATIONS', { token: user.access_token }]);
      setShowModal(false);
    }
  });

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (video == null) {
      useToast("error", t("Message.alert"), t("Message.presentation.error.load_video"));
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("video", video);
    image != null && data.append("thumbnail", image);
    data.append("title", values.name);
    data.append("description", "");
    data.append("type", type);

    if (document.getElementById('video_player').videoHeight > document.getElementById('video_player').videoWidth) {
      data.append("portrait", 1);
    } else {
      data.append("portrait", 0);
    }

    mutationPresentationAdd.mutate({ token: user.access_token, presentation: data });
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Header title={`${t("ModalPresentationNew.title")} ${type == "Open" ? t("ModalPresentationNew.open") : t("ModalPresentationNew.close")} `} modal={true} />
          <Container fluid className="content">
            <Row>
              <Col className="col-sm-12 col-md-12 offset-md-0">
                <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
                  <Card className="card-container shadow-none">
                    <Row className="g-0 justify-content-evenly mt-4">
                      <Col className="col-12 col-sm-12 col-md-5 text-center">
                        {
                          video ?
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2" id="outer" style={{ padding: 0 }}>
                              <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                                <i
                                  onClick={() => setVideo(null)}
                                  className="mdi mdi-close circle-video"
                                  style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                              </div>
                              <video id="video_player" src={video.preview} className="video" controls width="100%" height="300px"></video>
                            </div>
                          :
                          <>
                            <Dropzone
                              maxFiles={1}
                              accept="video/mp4"
                              onDrop={(acceptedFiles, fileRejections) => {
                                handleAcceptedFiles(acceptedFiles, fileRejections)
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>{t("Message.drop.drag_video")}</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <Button onClick={() => setShowModalVideoRecord(true)} className="btn-shadow btn-general bg-color-darkgray color-white mt-3" color="app-green">Grábate</Button>
                          </>
                        }
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-5">
                        <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                          <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("PresentationNew.name")}</h5>
                          <CardBody>
                            <Row>
                              <Col className="col-12">
                                <div className="mb-4">
                                  <AvField
                                    name="name"
                                    className="form-control"
                                    placeholder={t("Placeholder.name")}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: t("Validation.name") },
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                          <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("PresentationNew.image")}</h5>
                          <CardBody className='text-center'>
                            {
                              image ?
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2" style={{ padding: 0 }}>
                                  <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                                    <i
                                      onClick={() => setImage(null)}
                                      className="mdi mdi-close circle-video"
                                      style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                  </div>
                                  <img
                                    style={{ width: '100%' }}
                                    className="bg-light"
                                    alt={image.name}
                                    src={image.preview}
                                  />
                                </div>
                                :
                                <>
                                  <Row className="justify-content-center">
                                    <Col className="col-12 col-sm-12 col-md-8 border-dashed border-bottom border-top m-3 bg-color-white d-flex p-5 justify-content-center align-items-center">
                                      <div className="text-center">
                                        <input {...getInputProps()} />
                                        <Button onClick={() => open()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("PresentationNew.select")}</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Button onClick={() => setShowModalPhotoCamera(true)} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">Foto</Button>
                                </>
                            }
                            {/* <Row className="justify-content-center">
                              <Col className="col-12 col-sm-12 col-md-8 border-dashed border-bottom border-top m-3 bg-color-white d-flex p-5 justify-content-center align-items-center">
                                <div className="text-center">
                                  <input {...getInputProps()} />
                                  <Button onClick={() => open()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("PresentationNew.select")}</Button>
                                </div>
                              </Col>
                            </Row>
                            {
                              image &&
                              <Row className="align-items-center justify-content-center text-center">
                                <Col className="col-2 offset-md-0">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={image.name}
                                    src={image.preview}
                                  />
                                </Col>
                                <Col className="col-12">
                                  {image.name}
                                  <p className="mb-0">
                                    <strong>{image.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            } */}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                  <div className="mt-3 mb-4 text-center">
                    <Button onClick={() => setShowModal(false)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                    <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                      <Spinner
                        style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border" />
                      {t("PresentationNew.save")}
                    </Button>
                  </div>
                </AvForm>
              </Col>
            </Row>
            <ModalLoading
              title={t("Message.video.loading.title")}
              subtitle={t("Message.video.loading.subtitle")}
              showModal={loading} />
            <ModalVideoRecord
              setVideo={setVideo}
              showModal={showModalVideoRecord}
              setShowModal={setShowModalVideoRecord} />
            <ModalPhotoCamera
              setImage={setImage}
              showModal={showModalPhotoCamera}
              setShowModal={setShowModalPhotoCamera} />
          </Container>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPresentationNew;
