import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, CardText, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';
// images
import email from "assets/images/app/video/email.svg";
import link from "assets/images/app/video/link.svg";
import sms from "assets/images/app/video/sms.svg";
// routes
import { BASE_URL, LANDING, DASHBOARD } from 'routes/app/paths';

const Step11 = ({ back, next, video }) => {
  const history = useHistory();
  const { t } = useTranslation();

  function copyToClipboard(link) {
    const tempInput = document.createElement('input');
    tempInput.value = link;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }

  function sendEmail(link) {
    console.log(link);
    window.location.href = `mailto:direccion@destinatario.com?subject=Asunto%20con%20espacios&body=${link}`;
  }

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={4} />
      <Col className="col-sm-12 col-lg-1 col-12"></Col>
      <Col className="col-sm-12 col-12 col-md-10 offset-md-0">
        <Header title={t("VideoNew.Step11.title")} />
        <Card className="card-container shadow-none">
          <Row className="g-0 mb-4 justify-content-center mt-3">
            <Col className="col-sm-12 col-md-4 offset-md-4 justify-content-center" style={{ display: "contents" }}>
              <div className="m-4" onClick={() => sendEmail(`${BASE_URL}${LANDING}/${video.token}`)} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 160, minHeight: 150, maxWidth: 160 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center">
                      <img
                        src={email}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step11.email")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col className="col-sm-12 col-md-4 offset-md-4 justify-content-center" style={{ display: "contents" }}>
              <div className="m-4" onClick={() => copyToClipboard(`${BASE_URL}${LANDING}/${video.token}`)} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 160, minHeight: 150, maxWidth: 160 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center">
                      <img
                        src={link}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step11.copy")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <h5 className="text-black text-center">{t("VideoNew.Step11.finish1")}<br />{t("VideoNew.Step11.finish2")}</h5>
          <div className="mt-5 mb-5 text-center">
            <Button onClick={() => window.location.reload(true)} className="btn-general bg-color-primary" color="app-green">{t("VideoNew.Step11.new")}</Button>
            <Button onClick={() => history.push(DASHBOARD)} className="btn-general-black bg-color-gray ms-3 text-black px-5" color="app-green">{t("VideoNew.Step11.home")}</Button>
          </div>
          </Card>
      </Col>
      <Col className="col-sm-12 col-lg-1 col-12"></Col>
    </Row>
  );
}

export default Step11;
