import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="bg-white" style={{position: 'absolute', width: '100%',  }}>
        <p className="line-100-black"></p>
        <Row className="g-0 ps-5">
          <Col xl={6}>
            <p className="MontSemiBold">{t("Footer.copyright")}</p>
          </Col>
          <Col xl={6}>
            <Row className="g-0">
              <Col xl={4}>
                <a href="https://virtualvendorvideo.com/politica-de-privacidad" target="_blank" className="MontSemiBold mb-0 text-black text-decoration-none d-block">{t("Footer.privacy")}</a>
              </Col>
              <Col xl={4}>
                <a href="https://virtualvendorvideo.com/aviso-legal" target="_blank" className="MontSemiBold mb-0 text-black text-decoration-none d-block">{t("Footer.conditions")}</a>
              </Col>
              <Col xl={4}>
                <a href="https://virtualvendorvideo.com/politica-de-cookie" target="_blank" className="MontSemiBold mb-0 text-black text-decoration-none d-block">{t("Footer.cookies")}</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Footer
