import React from 'react';

// components
import RangeInput from "components/views/Utils/Edition/components/RangeInput";

const VideoControl = ({ videoRef, muted, playing, progress, duration, setProgress, setPlaying, setMuted, setSeeking }) => {

  function getTime(ms) {
    var date = new Date(ms);
    var time = [];

    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var seconds = date.getUTCSeconds();

    if (hours >= 1) {
      time.push(hours >= 10 ? hours : '0' + hours);
    }
    time.push(minutes >= 10 ? minutes : '0' + minutes);
    time.push(seconds >= 10 ? seconds : '0' + seconds);

    return time.join(':');
  }

  // function handleSeekMouseDown(e) {
  //   setSeeking(true);
  // }

  function handleSeekChange(value) {
    setProgress(parseFloat(value));
    videoRef.current.seekTo(parseFloat(value));
  }

  // function handleSeekMouseUp(e) {
  //   setSeeking(false);
  //   videoRef.current.seekTo(parseFloat(e.target.value))
  // }

  return (
    <div className='controls d-flex align-items-center'>
      <div className="ms-1" style={{ display: "inline", height: 26 }}>
        {
          playing ?
            <i className="mdi mdi-pause" onClick={() => { setPlaying(false) }} style={{ fontSize: 18, cursor: "pointer", color: "white" }}></i>
            : <i className="mdi mdi-play" onClick={() => { setPlaying(true) }} style={{ fontSize: 18, cursor: "pointer", color: "white" }}></i>
        }
      </div>
      <div className='count text-white text-center' style={{ width: 100 }}>{`${getTime(progress * 1000)}/${getTime(duration * 1000)}`}</div>
      <RangeInput
        min="0"
        max={duration}
        defaultValue={progress}
        onChange={handleSeekChange}
      // onMouseDown={handleSeekMouseDown}
      // onMouseUp={handleSeekMouseUp}
      />
      <div className="ms-1 me-1" style={{ display: "inline", float: "right", height: 26 }}>
        {
          muted ?
            <i className="mdi mdi-volume-mute" onClick={() => { setMuted(false) }} style={{ fontSize: 18, cursor: "pointer", color: "white" }}></i>
            : <i className="mdi mdi-volume-source" onClick={() => { setMuted(true) }} style={{ fontSize: 18, cursor: "pointer", color: "white" }}></i>
        }
      </div>
    </div>
  );
}

export default VideoControl;
