import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Card, CardBody, Row, Col } from "reactstrap";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

// services
import { CategoryListByProduct, CategoryClipsList } from 'store/category/CategoryService';
import { ProductsList, ProductClipsList } from "store/product/ProductService";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const CategoryBar = ({ originalList, setList, inputSearchRef, setSelectCategoryList, hideByStatus=false }) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);

  const queryProductsList = useQuery(['PRODUCTS', { token: user.access_token }], ProductsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_all"));
    },
    onSuccess: (data) => {
      const none = [{
        label: "Ninguna",
        value: 0
      }];
      if (hideByStatus) {
        const newProducts = data.data.data.filter((e) => (e.status == 1)).map(e => {
          return {
            label: e.name,
            value: e.id
          }
        });
        setProducts(none.concat(newProducts));
      } else {
        const newProducts = data.data.data.map((e, i) => {
          return {
            label: e.name,
            value: e.id
          }
        });
        setProducts(none.concat(newProducts));
      }
    }
  });

  async function handleSelectProduct(selectedProduct) {
    setSelectedProduct(selectedProduct);
    setSelectedCategory({
      label: t("Category.none"),
      value: 0
    });
    if (selectedProduct.value === 0) {
      setList(originalList);
      setCategories(null);
      setSelectCategoryList(null);
    } else {
      const params = {
        token: user.access_token,
        id: selectedProduct.value
      }
      const productClips = await queryClient.fetchQuery([{}, params], ProductClipsList);
      if (productClips.status === 200) {
        const clips = productClips.data.data;
        const newClips = originalList.map(clip => {
          if (clips.find(e => (clip.id == e.clip_id))) {
            return { ...clip };
          } else {
            return;
          }
        }).filter(e => e !== undefined);
        setList(newClips);
        setSelectCategoryList(newClips);
        inputSearchRef.value = "";
      }
      const data = await queryClient.fetchQuery([{},params], CategoryListByProduct);
      if (data.status === 200) {
        const none = [{
          label: t("Category.none"),
          value: 0
        }];
        if (hideByStatus) {
          const newCategories = data.data.data.filter((e) => (e.status == 1)).map(e => {
            return {
              label: e.name,
              value: e.id
            }
          });
          setCategories(none.concat(newCategories));
        } else {
          const newCategories = data.data.data.map((e, i) => {
            return {
              label: e.name,
              value: e.id
            }
          });
          setCategories(none.concat(newCategories));
        }
        inputSearchRef.value = "";
      }
    }
  }

  async function handleSelectCategory(selectedCategory) {
    setSelectedCategory(selectedCategory);
    if (selectedCategory.value === 0) {
      const params = {
        token: user.access_token,
        id: selectedProduct.value
      }
      const productClips = await queryClient.fetchQuery([{}, params], ProductClipsList);
      if (productClips.status === 200) {
        const clips = productClips.data.data;
        const newClips = originalList.map(clip => {
          if (clips.find(e => (clip.id == e.clip_id))) {
            return { ...clip };
          } else {
            return;
          }
        }).filter(e => e !== undefined);
        setList(newClips);
        setSelectCategoryList(newClips);
        inputSearchRef.value = "";
      }
      // setList(originalList);
      // setCategories(null);
      // setSelectCategoryList(null);
      // setSelectedCategory(null);
    } else {
      const params = {
        token: user.access_token,
        id: selectedCategory.value
      }
      const data = await queryClient.fetchQuery([{}, params], CategoryClipsList);
      if (data.status === 200) {
        const categoryClip = data.data.data;
        const newClips = originalList.map(clip => {
          if (categoryClip.find(e => (clip.id == e.clip_id))) {
            return { ...clip };
          } else {
            return;
          }
        }).filter(e => e !== undefined);
        setList(newClips);
        setSelectCategoryList(newClips);
        inputSearchRef.value = "";
      }
    }
  }

  return (
    <Row className="g-0 justify-content-center mb-2">
      <Col className="col-12 col-sm-12 col-md-4 ps-1">
        {/* <Sort list={list} setList={setList} inside={inside}/> */}
        <Card className="shadow-none mb-0 bg-transparent">
          {/* <CardBody> */}
            <Select
              value={selectedProduct}
              placeholder={t("Placeholder.products")}
              onChange={(e) => {
                handleSelectProduct(e)
              }}
              options={products}
              classNamePrefix="select2-selection"
            />
          {/* </CardBody> */}
        </Card>
      </Col>
      <Col className="col-12  col-sm-12 col-md-4 ps-1">
        {/* <Search list={originalList} setList={setList} setInputRef={setInputRef} inside={inside} /> */}
        <Card className="shadow-none mb-0 bg-transparent">
          {/* <CardBody> */}
            <Select
              value={selectedCategory}
              placeholder={t("Placeholder.categories")}
              onChange={(e) => {
                handleSelectCategory(e)
              }}
              options={categories}
              classNamePrefix="select2-selection"
            />
          {/* </CardBody> */}
        </Card>
      </Col>
    </Row>
  );
}

export default CategoryBar;
