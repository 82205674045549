import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Cookie from "components/views/Utils/Cookie/Cookie";
// hooks
import { useToast } from "hooks/useToast";
// services
import { LandingGet } from 'store/landing/LandingService';
import { StatisticsUpdateCTA, StatisticsUpdateDownload } from 'store/statistics/StatisticsService';
// routes
import { COMAPANY, COMAPANY_OUTPUTS, COMAPANY_DOCS, COMAPANY_LANDING } from "routes/server/paths";
// images
import logo from "assets/images/app/landing/ford/brand/brand@3x.png";
import oval from "assets/images/app/landing/ford/oval/oval.png";
// config
import { encryptStorage } from 'config/encryptStorage';

const COOKIE = 'COOKIE';

const Landing = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [video, setVideo] = useState(null);
  const [client, setClient] = useState(null);
  const [cta, setCta] = useState(null);
  const [cookie, setCookie] = useState(encryptStorage.getItem(COOKIE));
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    console.log(cookie);
  }, [cookie]);

  const queryLandingGet = useQuery(['LANDING_GET', { token }], LandingGet, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.load"));
    },
    onSuccess: (data) => {
      setVideo(data.data.data.video);
      setClient(data.data.data.client);
      setCta(data.data.data.cta);
    }
  });
  const mutationStatisticsUpdateCTA = useMutation('UPDATE_STATISTICS_CTA', StatisticsUpdateCTA);
  const mutationStatisticsUpdateDownload = useMutation('UPDATE_STATISTICS_DOWNLOAD', StatisticsUpdateDownload);
  
  function handleClickCTA(type, destiny) {
    mutationStatisticsUpdateCTA.mutate({ video_id: video.id });
    switch (type) {
      case "url":
        window.open(destiny, '_blank', 'noopener,noreferrer')
        break;
      case "phone":
        location.href = `tel:${destiny}`
        break;
      case "whatsapp":
        window.open(`https://wa.me/${destiny}`, '_blank', 'noopener,noreferrer')
        break;
      case "email":
        location.href = `mailto:${destiny}?subject=Asunto%20con%20espacios&body=Escribir%20mensaje`
        break;
      default:
        break;
    }
  }

  function handleClickDownload(destiny) {
    mutationStatisticsUpdateDownload.mutate({ video_id: video.id });
    location.href = destiny;
  }

  return (
    <div>
      <MetaTags>
        <title>{t("Landing.meta")}</title>
      </MetaTags>
      {
        (video && client) &&
        <>
          <div className="main-content" style={{
            backgroundImage: `url(${
              (video.background_img !== null && video.background_img !== "undefined") ? 
                `${COMAPANY}${video.user.company_id}${COMAPANY_LANDING}${video.background_img}` 
              : (
                  (video.user.company.background_img !== null && video.user.company.background_img !== "undefined") ? 
                  `${COMAPANY}${video.user.company_id}${COMAPANY_LANDING}${video.user.company.background_img}`
                  : ''
                ) 
            })`,
            backgroundPosition: isMobile ? 'top' : 'bottom',
            backgroundSize: isMobile ? 'cover' : 'contain',
            backgroundRepeat: 'no-repeat',
            height: 500,
            position: "absolute",
            bottom: 1,
            width: '100%'
          }}></div>
          <Container fluid className="content">
            <Row className="g-0 text-center">
              <span className="logo-lg">
                {
                  (video.user.company.logo !== null && video.user.company.logo !== "undefined" && video.landing_logo !== null && video.landing_logo !== "undefined") &&
                  <span className="logo-lg">
                    <img src={`${COMAPANY}${video.user.company_id}${COMAPANY_LANDING}${video.user.company.logo}`}
                      className=""
                      alt=""
                      height="100"
                      width="100" />
                  </span>
                }
                <img src={
                    (video.landing_logo !== null && video.landing_logo !== "undefined") ? 
                      `${COMAPANY}${video.user.company_id}${COMAPANY_LANDING}${video.landing_logo}` 
                    : (
                      (video.user.company.logo !== null && video.user.company.logo !== "undefined") ?
                        `${COMAPANY}${video.user.company_id}${COMAPANY_LANDING}${video.user.company.logo}`
                        : ''
                      ) 
                  }
                  alt=""
                  height="100" 
                  width="100" />
              </span>
            </Row>
            <Card className="rounded-3" style={{ marginTop: 150 }}>
              <Row className="g-0 d-content">
                <Col className="col-lg-6 col-md-6 col-sm-12 mx-auto" style={{ width: 720 }}>
                  <Row className="g-0 text-center" style={{ marginTop: -120 }}>
                    <div className="col-lg-12 col-md-12 col-sm-12" id="outer" style={{ padding: 0 }}>
                      <video src={`${COMAPANY}${video.user.company_id}${COMAPANY_OUTPUTS}${video.video}`} className="landing-video" controls></video>
                    </div>
                  </Row>
                  <Row className="g-0 mb-5 text-center">
                    <h1 className="MontBold font-size-lg lh-1 pt-5 mb-4" style={{ color: video.first_color != null ? video.first_color : video.user.company.first_color }}>{`${t("Landing.hello")}${client !== null ? `, ${client.name.trim()}!` : "!"}`}</h1>
                    {
                      video.default_text === null ?
                        <h4 className="lh-2" style={{ color: video.first_color != null ? video.first_color : video.user.company.first_color }}>{t("Landing.title")}</h4>
                      :
                        `${video.default_text}`
                    }
                  </Row>
                  <Row className="g-0 mb-4 justify-content-center col-md-12">
                    {
                      cta &&
                      cta.map((e, i) => {
                        console.log(e);
                        switch (e.type) {
                          case "url":
                            return (
                              <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                                <Button onClick={handleClickCTA.bind(this, "url", e.destiny)} className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: video.second_color != null ? video.second_color : video.user.company.second_color }}>{e.action}</Button>
                              </Col>
                            )
                          case "phone":
                            return (
                              <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                                <Button onClick={handleClickCTA.bind(this, "phone", e.destiny)} className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: video.second_color != null ? video.second_color : video.user.company.second_color }}>{e.action}</Button>
                              </Col>
                            )
                          case "whatsapp":
                            return (
                              <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                                <Button onClick={handleClickCTA.bind(this, "whatsapp", e.destiny)} className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: video.second_color != null ? video.second_color : video.user.company.second_color }}>{e.action}</Button>
                              </Col>
                            )
                          case "email":
                            return (
                              <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                                <Button onClick={handleClickCTA.bind(this, "email", e.destiny)} className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: video.second_color != null ? video.second_color : video.user.company.second_color }}>{e.action}</Button>
                              </Col>
                            )
                          default:
                            break;
                        }
                      })
                    }
                  </Row>
                  <Row className="g-0 ms-5 me-5 mb-5">
                    {/* <p className="line-100-black mt-5"></p> */}
                  </Row>
                  {
                    video.zip !== null &&
                      <Row className="g-0 ms-5 me-5 mb-5 text-center justify-content-center">
                        <h5 className="MontSemiBold lh-2" style={{ color: video.second_color != null ? video.second_color : video.user.company.second_color }}>{t("Landing.zip_message")}</h5>
                          <button 
                            onClick={handleClickDownload.bind(this, `${COMAPANY}${video.user.company_id}${COMAPANY_DOCS}${video.zip}`)} 
                            className="MontSemiBold bg-color-white align-self-center mt-2 p-2" 
                            style={{ 
                              fontSize: 16, 
                              width: 220,  
                              border: `1px solid ${video.second_color != null ? video.second_color : video.user.company.second_color}`,
                              color: video.second_color != null ? video.second_color : video.user.company.second_color,
                              borderRadius: '0.25rem'
                            }}>
                            {t("Landing.download")}
                            <i className="mdi mdi-download ms-1" style={{ fontSize: 24, cursor: "pointer" }}>
                          </i>
                        </button>
                      </Row>
                  }
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      }
      {
        !cookie &&
        <Cookie setCookie={setCookie} />
      }
    </div>
  );
}

export default Landing;
