import React from 'react';
import { Row, Col, Modal, Progress } from "reactstrap";

// components
import Header from 'components/views/Utils/Header';

const ModalLoading = ({ title, subtitle, showModal }) => {

  return (
    <Modal
      size="md"
      isOpen={showModal}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header 
                modal={true}
                title={title}
                subtitle={subtitle} />
              <Row className="g-0 justify-content-center mb-5">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <Progress
                    value={100}
                    style={{ width: '100%' }}
                    animated
                  ></Progress>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalLoading;
