import { useEffect } from 'react';

// hooks
import useAuthContext from 'hooks/useAuthContext';
// routes
import { LOGIN, HOME } from 'routes/app/paths';

const Logout = () => {
  const { logout, isAuthenticated } = useAuthContext();

  useEffect(() => {
    logout();
  }, [])

  useEffect(() => {
    if (!isAuthenticated) {
      window.location.href = HOME;
    }
  }, [isAuthenticated]);

  return (<></>);
}

export default Logout;
