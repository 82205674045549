import React, { useState } from 'react';
import { useMutation } from "react-query";
import { Row, Col, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import WizardStep from "components/views/Video/components/WizardStep";
import ModalVideoName from "components/views/Video/components/Send/components/ModalVideoName";
import Header from 'components/views/Utils/Header';
// services
import { VideoDelete } from 'store/video/VideoService';
// routes
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";
// hook
import { useToast } from "hooks/useToast";
import useAuthContext from 'hooks/useAuthContext';

const Step10 = ({ back, next, video, setVideo, name, setName, people, info }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [showModal, setShowModal] = useState(false);

  console.log("data", info);

  const mutationVideoDelete = useMutation('VIDEO_DELETE', VideoDelete, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.delete"));
    },
    onSuccess: (data) => {
      setVideo(null);
      back(10);
    }
  });

  function handleBack(id) {
    if (video != null) {
      mutationVideoDelete.mutate({ token: user.access_token, id });
    } else {
      back(10);
    }
  }

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleDelete(id) {
    mutationVideoDelete.mutate({ token: user.access_token, id });
  }

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={4} />
      <Col className="col-sm-12 col-12 col-md-8 offset-md-2">
        <Header title={t("VideoNew.Step10.title")} subtitle={t("VideoNew.Step10.subtitle")} />
        <Card className="card-container shadow-none">
          <Row className="g-0 justify-content-center m-5">
            <Col className="col-12 col-sm-12 col-md-8 justify-content-center" >
              <div className="col-lg-12 col-md-12 col-sm-12" id="outer" style={{ padding: 0 }}>
                <video src={`${COMAPANY}${video?.video.user.company_id}${COMAPANY_OUTPUTS}${video?.video.video}`} className="video" controls width="100%" height="300px"></video>
              </div>
            </Col>
          </Row>
        </Card>
        <div className="mt-3 mb-4 text-center">
          <Button onClick={() => handleDelete(video?.video.id)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
          <Button onClick={() => toggleModal()} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
        </div>
      </Col>
      <Col className=""></Col>
      <ModalVideoName
        next={next}
        video={video}
        name={name}
        setName={setName}
        showModal={showModal}
        setShowModal={setShowModal}
        toggleModal={toggleModal}
        people={people}
        info={info} />
    </Row>
  );
}

export default Step10;
