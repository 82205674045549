import React from 'react';
import { Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';

const Search = ({ originalList, list, setList, setInputRef, selectCategoryList, inside=false }) => {
  const { t } = useTranslation();

  function handelSearch(event) {
    console.log(list);
    if (event.target.value === "") {
      if (selectCategoryList != null) {
        setList(selectCategoryList);
      } else {
        setList(originalList);
      }
      return;
    }
    if (!inside) {
      setList(list.filter((e) => (e.title.toLowerCase().includes(event.target.value.toLowerCase()))));
    } else {
      setList(list.filter((e) => (e.video.title.toLowerCase().includes(event.target.value.toLowerCase()))));
    }
  }

  return (
    <Card className="shadow-none mb-0 bg-transparent">
      {/* <CardBody> */}
        <div className="select2-selection__indicators css-1hb7zxy-IndicatorsContainer table-search bg-white">
          <input
            ref={ref => setInputRef(ref)}
            style={{ height: 36, border: 'none' }}
            className="form-control"
            type="search"
            placeholder={t("Placeholder.search")}
            onChange={(event) => handelSearch(event)}
          />
          <span className="select2-selection__indicator-separator css-1okebmr-indicatorSeparator"></span>
        <div className="select2-selection__indicator select2-selection__dropdown-indicator css-tlfecz-indicatorContainer align-items-center d-flex" style={{ display: "-webkit-inline-flex" }} aria-hidden="true">
            <span className="bx bx-search-alt text-black" onClick={() => { }}></span>
          </div>
        </div>
      {/* </CardBody> */}
    </Card>
  );
}

export default Search;
