import React from 'react';
import { Modal } from "reactstrap";
import VideoRecorder from 'react-video-recorder';

// hooks
import useFormatBytes from "hooks/useFormatBytes";

const ModalVideoRecord = ({
  setVideo,
  showModal,
  setShowModal
}) => {

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  const saveVideo = (videoBlob) => {
    const file = new File([videoBlob], "file_name.mp4", { lastModified: Date.now(), type: videoBlob.type });

    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: useFormatBytes(file.size)
    })

    setVideo(file);
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <VideoRecorder
            chunkSize={0}
            constraints={{
              audio: true,
              video: {
                width: { min: 1280 },
                height: { min: 720 },
                facingMode: "user"
              }
            }}
            countdownTime={3000}
            dataAvailableTimeout={1500}
            isFlipped
            isOnInitially
            onRecordingComplete={(videoBlob) => saveVideo(videoBlob)} 
            onStopRecording={toggleModal}
            wrapperClassName="video-wrapper"
          />
        </div>
      </div>
    </Modal>
  );
}

export default ModalVideoRecord;