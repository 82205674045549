import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Badge, Button } from "reactstrap";
import classnames from "classnames";

// components
import Table from "components/views/Utils/Table";
import Loading from "components/views/Utils/Loading";
import Header from 'components/views/Utils/Header';
// routes
import { PRODUCT_NEW } from "routes/app/paths";
import { IMAGES_PRODUCTS } from "routes/server/paths";
// services
import { ProductsList } from "store/product/ProductService";
// hooks
import useAuthContext from "hooks/useAuthContext";
import { useToast } from "hooks/useToast";

const Products = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [ products, setProducts] = useState(null);
  const query = useQuery(['PRODUCTS', { token: user.access_token }], ProductsList, {
    onError: (error) => {
      useToast("error", "Error", "Error cargando productos");
    },
    onSuccess: (data) => {
      setProducts(data.data.data);
    }
  });

  const columns = [
    {
      dataField: 'picture',
      text: '',
      sort: false,
      formatter: (cell, row) => {
        return (
          <img
            data-dz-thumbnail=""
            height="80"
            className="avatar-sm rounded bg-light"
            alt={cell}
            src={`${IMAGES_PRODUCTS}${cell}`}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: 'NOMBRE',
      sort: true
    },
    {
      dataField: 'description',
      text: 'DESCRIPCIÓN',
      sort: true
    },
    {
      dataField: 'company.name',
      text: 'COMPAÑÍA',
      sort: true
    },
    {
      dataField: 'id',
      text: 'FICHAS CLIENTE',
      sort: false,
      formatter: (cell, row) => {
        return (
          <Link
            to={`#`}
            className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black"
            color="app-green">
            Ver ficha
          </Link>
        );
      },
    }
  ];

  return (
    <div className="main-content">
      <MetaTags>
        <title>Productos</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={"Productos"} />
            <Card className="card-container shadow-none" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
              <div className="mt-4 text-center">
                <Button
                  onClick={() => history.push(PRODUCT_NEW)}
                  className="btn-shadow btn-general bg-color-primary"
                  color="app-green">
                  Nuevo Producto
                </Button>
              </div>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  (query.isLoading) &&
                  <Loading style="table"/>
                }
                {
                  products ?
                    <Table data={products} columns={columns} />
                  :
                    <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}></Card>
                }
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Products;
