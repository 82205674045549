import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

// images
import vvv from "assets/images/app/landing/ford/vvv.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="mt-5" style={{position: 'absolute', width: '100%', bottom: 0 }}>
        <Container fluid className="content">
          {/* <p className="line-100-black"></p> */}
          <Row className="g-0 mb-2">
            <Col xl={6}>
              <Row className="g-0">
                <Col xl={5} className="d-flex justify-content-lg-start justify-content-md-center justify-content-center">
                  <img
                    width="220"
                    src={vvv}
                    alt=""
                    className="img-fluid d-block"
                  />
                </Col>
                <Col xl={7} className="align-self-center d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                  <p className="MontSemiBold text-white text-decoration-none footer-text m-0">{t("Footer.copyright")}</p>
                </Col>
              </Row>
            </Col>
            <Col xl={6} className="align-self-center">
              <Row className="g-0">
                <Col xl={4} className="d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                  <Link to="/" className="MontSemiBold mb-0 text-white footer-text d-block">{t("Footer.privacy")}</Link>
                </Col>
                <Col xl={4} className="d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                  <Link to="/" className="MontSemiBold mb-0 text-white footer-text d-block">{t("Footer.conditions")}</Link>
                </Col>
                <Col xl={4} className="d-flex justify-content-lg-end justify-content-md-center justify-content-center">
                  <Link to="/" className="MontSemiBold mb-0 text-white footer-text d-block">{t("Footer.cookies")}</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Footer
