import React from 'react';
import { Modal } from "reactstrap";

// routes
import { LANDING_PREVIEW } from 'routes/app/paths';

const ModalLandingPreview = ({ showModal, setShowModal, titleColor, secondaryColor, logo, backgroundImage, bgHeight }) => {

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  return (
    <Modal
      // size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      style={{ maxWidth: 1600 }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body p-0">
          <iframe src={`${LANDING_PREVIEW}?${titleColor}?${secondaryColor}?${logo}?${backgroundImage}?${bgHeight}`} style={{ width: '100%', minHeight: 1450 }}></iframe>
        </div>
      </div>
    </Modal>
  );
}

export default ModalLandingPreview;
