import {
  landingGet
} from 'routes/api/paths';

/**
 * @description List all clips
 * @param {*} access_token
 * @returns clips
 */
export const LandingGet = async (params) => {
  const requestOptions = {
    method: 'GET',
  }
  return fetch(`${landingGet}/${params.queryKey[1].token}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}