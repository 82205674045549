import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQueryClient } from "react-query";

// hooks
import { useToast } from "hooks/useToast";
// services
import { ForgotPasswordService } from 'store/auth/AuthService';
// components
import Success from "components/views/Auth/components/Success";

const PasswordRecovery = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    setLoading(true);
    const fetchToken = await queryClient.fetchQuery([values.email], ForgotPasswordService);
    setLoading(false);
    if (fetchToken.status === 200) {
      console.log('ok');
      useToast("success", t("Message.info"), t("Message.auth.success.check_email"));
    } else if (error.status === 403) {
      useToast("error", t("Message.error"), t("Message.auth.error.not_email"));
    } else {
      useToast("error", t("Message.error"), t("Message.problem"));
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>{t("Auth.PasswordRecovery.meta")}</title>
        </MetaTags>
        <Container fluid className="content">
          <Row className="g-0 justify-content-center m-5">
            <Col className="col-sm-12 col-lg-2 col-12 text-center align-self-center p-sm-4">
              <button type="button" onClick={() => history.goBack()} className="btn btn-shadow bg-color-white position-relative p-0 avatar-xs rounded-circle">
                <span className="avatar-title bg-transparent text-reset">
                  <i className="bx bx-chevron-left" style={{ fontSize: 24 }}></i>
                </span>
              </button>
            </Col>
            <Col className="col-sm-12 col-12 col-md-7 offset-md-0">
              <h1 className="MontSemiBold font-size-lg lh-1 text-black text-center pt-5">{t("Auth.PasswordRecovery.title")}</h1>
              <h6 className="mb-4 mt-4 lh-1 text-black text-center">{t("Auth.PasswordRecovery.subtitle")}</h6>
              <Card className="card-container">
                <Row className="g-0">
                  <Col xl={12}>
                    <div className="m-lg-5 p-lg-5 m-sm-1 p-sm-3">
                      <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                        <div className="mt-5 text-center">
                          <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                            <Spinner
                              style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border" />
                            {t("Auth.PasswordRecovery.title")}
                          </Button>
                        </div>
                      </AvForm>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col className="col-sm-12 col-lg-2 col-12 text-center align-self-center p-sm-4"></Col>
          </Row>
          <Success />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PasswordRecovery;
