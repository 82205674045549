import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
// services
import { ClientAdd } from 'store/client/ClientService';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const ClientNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [formRef, setFormRef] = useState(false);
  const [loading, setLoading] = useState(false);

  const mutationClientAdd = useMutation('CREATE_CLIENT', ClientAdd, {
    onError: (error) => {
      setLoading(false);
      console.log("Status", error.status);
      if (error.status === 433) {
        useToast("error", t("Message.error"), t("Message.client.error.email_use"));
        return;
      }
      useToast("error", t("Message.error"), t("Message.client.error.add"));
    },
    onSuccess: (data) => {
      formRef.reset();
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.client.success.add"));
    }
  })

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    setLoading(true);

    const client = {
      "name": values.name,
      "last_name": values.lastname,
      "second_last_name": values.secondLastName,
      "email": values.email,
      "phone": values.phone,
      "society": values.society
    }
    mutationClientAdd.mutate({ token: user.access_token, client });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("ClientNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("ClientNew.title")} />
            <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
              <Card className="card-container shadow-none">
                <Row className="g-0 mt-5 mb-5">
                  <Col lg={12}>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.surname")}
                            type="text"
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastName"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.second_surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            pattern="^\d{9}$"
                            errorMessage={t("Validation.not_phone")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="society"
                            className="form-control"
                            placeholder={t("Placeholder.company")}
                            type="text"
                          // validate={{
                          //   required: { value: true, errorMessage: t("Validation.company") },
                          // }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("ClientNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ClientNew;
