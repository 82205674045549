import React, { useState } from "react";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Button, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQuery, useMutation } from "react-query";
import classnames from "classnames";
import Select from "react-select";

// components
import Header from 'components/views/Utils/Header';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { CompaniesList } from 'store/company/CompanyService';
import { UserGet, UserUpdate } from 'store/user/UserService';

const Profile = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [users, setUser] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  useQuery(['COMPANIES', { token: user.access_token }], CompaniesList, {
    enabled: user.role == 'Admin',
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.company.error.load_all"));
    },
    onSuccess: (data) => {
      const newCompanies = data.data.data.map((e, i) => {
        return {
          label: e.name,
          value: e.id
        }
      });
      setCompanies(newCompanies);
    }
  });
  
  const queryUserGet = useQuery(['USER_GET', { token: user.access_token, id: user.id }], UserGet, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.user.error.load"));
    },
    onSuccess: (data) => {
      setSelectedCompany({ label: data.data.data.company.name, value: data.data.data.company.id });
      setSelectedRole({ label: data.data.data.role, value: data.data.data.role });
      setUser(data.data.data);
    }
  });

  const mutationUserUpdate = useMutation('UPDATE_USER', UserUpdate, {
    onError: (error) => {
      console.log("error", error);
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.user.error.update"));
    },
    onSuccess: (data) => {
      console.log("ok", data);
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.user.success.update"));
    }
  });

  function handleSelectCompany(data) {
    setSelectedCompany(data);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    const data = {
      "id": users.id,
      "name": `${values.name}`,
      "last_name": `${values.lastname}`,
      "second_last_name": `${values.secondLastName}`,
      "email": values.email,
      "phone": values.phone,
      "company_id": users.role == 'Admin' ? selectedCompany.value : users.company_id,
      "role": users.role
    }
    setLoading(true);
    mutationUserUpdate.mutate({ token: user.access_token, data });
  }

  function handleSubmitPassword(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    const data = {
      "id": users.id,
      "currentPassword": values.currentPassword,
      "newPassword": values.newPassword,
      "confirmedPassword": values.confirmedPassword,
      "role": users.role
    }
    setLoading(true);
    mutationUserUpdate.mutate({ token: user.access_token, data });
  }

  return (
    <React.Fragment>
      <div className="main-content">
        <MetaTags>
          <title>{t("Profile.meta")}</title>
        </MetaTags>
        <Container fluid className="content">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header title={t("Profile.title")} />
              <Card className="card-container shadow-none">
                <Row className="g-0">
                  <Col lg={12}>
                    <Card className="shadow-none">
                      <CardBody>
                        {/* Navs */}
                        <Row className="g-0 m-4">
                          <Col className="col-sm-12 col-md-4 offset-md-4">
                            <Nav className="icon-tab nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames(
                                    (customActiveTab === "1") ? "bg-color-primary text-white" : "bg-color-gray text-gray",
                                    "text-decoration-none MontBold tab-rounded-left"
                                  )}
                                  onClick={() => {
                                    toggleCustom("1")
                                  }}
                                >
                                  {t("Profile.plan")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames(
                                    (customActiveTab === "2") ? "bg-color-primary text-white" : "bg-color-gray text-gray",
                                    "text-decoration-none MontBold tab-rounded-right"
                                  )}
                                  onClick={() => {
                                    toggleCustom("2")
                                  }}
                                >
                                  {t("Profile.profile")}
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </Col>
                        </Row>  
                        {/* Tabs */}
                        <TabContent
                          activeTab={customActiveTab}
                          className="ps-3 text-muted"
                        >
                          <TabPane tabId="1">
                            <Row className="g-0">
                              <Col className="col-sm-12 col-md-6 offset-md-3 shadow-lg mt-1 border-bottom border-top">
                                <Card className="mb-0 border-bottom">
                                  <h4 className="MontSemiBold card-header bg-color-primary border-top text-center text-white">BÁSICO (GRATIS)</h4>
                                  <CardBody>
                                    <p>Curabitur lobortis lorem id bibendum conse quisque volutpat augue pulvinar lobortis  nibh lacinia at vestibulum.</p>
                                    <div className="line-100-gray mt-5"></div>
                                    <p className="MontSemiBold text-black">{t("Profile.sign_on")}</p>
                                    <p className="text-black">07/07/21</p>
                                    <div className="line-100-gray"></div>
                                    <p className="MontSemiBold text-black">{t("Profile.renew_date")}</p>
                                    <p className="text-black">07/07/21</p>
                                    <Col className="text-center mt-5">
                                      <Button
                                        className="btn-general-black bg-color-gray"
                                        color="app-green">
                                        {t("Profile.change_plan")}
                                      </Button>
                                    </Col>
                                    <Col className="text-center mt-4">
                                      <Link to={""} className="MontSemiBold text-black">
                                        {t("Profile.unsubscribe")}
                                      </Link>
                                    </Col>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Row className="g-0">
                              {/* Form */}
                              <Col lg={12}>
                                <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                                  <Row>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                      <div className="mb-4">
                                        <AvField
                                          name="name"
                                          className="form-control"
                                          placeholder={t("Placeholder.name")}
                                          type="text"
                                          value={users?.name}
                                          validate={{
                                            required: { value: true, errorMessage: t("Validation.name") },
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                                      <div className="mb-4">
                                        <AvField
                                          name="lastname"
                                          className="form-control"
                                          placeholder={t("Placeholder.surname")}
                                          type="text"
                                          value={users?.last_name}
                                          validate={{
                                            required: { value: true, errorMessage: t("Validation.surname") },
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                                      <div className="mb-4">
                                        <AvField
                                          name="secondLastName"
                                          className="form-control"
                                          placeholder={t("Placeholder.second_surname")}
                                          type="text"
                                          value={users?.second_last_name}
                                          validate={{
                                            required: { value: true, errorMessage: t("Validation.second_surname") },
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                      <div className="mb-4">
                                        <AvField
                                          name="email"
                                          className="form-control"
                                          placeholder={t("Placeholder.email")}
                                          type="email"
                                          value={users?.email}
                                          errorMessage={t("Validation.not_email")}
                                          validate={{
                                            required: { value: true, errorMessage: t("Validation.email") },
                                            email: true
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                                      <div className="mb-4">
                                        <AvField
                                          name="phone"
                                          className="form-control"
                                          placeholder={t("Placeholder.phone")}
                                          type="number"
                                          pattern="^\d{9}$"
                                          value={users?.phone}
                                          errorMessage={t("Validation.not_phone")}
                                          validate={{
                                            required: { value: true, errorMessage: t("Validation.phone") },
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                                      <div className="mb-4">
                                        <AvField
                                          name="department"
                                          className="form-control"
                                          placeholder={t("Placeholder.department")}
                                          type="text"
                                          value={users?.department}
                                        // validate={{
                                        //   required: { value: true, errorMessage: t("Validation.company") },
                                        // }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                                      <div className="mb-4">
                                        {
                                          user.role == 'Admin' &&
                                          (companies &&
                                            <Select
                                              value={selectedCompany}
                                              placeholder={t("Placeholder.select_company")}
                                              onChange={(data) => {
                                                handleSelectCompany(data)
                                              }}
                                              options={companies}
                                              classNamePrefix="select2-selection"
                                            />
                                          )
                                        }
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="mt-5 mb-2 text-center">
                                    <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                                      <Spinner
                                        style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                                        as="span"
                                        variant="light"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border" />
                                      {t("UserDetail.save")}
                                    </Button>
                                  </div>
                                </AvForm>
                              </Col>
                              {/* Password */}
                              <Col className="col-sm-12 col-md-4 offset-md-4 shadow-lg mt-5 border-bottom border-top">
                                <Card className="mb-0 border-bottom">
                                  <h4 className="MontSemiBold card-header bg-color border-top text-center">Cambiar la contraseña</h4>
                                  <CardBody>
                                    <AvForm className="form-horizontal" onSubmit={handleSubmitPassword.bind(this)}>
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-4">
                                            <AvField
                                              name="currentPassword"
                                              className="form-control"
                                              placeholder={t("Placeholder.current_password")}
                                              type="password"
                                              validate={{
                                                required: { value: true, errorMessage: t("Validation.current_password") },
                                              }}
                                            />
                                          </div>
                                          <div className="mb-4">
                                            <AvField
                                              name="newPassword"
                                              type="password"
                                              placeholder={t("Placeholder.new_password")}
                                              validate={{
                                                required: { value: true, errorMessage: t("Validation.new_password") },
                                              }}
                                            />
                                          </div>
                                          <div className="mb-4">
                                            <AvField
                                              name="confirmedPassword"
                                              className="form-control"
                                              type="password"
                                              placeholder={t("Placeholder.confirmed_password")}
                                              validate={{
                                                match: { value: 'newPassword', errorMessage: t("Validation.not_match_password") },
                                                required: { value: true, errorMessage: t("Validation.confirmed_password") }
                                              }}
                                            />
                                          </div>
                                          <div className="mt-3 text-center">
                                            <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                                              <Spinner
                                                style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                                                as="span"
                                                variant="light"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                animation="border" />
                                              {t("UserDetail.save")}
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                  
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile;
