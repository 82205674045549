import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import { OnSymbol, Offsymbol } from 'components/views/Utils/SwitchStatus';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import CategoryBar from 'components/views/Utils/CategoryBar/CategoryBar';
import ModalDeleteWithText from "components/views/Utils/ModalDeleteWithText";
import ModalEditName from "components/views/Utils/ModalEditName";
// services
import { ClipsList, ClipUpdate, ClipDelete } from 'store/clip/ClipService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { CLIP_NEW } from 'routes/app/paths';
import { COMAPANY, COMAPANY_CLIPS } from "routes/server/paths";

const Clips = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [selectCategoryList, setSelectCategoryList] = useState(null);
  const [clips, setClips] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdateName, setShowModalUpdateName] = useState(false);
  const [videoDelete, setVideoDelete] = useState(null);
  const [videoEdit, setVideoEdit] = useState(null);

  const queryClipList = useQuery(['CLIPS', { token: user.access_token }], ClipsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.clip.error.load_all"));
    },
    onSuccess: (data) => {
      setClips(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  const mutationClipUpdate = useMutation('UPDATE_CLIP', ClipUpdate, {
    onError: (error) => {
      setVideoEdit(null);
      if (error.status === 405) {
        setShowModalUpdateName(false);
        useToast("error", t("Message.error"), t("Message.auth.error.access_denied"));
        return;
      }
      useToast("error", t("Message.error"), t("Message.clip.error.update"));
    },
    onSuccess: (data) => {
      setShowModalUpdateName(false);
      useToast("success", t("Message.info"), t("Message.clip.success.update"));
      if (videoEdit) {
        queryClient.refetchQueries(['CLIPS', { token: user.access_token }]);
      }
      setVideoEdit(null);
    }
  });

  const mutationClipDelete = useMutation('DELETE_CLIP', ClipDelete, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.clip.error.delete"));
      setShowModal(false);
    },
    onSuccess: (data) => {
      if (inputSearchRef.value != "") {
        inputSearchRef.value = "";
      }
      useToast("success", t("Message.info"), t("Message.clip.success.delete"));
      queryClient.refetchQueries(['CLIPS', { token: user.access_token }]);
      setShowModal(false);
    }
  });

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleUpdateStatus(clip) {
    const newClips = clips.map((e, i) => {
      if (e.id === clip.id) {
        e.status = !e.status
      }
      return e;
    });
    setClips(newClips);
    mutationClipUpdate.mutate({ token: user.access_token, data: clip });
  }

  function handleChangeNameVideo(video) {
    setVideoEdit(video);
    setShowModalUpdateName(!showModalUpdateName);
  }

  function handleDelete(id) {
    setVideoDelete(id);
    toggleModal();
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Clips.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Clips.meta")} />
            <div className="text-center">
              {
                user.role != 'Agente' &&
                <Button onClick={() => history.push(CLIP_NEW)} className="btn-shadow btn-general bg-color-primary border-none" color="app-green">{t("Clips.select")}</Button>
              }
            </div>
            <div className="my-4">
              <CategoryBar originalList={originalList} setList={setClips} inputSearchRef={inputSearchRef} setSelectCategoryList={setSelectCategoryList} />
              <ToolBar originalList={originalList} list={clips} setList={setClips} setInputRef={setInputSearchRef} selectCategoryList={selectCategoryList} />
            </div>
            <Card className="card-container shadow-none" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  queryClipList.isLoading &&
                  <Loading style="video" />
                }
                {
                  clips &&
                  clips.map((e, i) => {
                    return (
                      <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.company_id}${COMAPANY_CLIPS}${e.clip}`} thumbnail={(e.thumbnail === null || e.thumbnail === 'null') ? null : `${COMAPANY}${e.company_id}${COMAPANY_CLIPS}${e.thumbnail}` } />
                        {
                          user.role != 'Agente' &&
                          <Row className="mb-3 justify-content-between">
                            <Col className="col-md-5 text-center">
                              <Switch
                                width={45}
                                height={20}
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="ms-3 mt-2"
                                onColor="#4ab28c"
                                onChange={() => {
                                  handleUpdateStatus({ ...e, "status": !e.status })
                                }}
                                checked={e.status}
                              />
                            </Col>
                            <Col className="col-md-5 text-center">
                              <i className="mdi mdi-pencil-outline" onClick={() => handleChangeNameVideo(e)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                              <i className="mdi mdi-trash-can-outline" onClick={() => { handleDelete(e.id) }} style={{ fontSize: 24, cursor: "pointer" }}></i>
                            </Col>
                          </Row>
                        }
                      </Col>
                    );
                  })
                }
                </Row>
            </Card>
          </Col>
        </Row>
        <ModalDeleteWithText
          showModal={showModal}
          setShowModal={setShowModal}
          dataDelete={videoDelete}
          mutation={mutationClipDelete}
          model={t("Clips.the_clip")} />
        <ModalEditName
          video={videoEdit}
          showModal={showModalUpdateName}
          setShowModal={setShowModalUpdateName}
          mutation={mutationClipUpdate} />
      </Container>
    </div>
  );
}

export default Clips;
