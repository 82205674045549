// DOMAIN
// export const DOMAIN = 'http://127.0.0.1:8000/';
// export const DOMAIN = 'http://52.214.57.61/';
// export const DOMAIN = 'https://back.virtualvendorvideo.com/';
export const DOMAIN = 'https://vvv.codeals.es/';

// IMAGES
export const IMAGES = DOMAIN + 'img/';
export const IMAGES_PRODUCTS = IMAGES + 'products/';

// VIDEOS
export const COMAPANY = DOMAIN + 'videos/';
export const COMAPANY_CLIPS = '/clips/';
export const COMAPANY_OUTPUTS = '/outputs/';
export const COMAPANY_VIDEOS = '/videos/';
export const COMAPANY_TEMPLATES = '/templates/';
export const COMAPANY_DOCS = '/docs/';
export const COMAPANY_LANDING = '/landing/';