import React, { useState } from 'react';
import { Row, Col, Card, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';
import { useQuery } from "react-query";

// components
import Header from 'components/views/Utils/Header';
import WizardStep from "components/views/Video/components/WizardStep";
import AutoComplete from 'components/views/Video/components/Introduction/components/AutoComplete';
// services
import { ClientsList } from 'store/client/ClientService';
// hooks
import useAuthContext from 'hooks/useAuthContext';

const Step2 = ({ back, next, client, setClient }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [clients, setClients] = useState(null);

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [name, setName] = useState(client?.name !== null ? client?.name : "");
  const [lastName, setLastName] = useState(client?.lastname !== null ? client?.lastname : "");
  const [secondLastName, setSecondLastName] = useState(client?.secondLastName !== null ? client?.secondLastName : "");
  const [email, setEmail] = useState(client?.email !== null ? client?.email : "");
  const [phone, setPhone] = useState(client?.phone !== null ? client?.phone : "");
  const [society, setSociety] = useState(client?.society !== null ? client?.society : "");

  const queryClientList = useQuery(['CLIENTS', { token: user.access_token }], ClientsList, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.client.error.load_all"));
    },
    onSuccess: (data) => {
      setClients(data.data.data);
    }
  });

  function handleUser(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }
    
    setClient(values);
    next(2, 4);
  }

  function handleChangeText(event) {
    setName(event.target.value);
    setLastName("");
    setSecondLastName("");
    setEmail("");
    setPhone("");
    setSociety("");
    const userInput = event.target.value;

    if (userInput === "") {
      setFilteredSuggestions([]);
      return;
    }

    console.log("clients", clients);

    // Filter our suggestions that don't contain the user's input
    if (clients) {
      const unLinked = clients.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
      
      setFilteredSuggestions(unLinked);
    }
  }

  const onClick = (index) => {
    const user = filteredSuggestions[index];
    console.log(user.name);
    user.name ? setName(user.name) : setName("");
    user.last_name ? setLastName(user.last_name) : setLastName("");
    user.second_last_name ? setSecondLastName(user.second_last_name) : setSecondLastName("");
    user.email ? setEmail(user.email) : setEmail("");
    user.phone ? setPhone(user.phone) : setPhone("");
    user.society ? setSociety(user.society) : setSociety("");
    setFilteredSuggestions([]);
  };

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={1} />
      <Col className="col-sm-12 col-md-8 col-12 offset-md-2">
        <Header title={t("VideoNew.Step2.title")} />
        <AvForm className="form-horizontal" onSubmit={handleUser}>
          <Card className="card-container shadow-none">
            <Row className="g-0 justify-content-center m-5">
              <Col className="col-sm-12 col-md-12 justify-content-center">
                <Row>
                  <Col className="col-12 col-sm-12 col-md-8 col-lg-8 offset-md-2">
                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            onChange={handleChangeText}
                            type="text"
                            value={name}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                          <AutoComplete filteredSuggestions={filteredSuggestions} onClick={onClick}/>
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="5" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.surname")}
                            type="text"
                            value={lastName}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.surname") },
                            // }}
                          />
                        </div>
                      </Col>

                      <Col xl="5" sm="12" className="offset-lg-2 p-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastName"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            value={secondLastName}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.second_surname") },
                            // }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            value={email}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="confirmEmail"
                            className="form-control"
                            placeholder={t("Placeholder.confirm_email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            value={email}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.confirm_email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="5" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            value={phone}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>

                      <Col xl="5" sm="12" className="offset-lg-2 p-0">
                        <div className="mb-4">
                          <AvField
                            name="society"
                            className="form-control"
                            placeholder={t("Placeholder.company")}
                            type="text"
                            value={society}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.company") },
                            // }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => back(2)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
          </div>
        </AvForm>
      </Col>
      <Col className=""></Col>
    </Row>
  );
}

export default Step2;
