import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
// routes
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { VideosList } from 'store/video/VideoService';
// routes
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";
import { STATISTICS } from 'routes/app/paths';

const Statistics = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [videos, setVideos] = useState(null);

  const queryVideoList = useQuery(['VIDEOS', { token: user.access_token }], VideosList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", "Error", "Error cargando los vídeos");
    },
    onSuccess: (data) => {
      setVideos(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Statistics.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Statistics.title")} />
            <ToolBar originalList={originalList} list={videos} setList={setVideos} setInputRef={setInputSearchRef} />
            <Card className="card-container shadow-none mt-4" style={{ maxHeight: 450, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  queryVideoList.isLoading &&
                  <Loading style="video" />
                }
                {
                  videos &&
                  videos.map((e, i) => {
                    console.log(e);
                    return (
                      <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_OUTPUTS}${e.video}`} />
                        <div className="mb-3 mt-2 text-center">
                          <Link
                            to={`${STATISTICS}/${e.id}`}
                            className="btn-general bg-color-darkgray text-decoration-none text-white"
                            color="app-green">
                            {t("Statistics.statistics")}
                          </Link>
                        </div>
                      </Col>
                    );
                  })
                }
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Statistics;