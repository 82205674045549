import React, { useState } from 'react';
import { Card, CardBody, Badge } from "reactstrap";
import Moment from 'react-moment';

// components
import ModalVideoView from 'components/views/Utils/ModalVideoView';
// images
import play from "assets/images/app/video/play.png";
import thumb from "assets/images/app/video/thumbnail.png";

const VideoCard = ({ name, date, srcVideo, clients = null, thumbnail = null }) => {
  const [showModal, setShowModal] = useState(false);

  const handleVideoView = () => {
    setShowModal(!showModal);
  }

  return (
    <Card className="mb-0 border-top shadow-none">
      <div className="bg-color-gray p-3 border-top border-bottom-green-1" style={{ height: 90 }}>
        <p className="MontSemiBold mb-0">{name}</p>
        <Moment date={date} format="DD MMM, YYYY" />
      </div>
      <CardBody>
        <div className="col-lg-12 col-md-12 col-sm-12" id="outer" onClick={handleVideoView} style={{ padding: 0, height: 100, cursor: 'pointer' }}>
          <img
            src={thumbnail === null ? thumb : thumbnail}
            alt=""
            className="video"
            width="100%"
            height="100px"
          />
          <div style={{ position: "relative", top: -100 }}>
            <img
              src={play}
              alt=""
              className="video"
              width="100%"
              height="100px"
              style={{ backgroundColor: 'transparent' }}
            />
          </div>
          {/* <video src={srcVideo} className="video" controls width="100%" height="100px"></video> */}
        </div>
      </CardBody>
      <ModalVideoView
        video={srcVideo}
        showModal={showModal}
        setShowModal={setShowModal} 
      />
    </Card>
  );
}

export default VideoCard;
