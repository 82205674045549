import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationES from "./locales/es/translation.json";
import translationEN from "./locales/en/translation.json";

// the translations
const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
}

// const language = localStorage.getItem("I18N_LANGUAGE");

// if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "es");
// }

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "es",
    fallbackLng: "es", // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    }
  });

export default i18n;
