import React from 'react';
import { Card, CardBody } from "reactstrap";

const StatisticCard = ({ title, data }) => {
  return (
    <Card className="border-top text-center shadow-lg border-bottom border-top m-3 bg-color-white">
      <div className="bg-color-gray p-3 border-top">
        <p className="MontSemiBold mb-0">{title}</p>
      </div>
      <CardBody>
        <h2 className="MontBold mb-0">{data}</h2>
      </CardBody>
    </Card>
  );
}

export default StatisticCard;
