import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

// hooks
import useAuthContext from 'hooks/useAuthContext';
// routes
import { LOGIN } from 'routes/app/paths';

function Authmiddleware ({ component: Component, layout: Layout, isAuthProtected, ...rest }) {
  const { isAuthenticated } = useAuthContext();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isAuthenticated) {
          return (
            <Redirect to={{ pathname: LOGIN, state: { from: props.location } }} />
          )
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  );
}


Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware;
