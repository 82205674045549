import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, CardText, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';
// images
import email from "assets/images/app/video/email.svg";
import link from "assets/images/app/video/link.svg";
import sms from "assets/images/app/video/sms.svg";
// routes
import { BASE_URL, LANDING, DASHBOARD } from 'routes/app/paths';
// hooks
import useSplitText from 'hooks/useSplitText';

const Step12 = () => {
  const history = useHistory();
  const { t } = useTranslation();

  function copyToClipboard(link) {
    const tempInput = document.createElement('input');
    tempInput.value = link;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }

  function sendEmail(link) {
    console.log(link);
    window.location.href = `mailto:direccion@destinatario.com?subject=Asunto%20con%20espacios&body=${link}`;
  }

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={4} />
      <Col className="col-sm-12 col-lg-1 col-12"></Col>
      <Col className="col-sm-12 col-12 col-md-10 offset-md-0">
        <Header title={t("VideoNew.Step12.title")} />
        <Card className="card-container shadow-none">
          <Row className="g-0 mb-4 justify-content-center mt-5">
            <h5 className="text-black text-center MontSemiBold lh-3">{useSplitText(t("VideoNew.Step12.finish1"))}<br /><br />{t("VideoNew.Step12.finish2")}</h5>
            <div className="mt-5 mb-5 text-center">
              <Button onClick={() => window.location.reload(true)} className="btn-general bg-color-primary" color="app-green">{t("VideoNew.Step11.new")}</Button>
              <Button onClick={() => history.push(DASHBOARD)} className="btn-general-black bg-color-gray ms-3 text-black px-5" color="app-green">{t("VideoNew.Step11.home")}</Button>
            </div>
          </Row>
        </Card>
      </Col>
      <Col className="col-sm-12 col-lg-1 col-12"></Col>
    </Row>
  );
}

export default Step12;
