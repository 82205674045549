import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardText, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import WizardStep from "components/views/Video/components/WizardStep";
import ModalVideoReuse from "components/views/Video/components/Introduction/components/ModalVideoReuse";
// images
import flag from "assets/images/app/video/flag.svg";
import date from "assets/images/app/video/date.svg";

const Step4 = ({ back, next, setClips, setSelectedClips, setCta, setReuse }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  
  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function startZero() {
    setCta([]);
    setReuse(null);
    next(4, 5);
  }

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={1} />
      <Col className="col-sm-12 col-12 col-md-8 offset-md-2">
        <Header title={t("VideoNew.Step4.title")} />
        <Card className="card-container shadow-none">
          <Row className="g-0 justify-content-center m-5">
            <Col className="col-sm-12 col-md-4 offset-md-4 justify-content-center" style={{ display: "contents" }}>
              <div className="m-4" onClick={() => startZero()} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 150, minHeight: 150, maxWidth: 150 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center" style={{ height: 93 }}>
                      <img
                        src={flag}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step4.start")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="m-4" onClick={() => toggleModal()} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 150, minHeight: 150, maxWidth: 150 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center" style={{ height: 93 }}>
                      <img
                        src={date}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step4.reuse")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Card>
        <div className="mt-3 mb-4 text-center">
          <Button onClick={() => back(4)} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">← {t("VideoNew.back")}</Button>
        </div>
      </Col>
      <Col className=""></Col>
      <ModalVideoReuse
        showModal={showModal}
        setShowModal={setShowModal}
        toggleModal={toggleModal}
        next={next}
        setClips={setClips}
        setSelectedClips={setSelectedClips}
        setCta={setCta}
        setReuse={setReuse}
      />
    </Row>
  );
}

export default Step4;
