import {
  clipList,
  clipGet,
  clipAdd,
  clipUpdate,
  clipDelete,
  clipCategoriesUpdate
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description List all clips
 * @param {*} access_token
 * @returns clips
 */
export const ClipsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(clipList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get clip by id
 * @param {*} access_token
 * @param {*} id
 * @returns 
 */
export const ClipGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${clipGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add clip
 * @param {*} clip
 * @param {*} token
 * @returns clip
 */
export const ClipAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.clip,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(clipAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update clip
 * @param {*} clip
 * @param {*} token
 * @returns clip
 */
export const ClipUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.data),
  }
  console.log(requestOptions);
  return fetch(clipUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete clip
 * @param {*} clip
 * @param {*} token
 * @returns clip
 */
export const ClipDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      clip_id: params.id
    }),
  }
  return fetch(clipDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description store clip for categories
 * @param {*} clip_id
 * @param {*} categories
 * @returns 
 */
export const ClipCategoriesUpdate = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.info)
  }
  return fetch(clipCategoriesUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}