import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// components
import ModalCookieSetting from 'components/views/Utils/Cookie/components/ModalCookieSetting';
// config
import { encryptStorage } from 'config/encryptStorage';

const COOKIE = 'COOKIE';

const Cookie = ({ setCookie }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleAcceptCookie = () => {
    encryptStorage.setItem(COOKIE, true);
    setCookie(true);
  }

  const handleNecessaryCookie = () => {
    setShowModal(true);
  }

  const handleInfoCookie = () => {
    window.open("https://virtualvendorvideo.com/politica-de-cookie", '_blank', 'noopener,noreferrer')
  }

  return (
    <>
      <Card className="cookie">
        <CardBody className='py-2 px-3'>
          <CardTitle className="mb-1 fs-4">{t("Cookie.title")}</CardTitle>
          <CardSubtitle className="mb-1 fs-5">
            {t("Cookie.subtitle")}
          </CardSubtitle>
          <Row className='float-end'>
            <Col>
              {/* <Button className="btn-shadow btn-general bg-color-darkgray color-white border-none" onClick={handleNecessaryCookie}>
                {t("Cookie.setting")}
              </Button>{" "} */}
              <Button className="btn-shadow btn-general bg-color-darkgray color-white border-none" onClick={handleInfoCookie}>
                {t("Cookie.info")}
              </Button>{" "}
              <Button className="btn-shadow btn-general bg-color-green color-white border-none" onClick={handleAcceptCookie}>
                {t("Cookie.accept")}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ModalCookieSetting
        showModal={showModal}
        setShowModal={setShowModal} />
    </>
  )
}

export default Cookie