import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

// images
import chicoCamare from "assets/images/app/auth/chico-camara/chico-camara.png";
import chica from "assets/images/app/auth/chica/chica.png";
import linkedIn from "assets/images/app/auth/in/in.png";

const Success = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="MontSemiBold font-size-lg lh-1 text-black pt-5 mt-4 text-center-sm" >{t("Auth.Success.title1")}</h1>
      <h4 className="mb-5 lh-1 text-black text-center-sm">{t("Auth.Success.title2")}</h4>
      <Row className="g-0">
        <Col xl={3}>
          <p className="MontSemiBold">{t("Auth.Success.we")}</p>
          <p className="line-50"></p>
          <p>{t("Auth.Success.we_desc1")} <Link to="/" className="MontBold d-inline text-black text-decoration-none">{t("Auth.Success.we_desc2")}</Link></p>
        </Col>
        <Col xl={3}>
          <p className="MontSemiBold">{t("Auth.Success.links")}</p>
          <p className="line-50"></p>
          <Link to="/" className="MontBold mb-0 text-black text-decoration-none d-block">{t("Auth.Success.plat")}</Link>
          <Link to="/" className="MontBold mb-0 text-black text-decoration-none d-block">{t("Auth.Success.blog")}</Link>
          <Link to="/" className="MontBold mb-0 text-black text-decoration-none d-block">{t("Auth.Success.price")}</Link>
          <Link to="/" className="MontBold mb-0 text-black text-decoration-none d-block">{t("Auth.Success.contact")}</Link>
        </Col>
        <Col xl={3}>
          <p className="MontSemiBold">{t("Auth.Success.last1")}</p>
          <p className="line-50"></p>
          <Row className="mb-2">
            <Col xl={2}>
              <img src={chicoCamare} alt="" width="40" height="40" />
            </Col>
            <Col xl={10}>
              <p className="MontBold d-inline">{t("Auth.Success.last2")}<br />{t("Auth.Success.last3")}</p>
            </Col>
          </Row>
          <Row>
            <Col xl={2}>
              <img src={chica} alt="" width="40" height="40" />
            </Col>
            <Col xl={10}>
              <p className="MontBold d-inline">{t("Auth.Success.last4")}<br />{t("Auth.Success.last5")}</p>
            </Col>
          </Row>
        </Col>
        <Col xl={3}>
          <p className="MontSemiBold">{t("Auth.Success.rrss")}</p>
          <p className="line-50"></p>
          <Row>
            <Col xl={2}>
              <img src={linkedIn} alt="" width="16" height="16" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Success;
