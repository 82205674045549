import React from 'react';
import { Row, Col, Card, CardBody, CardText } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import WizardStep from "components/views/Video/components/WizardStep";
// images
import person from "assets/images/app/video/person.svg";
import people from "assets/images/app/video/people.svg";

const Step1 = ({ back, next }) => {
  const { t } = useTranslation();

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={1} />
      <Col className="col-sm-12 col-md-8 offset-md-0">
        <Header title={t("VideoNew.Step1.title")} subtitle={t("VideoNew.Step1.subtitle")} />
        <Card className="card-container shadow-none">
          <Row className="g-0 justify-content-center m-5">
            <Col className="col-sm-12 col-md-4 offset-md-4 justify-content-center" style={{ display: "contents" }}>
              <div className="m-4" onClick={() => next(1, 2)} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 150, minHeight: 150, maxWidth: 150 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center" style={{ height: 93 }}>
                      <img
                        src={person}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step1.person")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
              <div className="m-4" onClick={() => next(1, 3)} style={{ cursor: "pointer" }}>
                <Card className="shadow-lg rounded-3" style={{ minWidth: 150, minHeight: 150, maxWidth: 150 }}>
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <CardText className="text-center" style={{ height: 93 }}>
                      <img
                        src={people}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                      {t("VideoNew.Step1.people")}
                    </CardText>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}

export default Step1;
