import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import Select from "react-select";
import { useQueryClient, useQuery, useMutation } from "react-query";

// services
import { CompaniesList } from 'store/company/CompanyService';
import { ProductAdd } from 'store/product/ProductService';
import Header from 'components/views/Utils/Header';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const ProductNew = () => {
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const [image, setImage] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formRef, setFormRef] = useState(null);

  // const previousValue = queryClient.getQueryData(['COMPANIES', { token: user.access_token }]);
  // const previousValue = await queryClient.refetchQueries(['COMPANIES', { token: user.access_token }], { active: true, exact: true })

  useQuery(['COMPANIES', { token: user.access_token }], CompaniesList, {
    onError: (error) => {
      useToast("error", "Error", "Error cargando companías");
    },
    onSuccess: (data) => {
      const newCompanies = data.data.data.map((e, i) => { 
        return { 
          label: e.name, 
          value: e.id
        }
      });
      setCompanies(newCompanies);
    }
  });
  
  const mutation = useMutation('CREATE_PRODUCT', ProductAdd, {
    onError: (error) => {
      useToast("error", "Error", "Error creando producto");
    },
    onSuccess: (data) => {
      formRef.reset();
      setImage(null);
      useToast("success", "Información", "Producto creado correctamente");
    }
  })

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    // setSelectedFiles(files);
    setImage(files);
  }

  function handleSelect(data) {
    setSelectedCompany(data);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (seletedCompany == null) {
      useToast("error", "Alerta", "Escoga una compañía");
      return;
    }

    if (image == null) {
      useToast("error", "Alerta", "Suba una imagen del producto");
      return;
    }

    const data = new FormData();
    data.append("picture", image[0]);
    data.append("name", values.name);
    data.append("description", values.description);
    data.append("company_id", seletedCompany.value);

    mutation.mutate({ token: user.access_token, product: data });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>Nuevo producto</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={"Nuevo producto"} back={true} />
            <Card className="card-container shadow-none">
              <Row className="g-0 mt-5 mb-5">
                <Col lg={12}>
                  <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder="Nombre"
                            type="text"
                            errorMessage="Nombre no válido"
                            validate={{
                              required: { value: true, errorMessage: 'Entre un nombre' },
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <AvField
                            name="description"
                            className="form-control"
                            placeholder="Descripción"
                            type="text"
                            errorMessage="Descripción no válida"
                            validate={{
                              required: { value: true, errorMessage: 'Entre una descripción' }
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          {
                            companies &&
                            <Select
                              value={selectedCompany}
                              placeholder="Escoga una compañía"
                              onChange={(data) => {
                                handleSelect(data)
                              }}
                              options={companies}
                              classNamePrefix="select2-selection"
                            />
                          }
                        </div>
                        <div className="mb-4">
                        {
                          image &&
                          image.map((f, i) => {
                            return (
                              <Row className=" align-items-center">
                                <Col className="col-2">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                  <div style={{ position: "relative", top: 145, float: "right", left: 40 }}>
                                    <i
                                      onClick={() => setImage(null)}
                                      className={classnames(
                                        "mdi mdi-close",
                                        "circle",
                                      )}
                                      style={{ fontSize: 18, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                  </div>
                                </Col>
                                <Col className="col-10">
                                  {f.name}
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            )
                          })
                        }
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Arrastra los archivos aquí o selecciona</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-5 mb-2 text-center">
                      <Button className="btn-shadow btn-general bg-color-green" color="app-green">Guardar</Button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductNew;