import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Input } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';

const Step7 = ({ back, next, message, setMessage }) => {
  const { t } = useTranslation();
  const [textCount, setTextCount] = useState(0);
  const [textAreaBadge, setTextAreaBadge] = useState(0);

  useEffect(() => {
    if (message != null) {
      const count = message.length;
      if (count > 0) {
        setTextAreaBadge(true);
      } else {
        setTextAreaBadge(false);
      }
      setTextCount(message.length);
    }
  }, [])

  function textAreaChange(event) {
    const count = event.target.value.length;
    if (count > 0) {
      setTextAreaBadge(true);
    } else {
      setTextAreaBadge(false);
    }
    setTextCount(event.target.value.length);
    setMessage(event.target.value);
  }

  return (
    <Row className="g-0 justify-content-center">
      <Col className="col-sm-12 col-lg-2 col-12 text-center align-self-center p-sm-4">
        <button type="button" onClick={() => back(7)} className="btn btn-shadow bg-color-white position-relative p-0 avatar-xs rounded-circle">
          <span className="avatar-title bg-transparent text-reset">
            <i className="bx bx-chevron-left" style={{ fontSize: 24 }}></i>
          </span>
        </button>
      </Col>
      <Col className="col-sm-12 col-12 col-md-8 offset-md-0">
        <WizardStep step={1} />
        <Header title={t("VideoNew.Step7.title")} subtitle={t("VideoNew.Step7.subtitle")}/>
        <Card className="card-container shadow-none">
          <Row className="g-0 justify-content-center m-5">
            <Col className="col-12 col-sm-12 col-md-8 justify-content-center">
              <Input
                type="textarea"
                id="textarea"
                className="border-black-1"
                onChange={e => { textAreaChange(e); }}
                maxLength="100"
                rows="6"
                placeholder={t("Placeholder.write")}
                value={message}
              />
              {textAreaBadge ? (
                <span className="badgecount badge bg-success">
                  {textCount} / 100
                </span>
              ) : null}
              <p className="float-end">{t("VideoNew.Step7.max")}</p>
              <div className="mt-5 mb-2 text-center">
                <Button onClick={() => next(7, 8)} className="btn-shadow btn-general-black bg-color-white" color="app-green">{t("VideoNew.continue")}</Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col className=""></Col>
    </Row>
  );
}

export default Step7;
