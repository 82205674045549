import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button, Label } from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";
import { useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { TokenService, UserService } from 'store/auth/AuthService';
// routes
import { DASHBOARD, PASSWORD_RECOVERY } from 'routes/app/paths';

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [checkClasses, setCheckClasses] = useState("");
  const { login, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(DASHBOARD);
    }
  }, [isAuthenticated]);

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    // if (!values.politics) {
    //   setCheckClasses("text-danger");
    //   return;
    // } else {
    //   setCheckClasses("");
    // }

    const userInfo = {
      grant_type: 'password',
      client_id: 2,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      username: values.email,
      password: values.password,
      scope: ''
    }

    try {
      const fetchToken = await queryClient.fetchQuery([userInfo], TokenService);
      if (fetchToken.status === 200) {
        const token = fetchToken.token;
        const fetchUser = await queryClient.fetchQuery([token], UserService);
        if (fetchUser.status === 200) {
          // console.log('user', fetchUser.user);
          login(fetchUser.user);
        }
      }
    } catch (error) {
      if (error.status === 400) {
        useToast("error", t("Message.error"), t("Message.auth.error.credential"));
      } else {
        useToast("error", t("Message.error"), t("Message.problem"));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="main-content bg-image-login">
        <MetaTags>
          <title>{t("Auth.Login.meta")}</title>
        </MetaTags>
        <Container fluid className="content" style={{ height: "100vh" }}>
          {/* <Row className="g-0 d-lg-none mb-2" style={{justifyContent: 'center'}}>
            <Button className="btn-shadow btn-login bg-color-black">{t("Auth.Login.signin")}</Button>
          </Row> */}
          <Card className="border-radius-10 col-sm-12 col-md-6 offset-md-3">
            <Row className="g-0 p-5">
              <h1 className="MontSemiBold font-size-md lh-1 text-black">{t("Auth.Login.title")}</h1>
              <h6 className="mb-5 lh-1 text-black">{t("Auth.Login.subtitle")}</h6>
              <Col xl={12}>
                <div className="">
                  <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                    <div className="mb-4">
                      <AvField
                        name="email"
                        className="form-control"
                        placeholder={t("Placeholder.email")}
                        type="email"
                        errorMessage={t("Validation.email")}
                        validate={{
                          required: { value: true, errorMessage: t("Validation.email") },
                          email: true
                        }}
                      />
                    </div>
                    
                    <div className="mb-4">
                      <AvField
                        name="password"
                        type="password"
                        placeholder={t("Placeholder.password")}
                        validate={{
                          required: { value: true, errorMessage: t("Validation.password") },
                        }}
                      />
                    </div>

                    <div className="mt-3">
                      <Link to={PASSWORD_RECOVERY} className="text-black">
                        <i className="mdi mdi-lock me-1" />
                        {t("Auth.Login.forgot_password")}
                      </Link>
                    </div>

                    <AvGroup check className="mt-3">
                      <Label check>
                        <AvInput type="checkbox" name="session" onClick={() => { }} checked={false} />
                        {t("Auth.Login.session")}
                      </Label>
                    </AvGroup>

                    <div className="text-center mt-3">
                      <Button className="btn-shadow btn-main-block" color="app-primary">{t("Auth.Login.signin")}</Button>
                    </div>

                    <div className="mt-3 text-center">
                      <p className="mb-0">{t("Auth.Login.not_account")}</p>
                      <Link
                        to="register"
                        className="text-green MontSemiBold"
                      >
                        {t("Auth.Login.register")}
                      </Link>
                    </div>

                    <Label check className={checkClasses}>{t("Auth.Login.politic1")} <a href="https://virtualvendorvideo.com/aviso-legal" target="_blank" className="MontBold text-black">{t("Auth.Login.politic2")}</a> {t("Auth.Login.politic3")} <a href="https://virtualvendorvideo.com/politica-de-privacidad" target="_blank" className="MontBold text-black">{t("Auth.Login.politic4")}</a></Label>
                  </AvForm>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login;
