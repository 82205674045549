import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, Spinner, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "react-query";
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import ModalLandingPreview from 'components/views/Utils/ModalLandingPreview';
// services
import { CompanyAdd } from 'store/company/CompanyService';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const CompanyNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [titleColor, setTitleColor] = useState('#c55654');
  const [secondaryColor, setSecondaryColor] = useState('#c55654');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [formRef, setFormRef] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [bgHeight, setBgHeight] = useState(500);

  const mutationCompanyAdd = useMutation('CREATE_COMPANY', CompanyAdd, {
    onError: (error) => {
      setLoading(false);
      console.log(error.status);
      if (error.status === 433) {
        useToast("error", t("Message.error"), t("Message.company.error.email_use"));
        return;
      }
      useToast("error", t("Message.error"), t("Message.company.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.company.success.add"));
    }
  });

  function handleAcceptedFiles(acceptedFiles, fileRejections, uploadType) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      if (uploadType === 'logo') {
        setLogo(image);
      } else {
        setBackgroundImage(image);
      }
    }
  }

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (logo == null) {
      useToast("error", t("Message.alert"), t("Message.company.error.logo"));
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("phone", values.phone);
    data.append("web", values.web);
    data.append("cif", "12345678");
    data.append("logo", logo);
    backgroundImage != null && data.append("background_img", backgroundImage);
    data.append("first_color", titleColor);
    data.append("second_color", secondaryColor);

    // const company = {
    //   "name": values.name,
    //   "email": values.email,
    //   "phone": values.phone,
    //   "web": values.web,
    //   "cif": "12345678",
    // }

    mutationCompanyAdd.mutate({ token: user.access_token, company: data });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("CompanyNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("CompanyNew.title")} />
            <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
              <Card className="card-container shadow-none">
                <Row className="g-0 mt-5 mb-5">
                  <Col lg={12}>
                    <Row className='mb-5'>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.company_name") },
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            pattern="^\d{9}$"
                            errorMessage={t("Validation.not_phone")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <AvField
                            name="web"
                            className="form-control"
                            placeholder={t("Placeholder.web")}
                            type="text"
                            pattern="/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi"
                            errorMessage={t("Validation.not_url")}
                            validate={{
                              required: { value: false },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className='mb-2'>
                      <h5 className="MontSemiBold lh-1 text-black text-center">{t("Company.landing")}</h5>
                      <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.landing_sub")}</h6>
                    </Row>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4 d-flex justify-content-center" style={{height: 230}}>
                          {
                            logo ?
                              <>
                                <div style={{ position: "absolute", top: 40, marginLeft: 100, zIndex: 2 }}>
                                  <i
                                    onClick={() => setLogo(null)}
                                    className="mdi mdi-close circle-video"
                                    style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0 }}>
                                  <img
                                    id="logo"
                                    style={{ width: '100px', height: '100px' }}
                                    className="bg-light"
                                    alt={logo.name}
                                    src={logo.preview}
                                  />
                                </div>
                              </>
                              :
                              <>
                                <Dropzone
                                  maxFiles={1}
                                  accept="image/jpeg, image/png"
                                  onDrop={(acceptedFiles, fileRejections) => {
                                    handleAcceptedFiles(acceptedFiles, fileRejections, "logo");
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone" style={{width: '100%'}}>
                                      <div
                                        className="dz-message needsclick mt-0"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-0">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>{t("Company.drag_logo")}</h4>
                                        <p className='mx-2 mb-0 MontSemiBold fs-6'>{t("VideoNew.Step9.landing.logo.format.title")}</p>
                                        <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.logo.format.format")}</p>
                                        <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.logo.format.proportions")}</p>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </>
                          }
                        </div>
                        <div className="mb-4 p-3 border-radius-1" style={{ backgroundColor: titleColor + "3D" }}>
                          <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                            <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                              <AvField
                                type="color"
                                name="title_color"
                                defaultValue={titleColor}
                                value={titleColor}
                                className="p-0"
                                style={{ height: 34, width: 34 }}
                                onChange={(event) => setTitleColor(event.target.value)}
                              />
                            </Col>
                            <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                              <p className='m-0' style={{ color: titleColor }}>{t("Company.title_color")}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4 d-flex justify-content-center" style={{ height: 230 }}>
                          {
                            backgroundImage ?
                              <>
                                <div style={{ position: "absolute", top: 40, right: 0, zIndex: 2 }}>
                                  <i
                                    onClick={() => setBackgroundImage(null)}
                                    className="mdi mdi-close circle-video"
                                    style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0, width: '100%' }}>
                                  <img
                                    id="bg-image"
                                    style={{ width: '100%', height: '100px' }}
                                    className="bg-light"
                                    alt={backgroundImage.name}
                                    src={backgroundImage.preview}
                                  />
                                </div>
                              </>
                              :
                              <>
                                <Dropzone
                                  maxFiles={1}
                                  accept="image/jpeg, image/png"
                                  onDrop={(acceptedFiles, fileRejections) => {
                                    handleAcceptedFiles(acceptedFiles, fileRejections, "background");
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone" style={{ width: '100%' }}>
                                      <div
                                        className="dz-message needsclick mt-0"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-0">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>{t("Company.drag_background")}</h4>
                                        <p className='mx-2 mb-0 MontSemiBold fs-6'>{t("VideoNew.Step9.landing.background.format.title")}</p>
                                        <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.background.format.format")}</p>
                                        <p className='my-0 mx-4 fs-6'>{t("VideoNew.Step9.landing.background.format.proportions")}</p>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </>
                          }
                        </div>
                        <div className="mb-4 p-3 border-radius-1" style={{ backgroundColor: secondaryColor + "3D" }}>
                          <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                            <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                              <AvField
                                type="color"
                                name="secondary_color"
                                defaultValue={secondaryColor}
                                value={secondaryColor}
                                className="p-0"
                                style={{ height: 34, width: 34 }}
                                onChange={(event) => setSecondaryColor(event.target.value)}
                              />
                            </Col>
                            <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                              <p className='m-0' style={{ color: secondaryColor }}>{t("Company.secondary_color")}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <div className="text-center">
                    <button className='border-none bg-color-transparent text-decoration-underline text-red' onClick={(e) => {
                      e.preventDefault();
                      setBgHeight(backgroundImage != null ? document.getElementById("bg-image").naturalHeight : 500);
                      setShowModal(true)
                    }}>{t("Company.preview")}</button>
                  </div> */}
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("CompanyNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
        <ModalLandingPreview
          showModal={showModal}
          setShowModal={setShowModal}
          titleColor={titleColor}
          secondaryColor={secondaryColor}
          logo={logo?.preview}
          backgroundImage={backgroundImage?.preview}
          bgHeight={bgHeight} />
      </Container>
    </div>
  );
}

export default CompanyNew;