import React, { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';

// components
import ImageCard from 'components/views/Utils/ImageCard';
import EditImage from 'components/views/Utils/Edition/EditImage';
import { OnSymbol, Offsymbol } from 'components/views/Utils/SwitchStatus';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// services
import { TemplatesList, TemplateUpdate, TemplateDelete } from 'store/template/TemplateService';
// routes
import { TEMPLATE_NEW } from 'routes/app/paths';
import { COMAPANY, COMAPANY_TEMPLATES } from "routes/server/paths";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Templates = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [image, setImage] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const queryTemplateList = useQuery(['TEMPLATES', { token: user.access_token }], TemplatesList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.template.error.load_all"));
    },
    onSuccess: (data) => {
      setTemplates(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  const mutationTemplateUpdate = useMutation('UPDATE_TEMPLATE', TemplateUpdate, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.template.error.update"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.template.success.update"));
      // queryClient.refetchQueries(['TEMPLATES', { token: user.access_token }]);
    }
  });

  const mutationTemplateDelete = useMutation('DELETE_TEMPLATE', TemplateDelete, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.template.error.delete"));
    },
    onSuccess: (data) => {
      if (inputSearchRef.value != "") {
        inputSearchRef.value = "";
      }
      useToast("success", t("Message.info"), t("Message.template.success.delete"));
      queryClient.refetchQueries(['TEMPLATES', { token: user.access_token }]);
    }
  });

  function handleUpdateStatus(template) {
    const newTemplates = templates.map((e, i) => {
      if (e.id === template.id) {
        e.status = !e.status
      }
      return e;
    });
    setTemplates(newTemplates);
    mutationTemplateUpdate.mutate({ token: user.access_token, data: template });
  }

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleEditImage(id, name, url) {
    setImage({
      id,
      name,
      url
    });
    setShowModal(!showModal);
  }

  function handleDelete(id) {
    mutationTemplateDelete.mutate({ token: user.access_token, id });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Template.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Template.title")} subtitle={t("Template.subtitle")} />
            <div className="text-center">
              <Button
                onClick={() => history.push(TEMPLATE_NEW)}
                className="btn-shadow btn-general bg-color-primary border-none"
                color="app-green">
                {t("Template.select")}
              </Button>
            </div>
            <div className="my-4">
              <ToolBar originalList={originalList} list={templates} setList={setTemplates} setInputRef={setInputSearchRef} />
            </div>
            <Card className="card-container shadow-none" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  queryTemplateList.isLoading &&
                  <Loading style="video" />
                }
                {
                  templates &&
                  templates.map((e, i) => {
                    return (
                      <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <ImageCard name={e.title} date={e.created_at} srcImage={`${COMAPANY}${e.company_id}${COMAPANY_TEMPLATES}${e.template}`} />
                        <Row className="mb-3 justify-content-between">
                          <Col className="col-md-5 text-center">
                            <Switch
                              width={45}
                              height={20}
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="ms-3 mt-2"
                              onColor="#4ab28c"
                              onChange={() => {
                                handleUpdateStatus({ ...e, "status": !e.status })
                              }}
                              checked={e.status}
                            />
                          </Col>
                          <Col className="col-md-5 text-center">
                            <i className="mdi mdi-image-edit-outline ms-1" onClick={() => handleEditImage(e.id, e.title, `${COMAPANY}${e.company_id}${COMAPANY_TEMPLATES}${e.template}`)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                            <i className="mdi mdi-trash-can-outline me-2" onClick={() => { handleDelete(e.id) }} style={{ fontSize: 24, cursor: "pointer" }}></i>
                          </Col>
                        </Row>
                      </Col>
                    )
                  })
                }
              </Row>
            </Card>
          </Col>
        </Row>
        <EditImage
          image={image}
          showModal={showModal}
          setShowModal={setShowModal}
          toggleModal={toggleModal} />
      </Container>
    </div>
  );
}

export default Templates;
