import React, { useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import EditVideo from 'components/views/Utils/Edition/EditVideo';
import ModalPresentationNew from "components/views/Presentation/ModalPresentationNew";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// routes
import { COMAPANY, COMAPANY_VIDEOS } from "routes/server/paths";
// hooks
import useAuthContext from 'hooks/useAuthContext';

const Open = ({ 
  open,
  setOpen,
  originalOpens,
  opens,
  setOpens,
  showView,
  toggle,
  openDynamic,
  setOpenDynamic,
  setOpenImageStage,
  setOpenVideoScreen
  /*open, setOpen, originalOpens, opens, setOpens, openDelete, setOpenDelete, showModal, setShowModal, toggleModal*/ }) => {
  const [video, setVideo] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [showModalPresentation, setShowModalPresentation] = useState(false);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [showModalEditVideo, setShowModalEditVideo] = useState(false);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);

  function toggleModalPresentation() {
    setShowModalPresentation(!showModalPresentation);
    document.body.classList.add("no_padding");
  }

  function handleCheckboxChange(video, editing = false) {
    const index = originalOpens.findIndex(e => e.id == video.id);
    const newOpens = [...originalOpens];
    const selectedVideo = originalOpens[index];
    if (editing) {
      if (open == null) {
        setOpen(video);
        newOpens[index].checked = true;
        return;
      } else if (open.id == selectedVideo.id) {
        return;
      } else if (open.id != selectedVideo.id) {
        const oldIndex = originalOpens.findIndex(e => e.id == open.id);
        newOpens[oldIndex].checked = !newOpens[oldIndex].checked;
        setOpen(video);
        newOpens[index].checked = true;
        return;
      }
    } 
    if (open == null) {
      setOpen(video);
    } else if (open.id == selectedVideo.id) {
      setOpen(null);
    } else {
      const oldIndex = originalOpens.findIndex(e => e.id == open.id);
      newOpens[oldIndex].checked = !newOpens[oldIndex].checked;
      setOpen(video);
    }
    newOpens[index].checked = !newOpens[index].checked;
    // setOpens(newOpens);
    // inputSearchRef.value = "";
  }

  function toggleModalEditVideo() {
    setShowModalEditVideo(!showModalEditVideo);
    document.body.classList.add("no_padding");
  }

  function handleEditVideo(video, url) {
    setVideoSelected(video);
    setVideo({
      id: video.id,
      name: video.title,
      url
    });
    toggleModalEditVideo();
  }

  return (
    <>
    <AvForm>
      <Row>
        <Col className="col-sm-12 col-md-12 offset-md-0">
          <div className="mt-1 mb-5 text-center">
            {
              // user.role != 'Agente' &&
              <Button onClick={() => setShowModalPresentation(!showModalPresentation)} className="btn-shadow btn-general bg-color-primary" color="app-green">{t("VideoNew.Step8.open.add")}</Button>
            }
          </div>
          {
            opens &&
            <ToolBar originalList={originalOpens} list={opens} setList={setOpens} setInputRef={setInputSearchRef} />
          }
          <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
            {
              opens &&
              opens.map((e, i) => (
                <Col key={i} className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                  <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`} thumbnail={(e.thumbnail === null || e.thumbnail === 'null') ? null : `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.thumbnail}`} />
                  <Row className="mb-3 justify-content-between">
                    <Col className="col-md-5 text-center"> 
                      <i className="mdi mdi-movie-edit-outline" onClick={() => handleEditVideo(e, `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                    </Col>
                    <Col className="col-md-5 text-center align-self-center">
                      <div className="d-flex justify-content-center">
                        <input
                          type="checkbox"
                          style={{ height: 25, width: 25, float: "right" }}
                          className="form-clip-check"
                          name="check"
                          onClick={(event) => handleCheckboxChange(e)}
                          checked={e.checked} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </AvForm>
    <ModalPresentationNew
      type={"Open"}
      showModal={showModalPresentation}
      setShowModal={setShowModalPresentation}
      toggleModal={toggleModalPresentation} />
    <EditVideo
      video={video}
      showModal={showModalEditVideo}
      setShowModal={setShowModalEditVideo}
      toggleModal={toggleModalEditVideo}
      dynamicText={true}
      imagesP={images}
      setImagesP={setImages}
      textsP={texts}
      setTextsP={setTexts}
      dynamicP={openDynamic}
      setDynamicP={setOpenDynamic}
      setImageStage={setOpenImageStage}
      setVideoScreen={setOpenVideoScreen}
      videoSelected={videoSelected}
      handleCheckboxChange={handleCheckboxChange} />
    </>
  );
}

export default Open;
