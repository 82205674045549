import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Table from "components/views/Utils/Table";
import Loading from "components/views/Utils/Loading";
import CompanyDetail from "components/views/Company/CompanyDetail";
import Header from 'components/views/Utils/Header';
import ModalDeleteWithText from "components/views/Utils/ModalDeleteWithText";
// routes
import { COMPANY_NEW } from 'routes/app/paths';
// services
import { CompaniesList, CompanyGet, CompanyUpdate, CompanyDelete } from 'store/company/CompanyService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Companies = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [ companies, setCompanies] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [company, setCompany] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);

  const columns = [
    {
      dataField: 'name',
      text: t("Company.table.name"),
      sort: true
    },
    {
      dataField: 'email',
      text: t("Company.table.email"),
      sort: true
    },
    {
      dataField: 'phone',
      text: t("Company.table.phone"),
      sort: true
    },
    {
      dataField: 'web',
      text: t("Company.table.web"),
      sort: true,
      formatter: (cell, row) => {
        return (
          <Link
            to={{ pathname: `${cell}` }}
            target="_blank">
            {cell}
          </Link>
        );
      },
    },
    {
      dataField: 'id',
      text: '',
      sort: false,
      formatter: (cell, row) => {
        return (
          <Row className="mb-3">
            <Col className="col-md-4 text-center">
              <i className="mdi mdi-pencil-outline" onClick={() => handleView(cell)} style={{ fontSize: 24, cursor: "pointer" }}></i>
            </Col>
            <Col className="col-md-4 text-center">
              <i className="mdi mdi-trash-can-outline" onClick={() => handleDelete(cell)} style={{ fontSize: 24, cursor: "pointer" }}></i>
            </Col>
          </Row>
        );
      },
    }
  ];

  const queryCompanyList = useQuery(['COMPANIES', { token: user.access_token }], CompaniesList, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.company.error.load_all"));
    },
    onSuccess: (data) => {
      setCompanies(data.data.data);
    }
  });

  const mutationCompanyUpdate = useMutation('UPDATE_COMPANY', CompanyUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.company.error.update"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.company.success.update"));
      queryClient.refetchQueries(['COMPANIES', { token: user.access_token }]);
      setCompany(null);
    }
  });

  const mutationCompanyDelete = useMutation('DELETE_COMPANY', CompanyDelete, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.company.error.delete"));
      setShowModalDelete(!showModalDelete);
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.company.success.delete"));
      queryClient.refetchQueries(['COMPANIES', { token: user.access_token }]);
      setShowModalDelete(!showModalDelete);
    }
  });

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function toggleModalDelete() {
    setShowModalDelete(!showModalDelete);
    document.body.classList.add("no_padding");
  }

  // async function handleDelete(id) {
  //   if (user.company_id === id) {
  //     useToast("error", t("Message.error"), t("Message.company.error.not_delete"));
  //     return
  //   }
  //   mutationCompanyDelete.mutate({ token: user.access_token, id });
  // }

  function handleDelete(id) {
    if (user.company_id === id) {
      useToast("error", t("Message.error"), t("Message.company.error.not_delete"));
      return
    }
    setDataDelete(id);
    toggleModalDelete();
  }

  async function handleView(id) {
    const data = await queryClient.fetchQuery(['COMPANY_GET', { token: user.access_token, id: id }], CompanyGet);
    if (data.status === 200) {
      setCompany(data.data.data);
    }
    toggleModal();
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Company.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Company.title")} />
            <div className="text-center">
              <Button
                onClick={() => history.push(COMPANY_NEW)}
                className="btn-shadow btn-general bg-color-primary"
                color="app-green">
                {t("Company.new")}
              </Button>
            </div>
            {/* {
              queryCompanyList.isLoading &&
              <Loading style="table"/>
            } */}
            {
              companies ?
                <Table data={companies} columns={columns} />
              :
                <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}></Card>
            }
          </Col>
        </Row>
        <CompanyDetail
          company={company}
          setCompany={setCompany}
          mutationCompanyUpdate={mutationCompanyUpdate}
          showModal={showModal}
          setShowModal={setShowModal}
          toggleModal={toggleModal} />
        <ModalDeleteWithText
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          dataDelete={dataDelete}
          mutation={mutationCompanyDelete}
          model={t("Company.the_company")}
        />
      </Container>
    </div>
  );
}

export default Companies;
