import React from 'react';

const Advice = () => {
  return (
    <div>
      <p>Advice</p>
    </div>
  );
}

export default Advice;
