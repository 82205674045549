import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { useMutation } from "react-query";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import ModalLoading from "components/views/Utils/ModalLoading";
import ModalVideoRecord from "components/views/Utils/ModalVideoRecord";
import ModalPhotoCamera from "components/views/Utils/ModalPhotoCamera";
// services
import { PresentationAdd } from 'store/presentation/PresentationService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const PresentationNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [type, setType] = useState(null);
  const [welcome, setWelcome] = useState(false);
  const [bye, setBye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalVideoRecord, setShowModalVideoRecord] = useState(false);
  const [showModalPhotoCamera, setShowModalPhotoCamera] = useState(false);
  const [formRef, setFormRef] = useState(null);
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
    maxFiles: 1
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      setImage(image);
    }
  }, [acceptedFiles]);

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_video"));
      return;
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setVideo(files[0]);
  }

  const mutationPresentationAdd = useMutation('CREATE_CLIP', PresentationAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.presentation.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      setVideo(null);
      setImage(null);
      setType(null);
      formRef.reset();
      useToast("success", t("Message.info"), t("Message.presentation.success.add"));
    }
  });

  function handleCheckboxChange (type) {
    console.log(type);
    setType(type)
    switch (type) {
      case "Open":
        setWelcome(!welcome);
        setBye(false);
        break;
      case "End":
        setWelcome(false);
        setBye(!bye);
        break;
      default:
        break;
    }
  };

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    debugger

    if (errors.length > 0) {
      return;
    }

    if (type == null) {
      useToast("error", t("Message.alert"), t("Message.presentation.error.select_category"));
      return;
    }

    if (video == null) {
      useToast("error", t("Message.alert"), t("Message.presentation.error.load_video"));
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("video", video);
    image != null && data.append("thumbnail", image);
    data.append("title", values.name);
    data.append("description", "");
    data.append("type", type);

    if (document.getElementById('video_player').videoHeight > document.getElementById('video_player').videoWidth) {
      data.append("portrait", 1);
    } else {
      data.append("portrait", 0);
    }
    mutationPresentationAdd.mutate({ token: user.access_token, presentation: data });
  }

  return (
    <div className = "main-content">
      <MetaTags>
        <title>{t("PresentationNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("PresentationNew.title")} />
            <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
              <Card className="card-container shadow-none">
                <Row className="g-0 justify-content-evenly mt-4">
                  <Col className="col-12 col-sm-12 col-md-4 text-center mb-4">
                    {
                      video ?
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2" id="outer" style={{ padding: 0 }}>
                          <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                            <i
                              onClick={() => setVideo(null)}
                              className="mdi mdi-close circle-video"
                              style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                          </div>
                          <video id="video_player" src={video.preview} className="video" controls width="100%" height="300px"></video>
                        </div>
                      :
                      <>
                        <Dropzone
                          maxFiles={1}
                          accept="video/mp4"
                          onDrop={(acceptedFiles, fileRejections) => {
                            handleAcceptedFiles(acceptedFiles, fileRejections)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>{t("Message.drop.drag_video")}</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <Button onClick={() => setShowModalVideoRecord(true)} className="btn-shadow btn-general bg-color-darkgray color-white mt-3" color="app-green">Grábate</Button>
                      </>
                    }
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-4">
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("PresentationNew.name")}</h5>
                      <CardBody>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-4">
                              <AvField
                                name="name"
                                className="form-control"
                                placeholder={t("Placeholder.name")}
                                type="text"
                                validate={{
                                  required: { value: true, errorMessage: t("Validation.name") },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("PresentationNew.categories")}</h5>
                      <CardBody>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-4 d-flex align-items-center">
                              <AvInput
                                type="radio"
                                style={{ height: 25, width: 25, float: "right" }}
                                className="form-clip-check mt-0"
                                name={"Open"}
                                onClick={(event) => handleCheckboxChange("Open")}
                                value={"Open"}
                                checked={welcome} />
                              <p className="mb-0 MontSemiBold ms-2">{t("PresentationNew.open")}</p>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-4 d-flex align-items-center">
                              <AvInput
                                type="radio"
                                style={{ height: 25, width: 25, float: "right" }}
                                className="form-clip-check mt-0"
                                name={"End"}
                                onClick={(event) => handleCheckboxChange("End")}
                                value={"End"}
                                checked={bye} />
                              <p className="mb-0 MontSemiBold ms-2">{t("PresentationNew.close")}</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("PresentationNew.image")}</h5>
                      <CardBody className='text-center'>
                        {
                          image ?
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2" style={{ padding: 0 }}>
                              <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                                <i
                                  onClick={() => setImage(null)}
                                  className="mdi mdi-close circle-video"
                                  style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                              </div>
                              <img
                                style={{ width: '100%' }}
                                className="bg-light"
                                alt={image.name}
                                src={image.preview}
                              />
                            </div>
                          :
                            <>
                            <Row className="justify-content-center">
                              <Col className="col-12 col-sm-12 col-md-8 border-dashed border-bottom border-top m-3 bg-color-white d-flex p-5 justify-content-center align-items-center">
                                <div className="text-center">
                                  <input {...getInputProps()} />
                                  <Button onClick={() => open()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("PresentationNew.select")}</Button>
                                </div>
                              </Col>
                            </Row>
                            <Button onClick={() => setShowModalPhotoCamera(true)} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">Foto</Button>
                            </>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("PresentationNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
        <ModalLoading
          title={t("Message.video.loading.title")}
          subtitle={t("Message.video.loading.subtitle")}
          showModal={loading} />
        <ModalVideoRecord
          setVideo={setVideo}
          showModal={showModalVideoRecord}
          setShowModal={setShowModalVideoRecord} />
        <ModalPhotoCamera
          setImage={setImage}
          showModal={showModalPhotoCamera}
          setShowModal={setShowModalPhotoCamera} />
      </Container>
    </div>
  );
}

export default PresentationNew;
