export default function useSort(key=str.casefold, list, inverse, inside=false) {
  if (!inside) {
    if (inverse) {
      return [...list].sort((b, a) => {
        const keyA = a[key].toUpperCase();
        const keyB = b[key].toUpperCase();
        return (keyA > keyB) ? 1 : ((keyA < keyB) ? -1 : 0);
      })
    } else {
      return [...list].sort((a, b) => {
        const keyA = a[key].toUpperCase();
        const keyB = b[key].toUpperCase();
        return keyA > keyB ? 1 : keyA < keyB ? -1 : 0;
      })
    }
  } else {
    if (inverse) {
      return [...list].sort((b, a) => {
        const keyA = a.video[key].toUpperCase();
        const keyB = b.video[key].toUpperCase();
        return (keyA > keyB) ? 1 : ((keyA < keyB) ? -1 : 0);
      })
    } else {
      return [...list].sort((a, b) => {
        const keyA = a.video[key].toUpperCase();
        const keyB = b.video[key].toUpperCase();
        return keyA > keyB ? 1 : keyA < keyB ? -1 : 0;
      })
    }
  }
  
}