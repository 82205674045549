import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";
// services
import { TemplateAdd } from 'store/template/TemplateService';

const TemplateNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(false);

  const mutationTemplateAdd = useMutation('CREATE_TEMPLATE', TemplateAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.template.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      setImage(null);
      formRef.reset();
      useToast("success", t("Message.info"), t("Message.template.success.add"));
    }
  });

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setImage(files[0]);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    debugger;

    if (errors.length > 0) {
      return;
    }

    if (image == null) {
      useToast("error", t("Message.alert"), t("Message.template.error.load_image"));
      return;
    }

    setLoading(true);

    const data = new FormData();
    data.append("template", image);
    data.append("title", values.name);

    mutationTemplateAdd.mutate({ token: user.access_token, template: data });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("TemplateNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("TemplateNew.title")} />
            <AvForm className="form-horizontal ms-md-5 me-md-5 mt-sm-3" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)} >
              <Card className="card-container shadow-none">
                <Row className="g-0 justify-content-evenly mt-4 mb-4">
                  <Col className="col-12 col-sm-12 col-md-4">
                    {
                      image ?
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2" style={{ padding: 0 }}>
                          <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                            <i
                              onClick={() => setImage(null)}
                              className="mdi mdi-close circle-video"
                              style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                          </div>
                          <img
                            style={{ width: '100%' }}
                            className="bg-light"
                            alt={image.name}
                            src={image.preview}
                          />
                        </div>
                      :
                        <Dropzone
                          accept={"image/jpeg, image/png"}
                          maxFiles={1}
                          onDrop={(acceptedFiles, fileRejections) => {
                            handleAcceptedFiles(acceptedFiles, fileRejections)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>{t("Message.drop.drag_image")}</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                    }
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-4">
                    <Card className="mb-0 border-bottom shadow-lg">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("TemplateNew.name1")}<br />{t("TemplateNew.name2")}</h5>
                      <CardBody>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-4">
                              <AvField
                                name="name"
                                className="form-control"
                                placeholder={t("Placeholder.name")}
                                type="text"
                                validate={{
                                  required: { value: true, errorMessage: t("Validation.name") },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("TemplateNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TemplateNew;
