import React, { useState } from 'react';
import { useQuery } from "react-query";
import { Row, Col, Card, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from 'react-i18next';
import classnames from "classnames";

// components
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';
import Template from "components/views/Video/components/Setting/components/Introduction/Template";
import Open from "components/views/Video/components/Setting/components/Composition/Open";
import Close from "components/views/Video/components/Setting/components/Composition/Close";
import ModalVideoStructure from "components/views/Video/components/Setting/components/Modals/ModalVideoStructure";
// services
import { PresentationsList } from 'store/presentation/PresentationService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// config
import { encryptStorage } from 'config/encryptStorage';

const VIDEO_STRUCTURE_MODAL = 'VIDEO_STRUCTURE_MODAL';

const Step8 = ({ 
  back, 
  next, 
  logo, 
  setLogo, 
  template, 
  setTemplate,
  originalTemplates,
  setOriginalTemplates,
  open,
  setOpen,
  openDynamic,
  setOpenDynamic,
  setOpenImageStage,
  setOpenVideoScreen,
  close,
  setClose,
  closeDynamic,
  setCloseDynamic,
  setCloseImageStage,
  setCloseVideoScreen,
  docs,
  setDocs,
  cta,
  setCta,
  defaultText,
  setDefaultText,
  loading,
  createVideo,
  reuse
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  
  const [opens, setOpens] = useState(null);
  const [originalOpens, setOriginalOpens] = useState(null);
  const [closes, setCloses] = useState(null);
  const [originalCloses, setOriginalCloses] = useState(null);

  const [showLogoView, setShowLogoView] = useState(false);
  const [showTemplateView, setShowTemplateView] = useState(false);
  const [showOpenView, setShowOpenView] = useState(false);
  const [showCloseView, setShowCloseView] = useState(false);
  const [showDocsView, setShowDocsView] = useState(false);
  const [showCtaView, setShowCtaView] = useState(false);
  const [showDefaultTextView, setShowDefaultTextView] = useState(false);

  const [activeTab, setActiveTab] = useState("1");
  const [showModalStructure, setShowModalStructure] = useState(encryptStorage.getItem(VIDEO_STRUCTURE_MODAL) === undefined ? true : false);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const toggle = (view) => {
    setShowTemplateView(!showTemplateView);
    switch (view) {
      case "logo":
        setShowLogoView(!showLogoView);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "template":
        setShowTemplateView(!showTemplateView);
        setShowLogoView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "open":
        setShowOpenView(!showOpenView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "close":
        setShowCloseView(!showCloseView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "docs":
        setShowDocsView(!showDocsView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "cta":
        setShowCtaView(!showCtaView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowDefaultTextView(false);
        break;
      case "text":
        setShowDefaultTextView(!showDefaultTextView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        break;
      default:
        break;
    }
  }

  const queryPresentationList = useQuery(['PRESENTATIONS', { token: user.access_token }], PresentationsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.load_all"));
    },
    onSuccess: (data) => {
      const presentations = data.data.data;
      const newOpens = presentations.filter((e) => (e.type == "Open" && e.status == 1)).map(e => ({ ...e, checked: false }));
      const newCloses = presentations.filter((e) => (e.type == "End" && e.status == 1)).map(e => ({ ...e, checked: false }));
      if (open != null) {
        const index = newOpens.findIndex(e => e.id == open.id);
        newOpens[index].checked = !newOpens[index].checked;
      }
      if (close != null) {
        const index = newCloses.findIndex(e => e.id == close.id);
        newCloses[index].checked = !newCloses[index].checked;
      }
      setOpens(newOpens);
      setOriginalOpens(newOpens);
      setCloses(newCloses);
      setOriginalCloses(newCloses);
    }
  });

  return (
    <>
      <Row className="g-0 justify-content-center">
        <WizardStep step={3} />
        <Col className="col-sm-12 col-md-12 col-12 offset-md-0">
          <Header title={t("VideoNew.Step8.title")} subtitle={t("VideoNew.Step8.subtitle")} subClass="lh-3" />
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "1" },
                  (activeTab === "1") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                {t("VideoNew.Step8.cover.tab")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "2" },
                  (activeTab === "2") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                {t("VideoNew.Step8.open.tab")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "3"},
                  (activeTab === "3") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                {t("VideoNew.Step8.close.tab")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step8.cover.text")}</p>
                      <Template
                        template={template}
                        setTemplate={setTemplate} />
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step8.open.text")}</p>
                      <Open
                        open={open}
                        setOpen={setOpen}
                        originalOpens={originalOpens}
                        setOriginalOpens={setOriginalOpens}
                        opens={opens} setOpens={setOpens}
                        showView={showOpenView}
                        toggle={toggle}
                        openDynamic={openDynamic}
                        setOpenDynamic={setOpenDynamic}
                        setOpenImageStage={setOpenImageStage}
                        setOpenVideoScreen={setOpenVideoScreen} />
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step8.close.text")}</p>
                      <Close
                        close={close}
                        setClose={setClose}
                        originalCloses={originalCloses}
                        setOriginalCloses={setOriginalCloses}
                        closes={closes} setCloses={setCloses}
                        showView={showCloseView}
                        toggle={toggle}
                        closeDynamic={closeDynamic}
                        setCloseDynamic={setCloseDynamic}
                        setCloseImageStage={setCloseImageStage}
                        setCloseVideoScreen={setCloseVideoScreen} />
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => back(8)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button onClick={() => next(8, 9)} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
          </div>
        </Col>
      </Row>
      <ModalVideoStructure
        showModal={showModalStructure}
        setShowModal={setShowModalStructure}
      />
    </>
  );
}

export default Step8;
