import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import { Container, Row, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// hooks
import useAuthContext from 'hooks/useAuthContext';
// routes
import { LOGIN, VIDEOS } from 'routes/app/paths';

const RegisterSuccess = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { login, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(VIDEOS);
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <div className="main-content bg-image-register-success">
        <MetaTags>
          <title>{t("Auth.Register.meta")}</title>
        </MetaTags>
        <Container fluid className="content" style={{ height: "80vh" }}>
          <Card className="border-radius-10 col-sm-12 col-md-6 offset-md-3 text-center mb-5">
            <Row className="g-0 p-5">
              <h1 className="MontSemiBold font-size-md lh-1 text-black ">{t("Auth.RegisterSuccess.title")}</h1>
              <h4 className="mb-5 mt-5 lh-1 text-black">{t("Auth.RegisterSuccess.subtitle1")}</h4>
              <h4 className="mb-5 lh-1 text-black">{t("Auth.RegisterSuccess.subtitle2")}</h4>
              <div className="text-center mt-3">
                <Button onClick={() => history.push(LOGIN)} className="btn-shadow btn-main-block" color="app-primary">{t("Auth.Login.signin")}</Button>
              </div>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisterSuccess;
