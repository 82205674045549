import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Row, Col, Modal, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { ProductsList } from "store/product/ProductService";
import { CategoryAdd } from 'store/category/CategoryService';

const ModalCategoryAdd = ({ showModal, setShowModal, toggleModal }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [name, setName] = useState("");
  const [products, setProducts] = useState("");
  const [formRef, setFormRef] = useState(null);

  const { data, status } = useQuery(['PRODUCTS', { token: user.access_token }], ProductsList, {
    staleTime: "Infinity",
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_products"));
    }
  });

  useEffect(() => {
    if (status === "success") {
      setProducts(data.data.data);
    }
  }, [data, status])

  const mutationCategoryAdd = useMutation('CREATE_CATEGORY', CategoryAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.category.error.add"));
    },
    onSuccess: (data) => {
      formRef.reset();
      useToast("success", t("Message.info"), t("Message.category.success.add"));
      setShowModal(false);
      queryClient.refetchQueries(['CATEGORIES', { token: user.access_token }]);
    }
  })

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    const category = {
      'name': values.name,
      'product_id': products[0].id,
      'status': 1
    }
    mutationCategoryAdd.mutate({ token: user.access_token, category });
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header title={t("ModalCategoryAdd.title")} modal={true}/>
              <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
                <Row className="g-0 justify-content-center">
                  <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                    <AvField
                      name="name"
                      className="form-control"
                      placeholder={t("Placeholder.name")}
                      type="text"
                      validate={{
                        required: { value: true, errorMessage: t("Validation.name") },
                      }}
                    />
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="btn-shadow btn-general bg-color-green m-3" color="app-green">{t("ModalCategoryAdd.save")}</Button>
                </div>
              </AvForm>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalCategoryAdd;
