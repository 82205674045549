import React, { useState, useEffect } from 'react';
import { Modal } from "reactstrap";

// hooks
import useFormatBytes from "hooks/useFormatBytes";

const ModalPhotoCamera = ({
  setImage,
  showModal,
  setShowModal
}) => {
  const [stream, setStream] = useState(null);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (showModal && video) {
      const options = {
        audio: false,
        video: {
          width: { min: 1280 },
          height: { min: 720 },
          facingMode: "user"
        }
      }

      navigator.mediaDevices.getUserMedia(options)
        .then(function (stream) {
          video.srcObject = stream;
          setStream(stream);
          video.play();
        })
        .catch(function (err) {
          console.log("An error occurred: " + err);
        });
    }
  }, [showModal, video]);

  function toggleModal() {
    stopStream();
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  const stopStream = () => {
    debugger;
    setVideo(null);
    if (stream) {
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  const takePhoto = () => {
    setShowModal(false);
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    canvas.width = 1920;
    canvas.height = 1080;
    context.drawImage(video, 0, 0, 1920, 1080);

    canvas.toBlob(function (blob) {
      stopStream();
      const file = new File([blob], "file_name.jpg", { lastModified: Date.now(), type: blob.type });

      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size)
      })

      setImage(file);
    }, 'image/jpeg', 1);
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              stopStream();
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <video ref={(video) => setVideo(video)} className="video" width="100%" height="100%">Video stream not available.</video>
          <div class="action-wrapper">
            <div class="record-button-wrapper">
              {/* <div class="record-button-instruction">
                <div>PRESS </div>
                <div class="record-button-instruction-highlight"> REC </div>
                WHEN READY
              </div> */}
              <div class="record-button-border">
                <button onClick={takePhoto} type="button" color="black" class="record-button"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
}

export default ModalPhotoCamera;