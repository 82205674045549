import React, { useState } from 'react';
import { Row, Col, Card, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

// components
import ModalVideoView from 'components/views/Utils/ModalVideoView';
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';
// routes
import { COMAPANY, COMAPANY_CLIPS } from "routes/server/paths";
// images
import play from "assets/images/app/video/play.png";
import thumb from "assets/images/app/video/thumbnail.png";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  width: 170,

  // change background colour if dragging
  // background: isDragging && "lightgreen",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getPlusStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer

  // change background colour if dragging
  display: isDragging && "none",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  display: "flex",
  padding: grid,
  width: itemsLength * 250 + 16
});

const Step6 = ({ back, next, clips, setClips, selectedClips, setSelectedClips, originalClips, setOriginalClips }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [srcVideo, setSrcVideo] = useState(null);

  console.log(selectedClips);

  const handleVideoView = (video) => {
    setSrcVideo(video);
    setShowModal(!showModal);
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const reorderItems = reorder(
      selectedClips,
      result.source.index,
      result.destination.index
    );
    setSelectedClips(reorderItems);
    console.log(reorderItems);
  }

  function handleRemoveClip(clip) {
    // remove for selected clips
    const newSelectedClips = selectedClips.filter((item) => item.id !== clip.id);
    setSelectedClips(newSelectedClips);

    // uncheck for clips checked
    const indexO = originalClips.indexOf(clip);
    const newClipsO = [...originalClips];
    newClipsO[indexO].checked = !originalClips[indexO].checked;
    setOriginalClips(newClipsO);

    const index = clips.indexOf(clip);
    const newClips = [...clips];
    newClips[index].checked = newClipsO[indexO].checked;
    setClips(newClips);
  }

  return (
    <>
      <Row className="g-0 justify-content-center">
        <Col className="col-sm-12 col-12 col-md-10 offset-md-1">
          <WizardStep step={2} />
          <Header title={t("VideoNew.Step6.title")} subtitle={t("VideoNew.Step6.subtitle")} />
          <div className="mb-4 text-center">
            <Button onClick={() => back(6)} className="btn-shadow btn-general bg-color-primary" color="app-green">{t("VideoNew.Step6.add")}</Button>
          </div>
          <AvForm>
            <Card className="card-container shadow-none">
              <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0 mt-2 p-2">
                <Col style={{ overflowX: "scroll" }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver, selectedClips.length)}
                          {...provided.droppableProps}
                        >
                          {selectedClips.map((item, index) => (
                            <>
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <div style={{ width: 150 }}>
                                    <div type="button" className="btn btn-wizard-edit btn-shadow bg-color-white p-0 avatar-xs rounded-circle">
                                      <span className="avatar-title bg-transparent text-reset">
                                        <i className="bx bx-move text-red" style={{ fontSize: 20 }}></i>
                                      </span>
                                    </div>
                                    <button type="button" onClick={() => handleRemoveClip(item)} className="btn btn-wizard-delete btn-shadow bg-color-white p-0 avatar-xs rounded-circle">
                                      <span className="avatar-title bg-transparent text-reset">
                                        <i className="bx bx-x text-red" style={{ fontSize: 26 }}></i>
                                      </span>
                                    </button>
                                      <div className="col-lg-12 col-md-12 col-sm-12" id="outer" onClick={handleVideoView.bind(this, `${COMAPANY}${item.company_id}${COMAPANY_CLIPS}${item.clip}`)} style={{ padding: 0, height: 100, cursor: 'pointer' }}>
                                      <img
                                          src={(item.thumbnail === null || item.thumbnail === 'null') ? thumb : `${COMAPANY}${item.company_id}${COMAPANY_CLIPS}${item.thumbnail}`}
                                        alt=""
                                        className="video"
                                        width="100%"
                                        height="100px"
                                      />
                                      <div style={{ position: "relative", top: -100 }}>
                                        <img
                                          src={play}
                                          alt=""
                                          className="video"
                                          width="100%"
                                          height="100px"
                                          style={{ backgroundColor: 'transparent' }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                            {
                              (selectedClips.length != (index + 1)) &&
                              <button type="button" style={getPlusStyle(snapshot.isDraggingOver)} onClick={() => handleBackStep(6)} className="btn bg-color-white p-0 avatar-xs rounded-circle align-self-center mt-4">
                                <span className="avatar-title bg-transparent text-reset">
                                  <i className="bx bx-plus" style={{ fontSize: 24 }}></i>
                                </span>
                              </button>
                            }
                            </>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Col>
              </Row>
            </Card>
            <div className="mt-3 mb-4 text-center">
              <Button onClick={() => back(6)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
              <Button onClick={() => next(6, 8)} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
            </div>
          </AvForm>
        </Col>
        <Col className="col-sm-12 col-lg-1 col-12"></Col>
      </Row>
      <ModalVideoView
        video={srcVideo}
        showModal={showModal}
        setShowModal={setShowModal} 
      />
    </>
  );
}

export default Step6;
