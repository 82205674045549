import {
  statisticsGet,
  statisticsUpdateCTA,
  statisticsUpdateDownload
} from 'routes/api/paths';
import { headers } from 'config/headers';

/**
 * @description Get video statistics
 * @param {*} videoId
 * @param {*} token
 * @returns statistics
 */
export const StatisticsGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[0].token),
  }
  return fetch(`${statisticsGet}/${params.queryKey[0].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Statistics video update CTA clicks
 * @param {*} video id
 * @param {*} token
 * @returns 
 */
export const StatisticsUpdateCTA = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params),
  }
  return fetch(statisticsUpdateCTA, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Statistics video update download docs clicks
 * @param {*} video id
 * @param {*} token
 * @returns 
 */
export const StatisticsUpdateDownload = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params),
  }
  return fetch(statisticsUpdateDownload, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}