import React from 'react';
import { Modal } from "reactstrap";

const ModalVideoView = ({ 
  video,
  showModal,
  setShowModal
}) => {

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <video src={video} className="video" controls width="100%" height="100%"></video>
        </div>
      </div>
    </Modal >
  );
}

export default ModalVideoView;