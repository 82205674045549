import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
// import Footer from "components/layout/HorizontalLayout/Footer";
import Footer from "components/layout/LandingLayout/Footer";

//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "store/layout/actions";

const LandingLayout = (props) => {
  const dispatch = useDispatch();

  const {
    topbarTheme, layoutWidth, isPreloader, showRightSidebar
  } = useSelector(state => ({
    topbarTheme: state.Layout.topbarTheme,
    layoutWidth: state.Layout.layoutWidth,
    isPreloader: state.Layout.isPreloader,
    showRightSidebar: state.Layout.showRightSidebar,
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  return (
    <React.Fragment>
      <div className="main-content">{props.children}</div>
      <Footer />
    </React.Fragment>
  );
}

LandingLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

export default withRouter(LandingLayout)
