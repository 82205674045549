import React, { useState } from "react";
import ReactDrawer from 'react-drawer';
import { Button } from "reactstrap";
import 'react-drawer/lib/react-drawer.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Import menuDropdown
import LanguageDropdown from "components/layout/CommonForBoth/TopbarDropdown/LanguageDropdown";
import RightSidebar from "components/layout/NonAuthLayout/RightSidebar";

// import logo"
import logo from "assets/images/app/logo/logo.png";

const Header = () => {
  const { t } = useTranslation();
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="shadow-none bg-white position-relative">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={logo} alt="" height="61" width="146" />
                </span>
              </Link>
            </div>
            <div className="d-lg-none ms-2">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="41" width="98" />
                </span>
              </Link>
            </div>
          </div>

          {/* <div className="d-flex d-sm-none-n">
            <Link to="/" className="MontSemiBold mb-0 ms-2 me-2 text-black text-decoration-none d-block">{t("HeaderNonAuth.home")}</Link>
            <Link to="/" className="MontSemiBold mb-0 ms-2 me-2 text-black text-decoration-none d-block">{t("HeaderNonAuth.plat")}</Link>
            <Link to="/" className="MontSemiBold mb-0 ms-2 me-2 text-black text-decoration-none d-block">{t("HeaderNonAuth.blog")}</Link>
            <Link to="/" className="MontSemiBold mb-0 ms-2 me-2 text-black text-decoration-none d-block">{t("HeaderNonAuth.price")}</Link>
            <Link to="/" className="MontSemiBold mb-0 ms-2 me-2 text-black text-decoration-none d-block">{t("HeaderNonAuth.contact")}</Link>
          </div> */}

          <div className="d-flex">
            <LanguageDropdown />
            <div className="dropdown d-inline-block d-lg-none">
              <button
                onClick={toggleTopDrawer} 
                disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
            <div className="dropdown d-inline-block align-self-center d-sm-none-n">
              <Button className="btn-shadow btn-web bg-color-black">{t("HeaderNonAuth.web")}</Button>
            </div>
          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

export default Header;
