import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import classnames from "classnames";

const CategoryCard = ({ tutorial, selected, text, onChange}) => {
  return (
    <div onClick={onChange} style={{ cursor: "pointer" }}>
      <Card className={classnames(
        "shadow-lg",
        "rounded-3",
        selected === tutorial && "tutorial-border"
      )} 
      style={{ minWidth: 130, minHeight: 130, maxWidth: 130 }}>
        <CardBody className="d-flex align-items-center justify-content-center">
          <CardText>
            {text}
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
}

export default CategoryCard;
