import React, { useState, useRef } from 'react';
import { Row, Col } from "reactstrap";
import ReactPlayer from 'react-player';

// components
import VideoControl from "components/views/Utils/Edition/components/VideoControl";

const VideoPlayer = ({ video }) => {
  const videoRef = useRef();
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(0);

  function handleProgress(state) {
    setProgress(state.playedSeconds);
  }

  function handleDuration(duration) {
    setDuration(duration);
  }

  return (
    <Row>
      <Col className="col-12 col-md-12">
        <ReactPlayer
          id="player"
          ref={videoRef}
          url={video}
          // width="100%"
          // height="100%"
          style={{ maxWidth: 585, maxHeight: 336 }}
          playing={playing}
          muted={muted}
          onDuration={handleDuration}
          onProgress={handleProgress} />
      </Col>
      <Col className="col-12 col-md-12">
        <VideoControl
          videoRef={videoRef}
          playing={playing}
          muted={muted}
          progress={progress}
          duration={duration}
          setProgress={setProgress}
          setPlaying={setPlaying}
          setMuted={setMuted}
          setSeeking={setSeeking} />
      </Col>
    </Row>
  );
}

export default VideoPlayer;
