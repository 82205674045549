import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';

// components
import Table from "components/views/Utils/Table";
import { OnSymbol, Offsymbol } from 'components/views/Utils/SwitchStatus';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
// routes
import { SUBCATEGORY_DETAIL } from 'routes/app/paths';
// services
import { CategoryListByProduct, CategoryAdd, CategoryUpdate, CategoryDelete } from 'store/category/CategoryService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const CategoryDetail = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { category } = useParams();
  const queryClient = useQueryClient();
  const [categories, setCategories] = useState(null);

  const queryCategoriesList = useQuery(['CATEGORIES', { token: user.access_token, id: category }], CategoryListByProduct, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_all"));
    },
    onSuccess: (data) => {
      setCategories(data.data.data);
    }
  });

  const mutationCategoryAdd = useMutation('CREATE_CATEGORY', CategoryAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.category.error.add"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.category.success.add"));
      queryClient.refetchQueries(['CATEGORIES', { token: user.access_token }]);
    }
  })

  const mutationCategoryUpdate = useMutation('UPDATE_CATEGORY', CategoryUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.category.error.update"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.category.success.update"));
      queryClient.refetchQueries(['CATEGORIES', { token: user.access_token }]);
    }
  });

  const mutationCategoryDelete = useMutation('DELETE_CATEGORY', CategoryDelete, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.category.error.delete"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.category.success.delete"));
      queryClient.refetchQueries(['CATEGORIES', { token: user.access_token }]);
    }
  });

  const columns = [
    {
      dataField: 'name',
      text: t("CategoryDetail.table.name"),
      sort: true,
      formatter: (cell, row) => {
        if (cell === "NOMBRE") {
          return (<p className='text-red m-0 border-bottom-red-1'>{cell}</p>);
        } else {
          return (<p className='m-0'>{cell}</p>);

        }
      },
    }, {
      dataField: 'id',
      text: t("CategoryDetail.table.video"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <i
            id={`edit-${cell}`}
            key={`edit-${row.id}`}
            className="mdi mdi-movie-open"
            style={{ fontSize: 24, cursor: "pointer" }}
            onClick={() => handleDetail(cell, row)} />
        );
      },
    }, {
      dataField: 'status',
      text: t("CategoryDetail.table.switch"),
      sort: true,
      editable: false,
      formatter: (cell, row) => (
        <Switch
          key={`switch-${row.id}`}
          width={45}
          height={20}
          uncheckedIcon={<Offsymbol />}
          checkedIcon={<OnSymbol />}
          className="ms-3 mt-2"
          onColor="#4ab28c"
          onChange={() => handleChangeStatus(row)}
          checked={cell}
        />
      ),
      formatExtraData: categories
    }, {
      dataField: 'id',
      text: t("CategoryDetail.table.delete"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <i
            key={`delete-${row.id}`}
            onClick={() => handleDelete(cell)}
            className="mdi mdi-trash-can-outline"
            style={{ fontSize: 24, cursor: "pointer" }} />
        );
      },
    }
  ];

  const onAddCategory = () => {
    const modifiedRows = [{ id: categories.length + 10000000, "name": "NOMBRE", "status": true }].concat(categories);
    setCategories(modifiedRows);
  }

  function editRow(oldValue, newValue, row, column) {
    // modificar nombre de la categoria
    if (row.id >= 10000000) {
      // create
      const categoryAdd = {
        'name': row.name,
        'product_id': category,
        'status': row.status
      }
      mutationCategoryAdd.mutate({ token: user.access_token, category: categoryAdd });
    } else {
      // update
      const categoryUpdate = {
        'id': row.id,
        'name': row.name,
        'product_id': category,
        'status': row.status
      }
      mutationCategoryUpdate.mutate({ token: user.access_token, category: categoryUpdate });
    }
  }

  function handleChangeStatus(row) {
    const index = categories.findIndex((e) => e.id === row.id);

    if (row.id >= 10000000) {
      // create
      const modifiedData = [...categories];
      modifiedData[index].status = !modifiedData[index].status;
      setCategories(modifiedData);
    } else {
      // update
      const modifiedData = [...categories];
      modifiedData[index].status = !modifiedData[index].status;
      setCategories(modifiedData);

      const categoryUpdate = {
        'id': row.id,
        'name': row.name,
        'product_id': category,
        'status': row.status
      }
      mutationCategoryUpdate.mutate({ token: user.access_token, category: categoryUpdate });
    }
  }

  function handleDetail(id, row) {
    if (id < 10000000) {
      history.push({
        pathname: `${SUBCATEGORY_DETAIL}/${id}`,
        state: { name: row.name }
      });
    }
  }

  function handleDelete(id) {
    if (id >= 10000000) {
      const modifiedData = categories.filter((e, i) => {
        return e.id != id;
      });
      setCategories(modifiedData);
    } else {
      mutationCategoryDelete.mutate({ token: user.access_token, id });
    }
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("CategoryDetail.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={props.location.state.name} subtitle={t("CategoryDetail.subtitle")} />
            <div className="text-center">
              <Button
                onClick={() => onAddCategory()}
                className="btn-shadow btn-general bg-color-primary"
                color="app-green">
                {t("CategoryDetail.new")}
              </Button>
            </div>
            {/* {
              (queryCategoriesList.isLoading) &&
              <Loading style="table" />
            } */}
            {
              categories ?
                <Table data={categories} columns={columns} editing={true} editRow={editRow} sorted={false} />
              :
                <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}></Card>
            }
            <div className="mt-3 mb-4 text-center">
              <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">← {t("VideoNew.back")}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CategoryDetail;
