import {
  productList,
  productGet,
  productAdd,
  productUpdate,
  productDelete,
  productClipList
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description List of products
 * @param {*} access_token
 * @returns products
 */
export const ProductsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(productList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add product
 * @param {*} product to add and token
 * @returns products
 */
export const ProductAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.category),
  }
  return fetch(productAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update product
 * @param {*} category
 * @param {*} token
 * @returns category
 */
export const ProductUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.category),
  }
  console.log("requestOptions", requestOptions);
  return fetch(productUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete product
 * @param {*} category
 * @param {*} token
 * @returns category
 */
export const ProductDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      product_id: params.id
    }),
  }
  return fetch(productDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get clips by product
 * @param {*} access_token
 * @param {*} product_id
 * @returns 
 */
export const ProductClipsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${productClipList}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}
