import { DOMAIN } from "routes/server/paths";

// API
export const API = DOMAIN + 'api/';
export const API_V1 = API + 'v1/';

// AUTH
export const token = DOMAIN + 'oauth/token';
export const user = API + 'user';
export const forgotPassword = API + 'forgot-password';
export const resetPassword = API + 'reset-password';
export const confirmRegister = API + 'confirm-register';

// COMPANIES
export const companyList = API_V1 + 'company-list';
export const companyGet = API_V1 + 'company-get';
export const companyAdd = API_V1 + 'company-add';
export const companyUpdate = API_V1 + 'company-update';
export const companyDelete = API_V1 + 'company-delete';

// PRODUCTS
export const productList = API_V1 + 'product-list';
export const productGet = API_V1 + 'product-get';
export const productAdd = API_V1 + 'product-add';
export const productUpdate = API_V1 + 'product-update';
export const productDelete = API_V1 + 'product-delete';
export const productClipList = API_V1 + 'product-clip-get-all';

// CATEGORIES
export const categoryList = API_V1 + 'category-list';
export const categoryListByProduct = API_V1 + 'category-by-product';
export const categoryGet = API_V1 + 'category-get';
export const categoryAdd = API_V1 + 'category-add';
export const categoryUpdate = API_V1 + 'category-update';
export const categoryDelete = API_V1 + 'category-delete';
export const categoryClipList = API_V1 + 'category-clip-get-all';
export const categoryClipsUpdate = API_V1 + 'category-clip-update'; // category and clips array

// CLIPS
export const clipList = API_V1 + 'clip-list';
export const clipGet = API_V1 + 'clip-get';
export const clipAdd = API_V1 + 'clip-add';
export const clipUpdate = API_V1 + 'clip-update';
export const clipDelete = API_V1 + 'clip-delete';
export const clipCategoriesUpdate = API_V1 + 'clip-category-update'; // clip and categories array

// PRESENTATION
export const presentationList = API_V1 + 'video-presentation-list';
export const presentationGet = API_V1 + 'video-presentation-get';
export const presentationAdd = API_V1 + 'video-presentation-add';
export const presentationUpdate = API_V1 + 'video-presentation-update';
export const presentationDelete = API_V1 + 'video-presentation-delete';

// VIDEO
export const videoList = API_V1 + 'video-list';
export const videoAdd = API_V1 + 'video-add';
export const videoAddMultiple = API_V1 + 'video-add-multiple';
export const videoEdit = API_V1 + 'video-edit-converted';
export const videoUpdate = API_V1 + 'video-update'; // update video title
export const videoDelete = API_V1 + 'video-delete';
export const videoReuse = API_V1 + 'video-reuse'; // send video id
export const videoToken = API_V1 + 'get-video-token'; // get video token by id

// CLIENTS
export const clientList = API_V1 + 'client-list';
export const clientGet = API_V1 + 'client-get';
export const clientAdd = API_V1 + 'client-add';
export const clientUpdate = API_V1 + 'client-update';
export const clientDelete = API_V1 + 'client-delete';

// USERS
export const userList = API_V1 + 'user-list';
export const userListCompany = API_V1 + 'user-list-company';
export const userGet = API_V1 + 'user-get';
export const userAdd = API_V1 + 'user-register';
export const userUpdate = API_V1 + 'user-update';
export const userDelete = API_V1 + 'user-delete';

// TEMPLATES
export const templateList = API_V1 + 'template-list';
export const templateGet = API_V1 + 'template-get';
export const templateAdd = API_V1 + 'template-add';
export const templateUpdate = API_V1 + 'template-update';
export const templateDelete = API_V1 + 'template-delete';
export const templateEdit = API_V1 + 'template-edit-converted'; // edición de template

// STATISTICS
export const statisticsGet = API_V1 + 'statistics-get';
export const statisticsUpdateCTA = API + 'update-statistics-cta';
export const statisticsUpdateDownload = API + 'update-statistics-download';

// LANDING
export const landingGet = API + 'video-landing';