import React, { useState } from 'react';
import { useQuery, useQueryClient } from "react-query";
import { Row, Col, Card, Button, Modal } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import VideoCard from 'components/views/Utils/VideoCard';
// services
import { VideosList, VideoReuse } from 'store/video/VideoService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";

const ModalVideoReuse = ({ showModal, setShowModal, toggleModal, next, setClips, setSelectedClips, setCta, setReuse }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [videos, setVideos] = useState(null);

  const queryVideoList = useQuery(['VIDEOS', { token: user.access_token }], VideosList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.load_all"));
    },
    onSuccess: (data) => {
      setVideos(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  async function handleReuse(id) {
    const params = {
      token: user.access_token,
      id
    }
    const fetchReuse = await queryClient.fetchQuery([params], VideoReuse);
    if (fetchReuse.status === 200) {
      setClips(null);
      setSelectedClips(null);
      setCta([]);
      console.log(fetchReuse.status);
      setReuse(fetchReuse.data);
      setShowModal(!showModal);
      next(4, 5);
    }
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Header title={t("VideoNew.Step4.ModalVideoReuse.videos")} modal={true} />
          <ToolBar originalList={originalList} list={videos} setList={setVideos} setInputRef={setInputSearchRef} />
          <Card className="card-container shadow-none mt-4" style={{ maxHeight: 450, minHeight: 400, overflowY: 'scroll' }}>
            <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
              {
                queryVideoList.isLoading &&
                <Loading style="video" />
              }
              {
                videos &&
                videos.map((e, i) => {
                  return (
                    <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                      <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_OUTPUTS}${e.video}`} />
                      <div className="mb-3 text-center">
                        <Button className="btn-shadow btn-general bg-color-darkgray" color="app-green" onClick={() => { handleReuse(e.id) }}>{t("VideoNew.Step4.ModalVideoReuse.reuse")}</Button>
                      </div>
                    </Col>
                  );
                })
              }
            </Row>
          </Card>
        </div>
      </div>
    </Modal>
  );
}

export default ModalVideoReuse;
