import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import CategoryCard from 'components/views/Tutorial/components/CategoryCard';
import Create from 'components/views/Tutorial/components/Create';
import Send from 'components/views/Tutorial/components/Send';
import Advice from 'components/views/Tutorial/components/Advice';

const Tutorial = () => {
  const [tutorial, setTutorial] = useState(1);
  const [tutorialComponent, setTutorialComponent] = useState(<Create />);

  function handleChangeTutorial(tutorial) {
    setTutorial(tutorial);
    switch (tutorial) {
      case 1:
        setTutorialComponent(<Create />);
        break;
      case 2:
        setTutorialComponent(<Send />);
        break;
      case 3:
        setTutorialComponent(<Advice />);
        break;
      default:
        setTutorialComponent(<Create />);
        break;
    }
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>Tips y tutoriales</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={"Tips y tutoriales"} />
            <Card className="card-container shadow-none">
              <Row className="g-0">
                <Col className="col-sm-12 col-md-10 offset-md-1">
                  <Card className="shadow-none mb-0 mt-4">
                    <CardBody>
                      <Row className="g-0 justify-content-center">
                        <Col lg={2} className="text-center d-flex justify-content-center">
                          <CategoryCard tutorial={1} selected={tutorial} text="CREACIÓN DEL VÍDEO" onChange={handleChangeTutorial.bind(this, 1)} />
                        </Col>
                        <Col lg={2} className="text-center d-flex justify-content-center">
                          <CategoryCard tutorial={2} selected={tutorial} text="ENVÍO" onChange={handleChangeTutorial.bind(this, 2)} />
                        </Col>
                        <Col lg={2} className="text-center d-flex justify-content-center">
                          <CategoryCard tutorial={3} selected={tutorial} text="CONSEJOS DE GRABACIÓN EN CASA" onChange={handleChangeTutorial.bind(this, 3)} />
                        </Col>
                        <Col lg={2} className="text-center d-flex justify-content-center">
                          <CategoryCard tutorial={4} selected={tutorial} text="LOREM IPSUM" />
                        </Col>
                        <Col lg={2} className="text-center d-flex justify-content-center">
                          <CategoryCard tutorial={5} selected={tutorial} text="MAECENAS VITAE MOLLIS" />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <div className="line-100-green mb-5"></div>
                  <div className="mb-5">
                    {tutorialComponent}
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Tutorial;
