import React, { useState, useEffect } from 'react';
import { useQuery } from "react-query";
import { Row, Col, Card, Button, Spinner, Nav, NavItem, NavLink, TabContent, TabPane, CardText } from "reactstrap";
import { useTranslation } from 'react-i18next';
import classnames from "classnames";

// components
import WizardStep from "components/views/Video/components/WizardStep";
import Header from 'components/views/Utils/Header';
import Logo from "components/views/Video/components/Setting/components/Introduction/Logo";
import Template from "components/views/Video/components/Setting/components/Introduction/Template";
import Open from "components/views/Video/components/Setting/components/Composition/Open";
import Close from "components/views/Video/components/Setting/components/Composition/Close";
import Docs from "components/views/Video/components/Setting/components/Send/Docs";
import Cta from "components/views/Video/components/Setting/components/Send/Cta";
import DefaultText from "components/views/Video/components/Setting/components/Send/DefaultText";
import Landing from "components/views/Video/components/Setting/components/Send/Landing";
// services
import { PresentationsList } from 'store/presentation/PresentationService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Step9 = ({
  back,
  next,
  logo,
  setLogo,
  template,
  setTemplate,
  originalTemplates,
  setOriginalTemplates,
  open,
  setOpen,
  openDynamic,
  setOpenDynamic,
  setOpenImageStage,
  setOpenVideoScreen,
  close,
  setClose,
  closeDynamic,
  setCloseDynamic,
  setCloseImageStage,
  setCloseVideoScreen,
  docs,
  setDocs,
  cta,
  setCta,
  defaultText,
  setDefaultText,
  landingLogo,
  setLandingLogo,
  backgroundImage,
  setBackgroundImage,
  titleColor,
  setTitleColor,
  secondaryColor,
  setSecondaryColor,
  loading,
  createVideo,
  reuse
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const [opens, setOpens] = useState(null);
  const [originalOpens, setOriginalOpens] = useState(null);
  const [closes, setCloses] = useState(null);
  const [originalCloses, setOriginalCloses] = useState(null);

  const [showLogoView, setShowLogoView] = useState(false);
  const [showTemplateView, setShowTemplateView] = useState(false);
  const [showOpenView, setShowOpenView] = useState(false);
  const [showCloseView, setShowCloseView] = useState(false);
  const [showDocsView, setShowDocsView] = useState(false);
  const [showCtaView, setShowCtaView] = useState(false);
  const [showDefaultTextView, setShowDefaultTextView] = useState(false);

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const toggle = (view) => {
    setShowTemplateView(!showTemplateView);
    switch (view) {
      case "logo":
        setShowLogoView(!showLogoView);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "template":
        setShowTemplateView(!showTemplateView);
        setShowLogoView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "open":
        setShowOpenView(!showOpenView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "close":
        setShowCloseView(!showCloseView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "docs":
        setShowDocsView(!showDocsView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowCtaView(false);
        setShowDefaultTextView(false);
        break;
      case "cta":
        setShowCtaView(!showCtaView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowDefaultTextView(false);
        break;
      case "text":
        setShowDefaultTextView(!showDefaultTextView);
        setShowLogoView(false);
        setShowTemplateView(false);
        setShowOpenView(false);
        setShowCloseView(false);
        setShowDocsView(false);
        setShowCtaView(false);
        break;
      default:
        break;
    }
  }

  function handleContinue() {
    createVideo(() => next(9, 10));
  }

  return (
    <>
      <Row className="g-0 justify-content-center">
        <WizardStep step={3} />
        <Col className="col-sm-12 col-md-12 col-12 offset-md-0">
          <Header title={t("VideoNew.Step9.title")} subtitle={t("VideoNew.Step9.subtitle")} />
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "1" },
                  (activeTab === "1") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                {t("VideoNew.Step9.default_text.tab")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "2" },
                  (activeTab === "2") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                {t("VideoNew.Step9.cta.tab")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "3" },
                  (activeTab === "3") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                {t("VideoNew.Step9.doc.tab")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames(
                  "text-decoration-none border-bottom-none",
                  { active: activeTab === "4" },
                  (activeTab === "4") ? "text-white bg-color-primary" : "text-black",
                )}
                onClick={() => {
                  toggleTab("4");
                }}
              >
                {t("VideoNew.Step9.landing.tab")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="text-muted">
            <TabPane tabId="1">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step9.default_text.text")}</p>
                      <DefaultText
                        defaultText={defaultText}
                        setDefaultText={setDefaultText}
                        showView={showDefaultTextView}
                        toggle={toggle} />
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step9.cta.text")}</p>
                      <Cta
                        cta={cta}
                        setCta={setCta}
                        showView={showCtaView}
                        toggle={toggle}
                        reuse={reuse}/>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <p className="text-center text-muted mt-3" style={{ fontStyle: "italic" }}>{t("VideoNew.Step9.doc.text")}</p>
                      <Docs
                        docs={docs}
                        setDocs={setDocs}
                        showView={showDocsView}
                        toggle={toggle} />
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row className="g-0 justify-content-center">
                <Col className="col-sm-12 col-12 col-md-12 offset-md-0">
                  <Card className="card-container shadow-none">
                    <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                      <div className="mt-3 mb-3">
                        {
                          t("VideoNew.Step9.landing.text").split("\n").map((item) => (
                            <p className="text-center text-muted mb-0" style={{ fontStyle: "italic" }}>{item}</p>
                          ))
                        }
                      </div>
                      <Landing 
                        landingLogo={landingLogo}
                        setLandingLogo={setLandingLogo}
                        backgroundImage={backgroundImage}
                        setBackgroundImage={setBackgroundImage}
                        titleColor={titleColor}
                        setTitleColor={setTitleColor}
                        secondaryColor={secondaryColor}
                        setSecondaryColor={setSecondaryColor}/>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => back(9)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
          </div>
        </Col>
      </Row>
    </>


    // <Row className="g-0 justify-content-center">
    //   <Col className="col-sm-12 col-lg-1 col-12 text-center align-self-center p-sm-4">
    //     <button type="button" onClick={() => back(8)} className="btn btn-shadow bg-color-white position-relative p-0 avatar-xs rounded-circle">
    //       <span className="avatar-title bg-transparent text-reset">
    //         <i className="bx bx-chevron-left" style={{ fontSize: 24 }}></i>
    //       </span>
    //     </button>
    //   </Col>
    //   <Col className="col-sm-12 col-12 col-md-10 offset-md-0">
    //     <WizardStep step={3} />
    //     <Header title={t("VideoNew.Step8.title")} />
    //     <Card className="card-container shadow-none">
    //       <div className="mt-4 text-center">
    //         <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-primary" color="app-green">
    //           <Spinner
    //             style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
    //             as="span"
    //             variant="light"
    //             size="sm"
    //             role="status"
    //             aria-hidden="true"
    //             animation="border" />
    //           {t("VideoNew.Step8.new")}
    //         </Button>
    //       </div>

    //       <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0 mt-5">
    //         <Col className="col-12 col-sm-12 col-md-11 bg-color-gray d-flex p-1" >
    //           <h4 className="lh-1 text-black text-center p-2 mb-0">{t("VideoNew.Step8.introduction")}</h4>
    //         </Col>
    //         <Logo 
    //           logo={logo} 
    //           setLogo={setLogo} 
    //           showView={showLogoView} 
    //           toggle={toggle} />
    //         <Template 
    //           template={template} 
    //           setTemplate={setTemplate} 
    //           originalTemplates={originalTemplates} 
    //           setOriginalTemplates={setOriginalTemplates} 
    //           showView={showTemplateView} 
    //           toggle={toggle} />
    //       </Row>

    //       <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0 mt-5">
    //         <Col className="col-12 col-sm-12 col-md-11 bg-color-gray d-flex p-1" >
    //           <h4 className="lh-1 text-black text-center p-2 mb-0">{t("VideoNew.Step8.composition")}</h4>
    //         </Col>
    //         <Open 
    //           open={open} 
    //           setOpen={setOpen} 
    //           originalOpens={originalOpens} 
    //           setOriginalOpens={setOriginalOpens} 
    //           opens={opens} setOpens={setOpens} 
    //           showView={showOpenView} 
    //           toggle={toggle} 
    //           openDynamic={openDynamic} 
    //           setOpenDynamic={setOpenDynamic} 
    //           setOpenImageStage={setOpenImageStage}
    //           setOpenVideoScreen={setOpenVideoScreen} />
    //         <Close 
    //           close={close} 
    //           setClose={setClose} 
    //           originalCloses={originalCloses} 
    //           setOriginalCloses={setOriginalCloses} 
    //           closes={closes} setCloses={setCloses} 
    //           showView={showCloseView} 
    //           toggle={toggle} 
    //           closeDynamic={closeDynamic} 
    //           setCloseDynamic={setCloseDynamic} 
    //           setCloseImageStage={setCloseImageStage}
    //           setCloseVideoScreen={setCloseVideoScreen} />
    //       </Row>

    //       <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0 mt-5">
    //         <Col className="col-12 col-sm-12 col-md-11 bg-color-gray d-flex p-1" >
    //           <h4 className="lh-1 text-black text-center p-2 mb-0">{t("VideoNew.Step8.send")}</h4>
    //         </Col>
    //         <Docs 
    //           docs={docs} 
    //           setDocs={setDocs} 
    //           showView={showDocsView} 
    //           toggle={toggle} />
    //         <Cta 
    //           cta={cta} 
    //           setCta={setCta} 
    //           showView={showCtaView} 
    //           toggle={toggle}
    //           reuse={reuse}/>
    //         <DefaultText 
    //           defaultText={defaultText} 
    //           setDefaultText={setDefaultText} 
    //           showView={showDefaultTextView} 
    //           toggle={toggle} />
    //       </Row>

    //       <div className="mt-4 mb-4 text-center">
    //         <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-primary" color="app-green">
    //           <Spinner
    //             style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
    //             as="span"
    //             variant="light"
    //             size="sm"
    //             role="status"
    //             aria-hidden="true"
    //             animation="border" />
    //           {t("VideoNew.Step8.new")}
    //         </Button>
    //       </div>
    //     </Card>
    //   </Col>
    //   <Col className="col-sm-12 col-lg-1 col-12"></Col>
    // </Row>
  );
}

export default Step9;
