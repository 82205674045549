import React, { useState } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import Switch from "react-switch";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import { OnSymbol, Offsymbol } from 'components/views/Utils/SwitchStatus';
import Header from 'components/views/Utils/Header';
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { STATISTICS } from 'routes/app/paths';
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";
// services
import { CompaniesList } from 'store/company/CompanyService';
import { UserGet, UserUpdate, UserDelete } from 'store/user/UserService';

const UserDetail = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { user_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isOn, setIsOn] = useState(true);
  
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [clients, setClient] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [videos, setVideos] = useState(null);

  useQuery(['COMPANIES', { token: user.access_token }], CompaniesList, {
    enabled: user.role == 'Admin',
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.company.error.load_all"));
    },
    onSuccess: (data) => {
      const newCompanies = data.data.data.map((e, i) => {
        return {
          label: e.name,
          value: e.id
        }
      });
      setCompanies(newCompanies);
    }
  });

  const queryUserGet = useQuery(['USER_GET', { token: user.access_token, id: user_id }], UserGet, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.user.error.load"));
    },
    onSuccess: (data) => {
      setSelectedCompany({ label: data.data.data.company.name, value: data.data.data.company.id });
      setSelectedRole({ label: data.data.data.role, value: data.data.data.role});
      setUser(data.data.data);

      setVideos(data.data.data.videos);
      setOriginalList(data.data.data.videos);
    }
  });

  const mutationUserUpdate = useMutation('UPDATE_USER', UserUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.user.error.update"));
    },
    onSuccess: (data) => {
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.user.success.update"));
    }
  });

  const mutationUserDelete = useMutation('DELETE_USER', UserDelete, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.user.error.delete"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.user.success.delete"));
      history.goBack();
    }
  });

  function handleSelectCompany(data) {
    setSelectedCompany(data);
  }

  function handleSelectRole(data) {
    setSelectedRole(data);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    const data = {
      "id": users.id,
      "name": `${values.name}`,
      "last_name": `${values.lastname}`, 
      "second_last_name": `${values.secondLastName}`,
      "email": values.email,
      "phone": values.phone,
      "department": values.department,
      "company_id": user.role == 'Admin' ? selectedCompany.value : users.company_id,
      "role": user.role == 'Admin' ? selectedRole.value : users.role
    }
    setLoading(true);
    mutationUserUpdate.mutate({ token: user.access_token, data });
  }

  function handleUpdateStatus(u) {
    setUser(u);
    mutationUserUpdate.mutate({ token: user.access_token, data: u });
  }

  function handleDelete(id) {
    mutationUserDelete.mutate({ token: user.access_token, id });
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("UserDetail.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <AvForm className="form-horizontal p-2" onSubmit={handleSubmit.bind(this)}>
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header title={t("UserDetail.title")} />
              <Row className="g-0 justify-content-center m-0">
                <Col className="col-12 col-sm-12 col-md-1 text-center d-flex justify-content-center align-items-center">
                  {
                    users &&
                    <Switch
                      width={45}
                      height={20}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-2"
                      onColor="#4ab28c"
                      onChange={() => {
                        handleUpdateStatus({ ...users, "status": !users.status })
                      }}
                      checked={users.status}
                    />
                  }
                  <div className="mt-1">
                    <i className="mdi mdi-trash-can-outline mt-1" onClick={() => { handleDelete(users?.id) }} style={{ fontSize: 24, cursor: "pointer" }}></i>
                  </div>
                </Col>
              </Row>
              <Card className="card-container shadow-none">
                <Row className="g-0 mt-5 mb-5">
                  <Col lg={12}>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            value={users?.name}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.surname")}
                            type="text"
                            value={users?.last_name}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastName"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            value={users?.second_last_name}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.second_surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            value={users?.email}
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            pattern="^\d{9}$"
                            value={users?.phone}
                            errorMessage={t("Validation.not_phone")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="department"
                            className="form-control"
                            placeholder={t("Placeholder.department")}
                            type="text"
                            value={users?.department}
                          // validate={{
                          //   required: { value: true, errorMessage: t("Validation.company") },
                          // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          {
                            user.role == 'Admin' &&
                            (companies &&
                              <Select
                                value={selectedCompany}
                                placeholder={t("Placeholder.select_company")}
                                onChange={(data) => {
                                  handleSelectCompany(data)
                                }}
                                options={companies}
                                classNamePrefix="select2-selection"
                              />
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          {
                            user.role == 'Admin' ?
                              <Select
                                value={selectedRole}
                                placeholder={t("Placeholder.select_role")}
                                onChange={(data) => {
                                  handleSelectRole(data)
                                }}
                                options={[{ label: "Admin", value: "Admin" }, { label: "Manager", value: "Manager" }, { label: "Agente", value: "Agente" }]}
                                classNamePrefix="select2-selection"
                              />
                              :
                              <Select
                                value={selectedRole}
                                placeholder={t("Placeholder.select_role")}
                                onChange={(data) => {
                                  handleSelectRole(data)
                                }}
                                options={[{ label: "Manager", value: "Manager" }, { label: "Agente", value: "Agente" }]}
                                classNamePrefix="select2-selection"
                              />
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <h3 className="MontSemiBold lh-1 text-black text-center pt-5 mb-4">{t("UserDetail.send")}</h3>
              <ToolBar originalList={originalList} list={videos} setList={setVideos} setInputRef={setInputSearchRef} />
              <Card className="card-container shadow-none mt-4" style={{ maxHeight: 450, minHeight: 400, overflowY: 'scroll' }}>
                <Row className="g-0 mb-5 justify-content-center col-md-10 offset-md-1">
                  {
                    videos &&
                    (videos.length > 0 &&
                      videos.map((v) => (
                        <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                          <VideoCard name={v.title} date={v.created_at} srcVideo={`${COMAPANY}${v.user.company_id}${COMAPANY_OUTPUTS}${v.video}`} />
                          <div className="mb-3 mt-2 text-center">
                            <Link
                              to={`${STATISTICS}/${v.id}`}
                              className="btn-general bg-color-darkgray text-decoration-none text-white"
                              color="app-green">
                              {t("UserDetail.statistics")}
                            </Link>
                          </div>
                        </Col>
                      ))
                    )
                  }
                </Row>
              </Card>
            </Col>
          </Row>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
              <Spinner
                style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border" />
              {t("UserDetail.save")}
            </Button>
          </div>
        </AvForm>
      </Container>
    </div>
  );
}

export default UserDetail;
