import {
  companyList,
  companyGet,
  companyAdd,
  companyUpdate,
  companyDelete
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description Log in
 * @param {*} access_token
 * @returns user
 */
export const CompaniesList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(companyList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get company by id
 * @param {*} access_token
 * @param {*} id
 * @returns 
 */
export const CompanyGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${companyGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add company
 * @param {*} company
 * @param {*} token
 * @returns company
 */
export const CompanyAdd = async (params) => {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: headers(params.token),
  //   body: JSON.stringify(params.company),
  // }
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.company,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(companyAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update company
 * @param {*} company
 * @param {*} token
 * @returns company
 */
export const CompanyUpdate = async (params) => {
  // const requestOptions = {
  //   method: 'PUT',
  //   headers: headers(params.token),
  //   body: JSON.stringify(params.data),
  // }
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.data,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  console.log(requestOptions);
  return fetch(companyUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete company
 * @param {*} company
 * @param {*} token
 * @returns company
 */
export const CompanyDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      id: params.id
    }),
  }
  return fetch(companyDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}