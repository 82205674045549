import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Badge, Button } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

// components
import Table from "components/views/Utils/Table";
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ModalDeleteWithText from "components/views/Utils/ModalDeleteWithText";
// routes
import { CLIENT_NEW, CLIENT_DETAIL } from 'routes/app/paths';
// services
import { ClientsList, ClientDelete } from 'store/client/ClientService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Clients = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [clients, setClients] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState(null);

  const queryClientList = useQuery(['CLIENTS', { token: user.access_token }], ClientsList, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.client.error.load_all"));
    },
    onSuccess: (data) => {
      setClients(data.data.data);
    }
  });

  const mutationClientDelete = useMutation('DELETE_CLIENT', ClientDelete, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.client.error.delete"));
    },
    onSuccess: (data) => {
      useToast("success", t("Message.info"), t("Message.client.success.delete"));
      queryClient.refetchQueries(['CLIENTS', { token: user.access_token }]);
      setShowModalDelete(!showModalDelete);
    }
  });

  function toggleModalDelete() {
    setShowModalDelete(!showModalDelete);
    document.body.classList.add("no_padding");
  }

  function handleDelete(id) {
    // mutationClientDelete.mutate({ token: user.access_token, id });
    setDataDelete(id);
    toggleModalDelete();
  }

  const columns = [
    {
      dataField: 'name',
      text: t("Client.table.name"),
      sort: true,
      formatter: (cell, row) => (
        <div>{`${row.name} ${row.last_name === null ? "" : row.last_name}`}</div>
      )
    },
    {
      dataField: 'client_video',
      text: t("Client.table.video"),
      sort: true,
      formatter: (cell, row) => (
        <Badge
          className={classnames(
            "font-size-12 text-center",
            (cell.length >= 1) ? "badge-soft-success" : "badge-soft-danger",
          )}
          color={(cell.length >= 1) ? "green" : "danger"}
          pill
        >
          {cell.length}
        </Badge>
      )
    }, {
      dataField: 'id',
      text: t("Client.table.profile"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <Link 
            to={`${CLIENT_DETAIL}/${cell}`}
            className="btn-general-black bg-color-gray text-decoration-none text-black" 
            color="app-green">
            {t("Client.profile")}
          </Link>
        );
      },
    }, {
      dataField: 'id',
      text: t("Client.table.delete"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <i
            key={`delete-${row.id}`}
            onClick={() => handleDelete(cell)}
            className="mdi mdi-trash-can-outline"
            style={{ fontSize: 24, cursor: "pointer" }} />
        );
      },
    }
  ];

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Client.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Client.title")} />
            <div className="text-center">
              <Button onClick={() => history.push(CLIENT_NEW)} className="btn-shadow btn-general bg-color-primary" color="app-green">{t("Client.new")}</Button>
            </div>
            {/* {
              queryClientList.isLoading &&
              <Loading style="table" />
            } */}
            {
              clients ?
                <Table data={clients} columns={columns} />
              :
                <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}></Card>
            }
          </Col>
        </Row>
        <ModalDeleteWithText
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          dataDelete={dataDelete}
          mutation={mutationClientDelete}
          model={t("Client.the_client")}
        />
      </Container>
    </div>
  );
}

export default Clients;
