import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';
import Select from "react-select";

// components
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ModalLoading from "components/views/Utils/ModalLoading";
// services
import { ClipAdd, ClipCategoriesUpdate } from 'store/clip/ClipService';
import { CategoryListByProduct } from 'store/category/CategoryService';
import { ProductsList } from "store/product/ProductService";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const ClipNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useAuthContext();
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formRef, setFormRef] = useState(false);
  const { getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
    maxFiles: 1
  });
  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const queryProductsList = useQuery(['PRODUCTS', { token: user.access_token }], ProductsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_all"));
    },
    onSuccess: (data) => {
      const newProducts = data.data.data.map((e, i) => {
        return {
          label: e.name,
          value: e.id
        }
      });
      setProducts(newProducts);
    }
  });

  useEffect(() => {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      setImage(image);
    }
  }, [acceptedFiles]);

  const mutationClipAdd = useMutation('CREATE_CLIP', ClipAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.clip.error.add"));
    },
    onSuccess: (data) => {
      if (selectedCategory != null) {
        const clip = data.data.data;
        const info = {
          category_id: [selectedCategory.value],
          clip_id: clip.id
        }
        mutationClipCategoriesUpdate.mutate({ token: user.access_token, info });
      } else {
        setLoading(false);
        setVideo(null);
        setImage(null);
        formRef.reset();
        setSelectedProduct(null);
        setSelectedCategory(null);
        useToast("success", t("Message.info"), t("Message.clip.success.add"));
      }
    }
  });

  const mutationClipCategoriesUpdate = useMutation('UPDATE_CLIP_CATEGORY', ClipCategoriesUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.clip.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      setVideo(null);
      setImage(null);
      formRef.reset();
      setSelectedProduct(null);
      setSelectedCategory(null);
      useToast("success", t("Message.info"), t("Message.clip.success.add"));
    }
  });

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_video"));
      return;
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setVideo(files[0]);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (video == null) {
      useToast("error", t("Message.alert"), t("Message.clip.error.load_video"));
      return;
    }

    // if (image == null) {
    //   useToast("error", t("Message.alert"), t("Message.clip.error.load_image"));
    //   return;
    // }

    setLoading(true);

    const data = new FormData();
    data.append("clip", video);
    data.append("thumbnail", image);
    data.append("title", values.name);
    data.append("description", "");

    if (document.getElementById('video_player').videoHeight > document.getElementById('video_player').videoWidth) {
      data.append("portrait", 1);
    } else {
      data.append("portrait", 0);
    }
    
    mutationClipAdd.mutate({ token: user.access_token, clip: data });
  }

  async function handleSelectProduct(selectedProduct) {
    setSelectedProduct(selectedProduct);
    const params = {
      token: user.access_token,
      id: selectedProduct.value
    }
    const data = await queryClient.fetchQuery([{}, params], CategoryListByProduct);
    if (data.status === 200) {
      const newCategories = data.data.data.map((e, i) => {
        return {
          label: e.name,
          value: e.id
        }
      });
      setCategories(newCategories);
    }
  }

  async function handleSelectCategory(selectedCategory) {
    setSelectedCategory(selectedCategory);
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("ClipNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("ClipNew.title")} />
            <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
              <Card className="card-container shadow-none">
                <Row className="g-0 justify-content-evenly mt-4">
                  <Col className="col-12 col-sm-12 col-md-4">
                    {
                      video ?
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-2" id="outer" style={{ padding: 0 }}>
                          <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                            <i
                              onClick={() => setVideo(null)}
                              className="mdi mdi-close circle-video"
                              style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                          </div>
                          <video id="video_player" src={video.preview} className="video" controls width="100%" height="300px"></video>
                        </div>
                      :
                        <Dropzone
                          maxFiles={1}
                          accept="video/mp4"
                          onDrop={(acceptedFiles, fileRejections) => {
                            handleAcceptedFiles(acceptedFiles, fileRejections)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>{t("Message.drop.drag_video")}</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                    }
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-4">
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.name")}</h5>
                      <CardBody>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-4">
                              <AvField
                                name="name"
                                className="form-control"
                                placeholder={t("Placeholder.name")}
                                type="text"
                                validate={{
                                  required: { value: true, errorMessage: t("Validation.name") },
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.categories")}</h5>
                      <CardBody>
                        <Row>
                          {
                            queryProductsList.isLoading &&
                            <Loading style="list" />
                          }
                          <Row className="g-0 justify-content-center mb-2">
                            <Col className="col-12 col-sm-12 col-md-12 ps-1 mb-1">
                              <Card className="shadow-none mb-0 bg-transparent">
                                <Select
                                  value={selectedProduct}
                                  placeholder={t("Placeholder.products")}
                                  onChange={(e) => {
                                    handleSelectProduct(e)
                                  }}
                                  options={products}
                                  classNamePrefix="select2-selection"
                                />
                              </Card>
                            </Col>
                            <Col className="col-12  col-sm-12 col-md-12 ps-1">
                              <Card className="shadow-none mb-0 bg-transparent">
                                <Select
                                  value={selectedCategory}
                                  placeholder={t("Placeholder.categories")}
                                  onChange={(e) => {
                                    handleSelectCategory(e)
                                  }}
                                  options={categories}
                                  classNamePrefix="select2-selection"
                                />
                              </Card>
                            </Col>
                          </Row>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                      <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.image")}</h5>
                      <CardBody>
                        {
                          image ?
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2" style={{ padding: 0 }}>
                              <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                                <i
                                  onClick={() => setImage(null)}
                                  className="mdi mdi-close circle-video"
                                  style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                              </div>
                              <img
                                style={{ width: '100%' }}
                                className="bg-light"
                                alt={image.name}
                                src={image.preview}
                              />
                            </div>
                            :
                            <Row className="justify-content-center">
                              <Col className="col-12 col-sm-12 col-md-8 border-dashed border-bottom border-top m-3 bg-color-white d-flex p-5 justify-content-center align-items-center">
                                <div className="text-center">
                                  <input {...getInputProps()} />
                                  <Button onClick={() => open()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("PresentationNew.select")}</Button>
                                </div>
                              </Col>
                            </Row>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("ClipNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
          <ModalLoading
            title={t("Message.video.loading.title")}
            subtitle={t("Message.video.loading.subtitle")}
            showModal={loading}
          />
        </Row>
      </Container>
    </div>
  );
}

export default ClipNew;
