import React from 'react';
import { Card, CardBody } from "reactstrap";
import Moment from 'react-moment';

const ImageCard = ({ name, date, srcImage }) => {
  return (
    <Card className="mb-0 border-top shadow-none">
      <div className="bg-color-gray p-3 border-top border-bottom-green-1" style={{ height: 90 }}>
        <p className="MontSemiBold mb-0">{name}</p>
        <Moment date={date} format="DD MMM, YYYY" />
      </div>
      <CardBody>
        <div className="col-lg-12 col-md-12 col-sm-12" id="outer" style={{ padding: 0 }}>
          <img
            src={srcImage}
            alt=""
            className="video"
            width="100%"
            height="100px"
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default ImageCard;
