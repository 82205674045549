import toastr from "toastr";

export function useToast(
  toastType,
  title,
  message, 
  closeButton = true,
  debug = false,
  progressBar = false,
  preventDuplicates = false,
  newestOnTop = true,
  position = "toast-top-right",
  showEasing = "swing",
  hideEasing = "linear",
  showMethod = "fadeIn",
  hideMethod = "fadeOut",
  showDuration = 300,
  hideDuration = 1000,
  timeOut = 5000,
  extendedTimeOut = 1000,
) {

  toastr.options = {
    positionClass: position,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: closeButton,
    debug: debug,
    progressBar: progressBar,
    preventDuplicates: preventDuplicates,
    newestOnTop: newestOnTop,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration
  }

  if (toastType === "info") toastr.info(message, title)
  else if (toastType === "warning") toastr.warning(message, title)
  else if (toastType === "error") toastr.error(message, title)
  else toastr.success(message, title)
}

export function useClearToasts() {
  toastr.clear();
}

