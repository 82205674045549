import React, { useState } from 'react';
import { Row, Col, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import EditVideo from 'components/views/Utils/Edition/EditVideo';
import ModalPresentationNew from "components/views/Presentation/ModalPresentationNew";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// routes
import { COMAPANY, COMAPANY_VIDEOS } from "routes/server/paths";
// hooks
import useAuthContext from 'hooks/useAuthContext';

const Close = ({ 
  close,
  setClose,
  originalCloses,
  closes,
  setCloses,
  showView,
  toggle,
  closeDynamic,
  setCloseDynamic,
  setCloseImageStage,
  setCloseVideoScreen
  /*close, setClose, originalCloses, closes, setCloses, closeDelete, setCloseDelete, showModal, setShowModal, toggleModal*/ }) => {
  const [video, setVideo] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const [showModalPresentation, setShowModalPresentation] = useState(false);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [showModalEditVideo, setShowModalEditVideo] = useState(false);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);

  function toggleModalPresentation() {
    setShowModalPresentation(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleCheckboxChange(video, editing = false) {
    const index = originalCloses.findIndex(e => e.id == video.id);
    const newCloses = [...originalCloses];
    const selectedVideo = originalCloses[index];
    if (editing) {
      if (close == null) {
        setClose(video);
        newCloses[index].checked = true;
        return;
      } else if (close.id == selectedVideo.id) {
        return;
      } else if (close.id != selectedVideo.id) {
        const oldIndex = originalCloses.findIndex(e => e.id == close.id);
        newCloses[oldIndex].checked = !newCloses[oldIndex].checked;
        setClose(video);
        newCloses[index].checked = true;
        return;
      }
    } 
    if (close == null) {
      setClose(video);
    } else if (close.id == selectedVideo.id) {
      setClose(null);
    } else {
      const oldIndex = originalCloses.findIndex(e => e.id == close.id);
      newCloses[oldIndex].checked = !newCloses[oldIndex].checked;
      setClose(video);
    }
    newCloses[index].checked = !newCloses[index].checked;
    // setCloses(newCloses);
    // inputSearchRef.value = "";
  }

  function toggleModalEditVideo() {
    setShowModalEditVideo(!showModalEditVideo);
    document.body.classList.add("no_padding");
  }

  function handleEditVideo(video, url) {
    setVideoSelected(video);
    setVideo({
      id: video.id,
      name: video.title,
      url
    });
    toggleModalEditVideo();
  }

  return (
    <>
    <AvForm>
      <Row>
        <Col className="col-sm-12 col-md-12 offset-md-0">
          <div className="mt-1 mb-5 text-center">
            {
              // user.role != 'Agente' &&
                <Button onClick={() => setShowModalPresentation(!showModalPresentation)} className="btn-shadow btn-general bg-color-primary" color="app-green">{t("VideoNew.Step8.close.add")}</Button>
            }      
          </div>
          {
            closes &&
            <ToolBar originalList={originalCloses} list={closes} setList={setCloses} setInputRef={setInputSearchRef} />
          }
          <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
            {
              closes &&
              closes.map((e, i) => (
                <Col key={i} className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                  <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`} thumbnail={(e.thumbnail === null || e.thumbnail === 'null') ? null : `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.thumbnail}`} />
                  <Row className="mb-3 justify-content-between">
                    <Col className="col-md-5 text-center">
                      <i className="mdi mdi-movie-edit-outline" onClick={() => handleEditVideo(e, `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                    </Col>
                    <Col className="col-md-5 text-center align-self-center">
                      <div className="d-flex justify-content-center">
                        <input
                          type="checkbox"
                          style={{ height: 25, width: 25, float: "right" }}
                          className="form-clip-check"
                          name="check"
                          onClick={(event) => handleCheckboxChange(e)}
                          checked={e.checked} />
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))
            }
          </Row>
        </Col>
      </Row>
    </AvForm>
    <ModalPresentationNew
      type={"End"}
      showModal={showModalPresentation}
      setShowModal={setShowModalPresentation}
      toggleModal={toggleModalPresentation} />
    <EditVideo
      video={video}
      showModal={showModalEditVideo}
      setShowModal={setShowModalEditVideo}
      toggleModal={toggleModalEditVideo}
      dynamicText={true}
      imagesP={images}
      setImagesP={setImages}
      textsP={texts}
      setTextsP={setTexts}
      dynamicP={closeDynamic}
      setDynamicP={setCloseDynamic}
      setImageStage={setCloseImageStage}
      setVideoScreen={setCloseVideoScreen}
      videoSelected={videoSelected}
      handleCheckboxChange={handleCheckboxChange} />
    </>
  );
}

export default Close;
