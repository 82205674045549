import React, { useState } from 'react';
import { Row, Col, Collapse, Label } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import Dropzone from "react-dropzone";

// components
import ModalLandingPreview from 'components/views/Utils/ModalLandingPreview';
// hooks
import useFormatBytes from "hooks/useFormatBytes";
import useAuthContext from 'hooks/useAuthContext';

const Landing = ({
  landingLogo,
  setLandingLogo,
  backgroundImage,
  setBackgroundImage,
  titleColor,
  setTitleColor,
  secondaryColor,
  setSecondaryColor,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bgHeight, setBgHeight] = useState(500);

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
    setcol4(false)
  }

  const t_col4 = () => {
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
  }

  const handleAcceptedFiles = (acceptedFiles, fileRejections, uploadType) => {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      if (uploadType === 'logo') {
        setLandingLogo(image);
      } else {
        setBackgroundImage(image);
      }
    }
  }

  return (
    <Row className="g-0 justify-content-center mt-4">
      <Col className="col-12 col-sm-12 col-md-6">
        <div className="accordion" id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  "MontSemiBold",
                  "text-black",
                  { collapsed: !col1 }
                )}
                type="button"
                onClick={t_col1}
                style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
              >
                {t("VideoNew.Step9.landing.logo.title")}
              </button>
            </h2>
            <Collapse isOpen={col1} className="accordion-collapse">
              <div className="accordion-body">
                <Row className="g-0">
                  <Col className="col-12 col-sm-12 col-md-6">
                    <div className="d-flex justify-content-center" style={{ height: 230 }}>
                      {
                        landingLogo ?
                          <>
                            <div style={{ position: "absolute", top: 40, marginLeft: 100, zIndex: 0 }}>
                              <i
                                onClick={() => setLandingLogo(null)}
                                className="mdi mdi-close circle-video"
                                style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0 }}>
                              <img
                                id="logo"
                                style={{ width: '100px', height: '100px' }}
                                className="bg-light"
                                alt={landingLogo.name}
                                src={landingLogo.preview}
                              />
                            </div>
                          </>
                          :
                          <>
                            <Dropzone
                              maxFiles={1}
                              accept="image/jpeg, image/png"
                              onDrop={(acceptedFiles, fileRejections) => {
                                handleAcceptedFiles(acceptedFiles, fileRejections, "logo");
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ width: '100%' }}>
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>{t("Company.drag_logo")}</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </>
                      }
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-6 p-5">
                    <p className='mx-2 MontSemiBold'>{t("VideoNew.Step9.landing.logo.format.title")}</p>
                    <p className='my-0 mx-4'>{t("VideoNew.Step9.landing.logo.format.format")}</p>
                    <p className='my-0 mx-4'>{t("VideoNew.Step9.landing.logo.format.proportions")}</p>
                  </Col>
                </Row>
                
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  "MontSemiBold",
                  "text-black",
                  { collapsed: !col2 }
                )}
                type="button"
                onClick={t_col2}
                style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
              >
                {t("VideoNew.Step9.landing.background.title")}
              </button>
            </h2>

            <Collapse isOpen={col2} className="accordion-collapse">
              <div className="accordion-body">
                <Row className="g-0">
                  <Col className="col-12 col-sm-12 col-md-6">
                    <div className="d-flex justify-content-center" style={{ height: 230 }}>
                      {
                        backgroundImage ?
                          <>
                            <div style={{ position: "absolute", top: 40, right: -10, zIndex: 0 }}>
                              <i
                                onClick={() => setBackgroundImage(null)}
                                className="mdi mdi-close circle-video"
                                style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2 align-self-center text-center" style={{ padding: 0, width: '100%' }}>
                              <img
                                id="bg-image"
                                style={{ width: '100%', height: '100px' }}
                                className="bg-light"
                                alt={backgroundImage.name}
                                src={backgroundImage.preview}
                              />
                            </div>
                          </>
                          :
                          <>
                            <Dropzone
                              maxFiles={1}
                              accept="image/jpeg, image/png"
                              onDrop={(acceptedFiles, fileRejections) => {
                                handleAcceptedFiles(acceptedFiles, fileRejections, "background");
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone" style={{ width: '100%' }}>
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h4>{t("Company.drag_background")}</h4>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </>
                      }
                    </div>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-6 p-5">
                    <p className='mx-2 MontSemiBold'>{t("VideoNew.Step9.landing.background.format.title")}</p>
                    <p className='my-0 mx-4'>{t("VideoNew.Step9.landing.background.format.format")}</p>
                    <p className='my-0 mx-4'>{t("VideoNew.Step9.landing.background.format.proportions")}</p>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  "MontSemiBold",
                  "text-black",
                  { collapsed: !col3 }
                )}
                type="button"
                onClick={t_col3}
                style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
              >
                {t("VideoNew.Step9.landing.title_color")}
              </button>
            </h2>
            <Collapse isOpen={col3} className="accordion-collapse">
              <div className="accordion-body">
                <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.title_color_warning")}</h6>
                <div className="m-4 p-3 border-radius-1" style={{ backgroundColor: titleColor + "3D" }}>
                  <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                    <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                      <input
                        type="color"
                        name="title_color"
                        defaultValue={titleColor}
                        value={titleColor}
                        className="p-0"
                        style={{ height: 34, width: 34 }}
                        onChange={(event) => setTitleColor(event.target.value)}
                      />
                    </Col>
                    <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                      <p className='m-0' style={{ color: titleColor }}>{t("VideoNew.Step9.landing.title_color")}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  "MontSemiBold",
                  "text-black",
                  { collapsed: !col4 }
                )}
                type="button"
                onClick={t_col4}
                style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
              >
                {t("VideoNew.Step9.landing.secondary_color")}
              </button>
            </h2>
            <Collapse isOpen={col4} className="accordion-collapse">
              <div className="accordion-body">
                <h6 className="MontSemiBold lh-1 text-muted text-center">{t("Company.secondary_color_warning")}</h6>
                <div className="m-4 p-3 border-radius-1" style={{ backgroundColor: secondaryColor + "3D" }}>
                  <Row className="g-0 col-md-12 offset-md-0 justify-content-center">
                    <Col className="col-2 col-sm-2 col-md-2 p-1 align-self-center">
                      <input
                        type="color"
                        name="title_color"
                        defaultValue={secondaryColor}
                        value={secondaryColor}
                        className="p-0"
                        style={{ height: 34, width: 34 }}
                        onChange={(event) => setSecondaryColor(event.target.value)}
                      />
                    </Col>
                    <Col className="col-10 col-sm-10 col-md-7 p-1 align-self-center text-center-sm" >
                      <p className='m-0' style={{ color: secondaryColor }}>{t("VideoNew.Step9.landing.secondary_color")}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </Col>
      {/* <div className="text-center">
        <button className='border-none bg-color-transparent text-decoration-underline text-red' onClick={() => {
          setBgHeight(backgroundImage != null ? document.getElementById("bg-image").naturalHeight : 500);
          setShowModal(true)
        }}>{t("Company.preview")}</button>
      </div> */}
      <ModalLandingPreview
        showModal={showModal}
        setShowModal={setShowModal}
        titleColor={titleColor}
        secondaryColor={secondaryColor}
        logo={landingLogo?.preview}
        backgroundImage={backgroundImage?.preview}
        bgHeight={bgHeight} />
    </Row>
  );
}

export default Landing;
