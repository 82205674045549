import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Step1 from "components/views/Video/components/Introduction/Step1";
import Step2 from "components/views/Video/components/Introduction/Step2";
import Step3 from "components/views/Video/components/Introduction/Step3";
import Step4 from "components/views/Video/components/Introduction/Step4";
import Step5 from "components/views/Video/components/Create/Step5";
import Step6 from "components/views/Video/components/Create/Step6";
import Step7 from "components/views/Video/components/Create/Step7";
import Step8 from "components/views/Video/components/Setting/Step8";
import Step9 from "components/views/Video/components/Setting/Step9";
import Step10 from "components/views/Video/components/Send/Step10";
import Step11 from "components/views/Video/components/Send/Step11";
import Step12 from "components/views/Video/components/Send/Step12";
import ModalLoading from "components/views/Utils/ModalLoading";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { VideoAdd } from 'store/video/VideoService';
// routes
import { videoAdd, videoAddMultiple } from 'routes/api/paths';

const VideoNew = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [reuse, setReuse] = useState((props.location && props.location.state) || null);
  const [video, setVideo] = useState(null);

  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  const [step4, setStep4] = useState(false);
  const [step5, setStep5] = useState(false);
  const [step6, setStep6] = useState(false);
  const [step7, setStep7] = useState(false);
  const [step8, setStep8] = useState(false);
  const [step9, setStep9] = useState(false);
  const [step10, setStep10] = useState(false);
  const [step11, setStep11] = useState(false);
  const [step12, setStep12] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [previousStep, setPreviousStep] = useState([]);

  // step2
  const [client, setClient] = useState(null);
  // step3
  const [people, setPeople] = useState(null);
  // step5
  const [clips, setClips] = useState(null);
  const [originalClips, setOriginalClips] = useState(null);
  const [selectedClips, setSelectedClips] = useState(null);
  // step 7
  const [message, setMessage] = useState(null);
  // step 8 y 9
  const [logo, setLogo] = useState(null);
  const [template, setTemplate] = useState(null);
  const [open, setOpen] = useState(null);
  const [openDynamic, setOpenDynamic] = useState(null);
  const [openImageStage, setOpenImageStage] = useState(null);
  const [openVideoScreen, setOpenVideoScreen] = useState(null);
  const [close, setClose] = useState(null);
  const [closeDynamic, setCloseDynamic] = useState(null);
  const [closeImageStage, setCloseImageStage] = useState(null);
  const [closeVideoScreen, setCloseVideoScreen] = useState(null);
  const [docs, setDocs] = useState([]);
  const [cta, setCta] = useState([]);
  const [defaultText, setDefaultText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(new FormData());
  const [landingLogo, setLandingLogo] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [titleColor, setTitleColor] = useState(user.company?.first_color ? user.company?.first_color : '#c55654');
  const [secondaryColor, setSecondaryColor] = useState(user.company?.second_color ? user.company?.second_color : '#c55654');
  // step 10
  const [name, setName] = useState(null);

  const mutationVideoCreate = useMutation('CREATE_VIDEO', VideoAdd);

  function handleNextStep(currentStep, nextStep) {
    const modifiedPreviousStep = [...previousStep];
    modifiedPreviousStep.push(currentStep);
    setPreviousStep(modifiedPreviousStep);
    setCurrentStep(nextStep);
    switch (currentStep) {
      case 1:
        setStep1(!step1);
        break;
      case 2:
        setPeople(null);
        setStep2(!step2);
        break;
      case 3:
        setClient(null);
        setStep3(!step3);
        break;
      case 4:
        setStep4(!step4);
        break;
      case 5:
        setStep5(!step5);
      case 6:
        setStep6(!step6);
        break;
      case 7:
        setStep7(!step7);
      case 8:
        setStep8(!step8);
        break;
      case 9:
        setStep9(!step9);
        break;
      case 10:
        setStep10(!step10);
        break;
      case 11:
        setStep11(!step11);
        break;
      case 12:
        setStep12(!step12);
        break;
      default:
        break;
    }
    switch (nextStep) {
      case 1:
        setStep1(!step1);
        break;
      case 2:
        setStep2(!step2);
        break;
      case 3:
        setStep3(!step3);
        break;
      case 4:
        if (reuse === null) {
          setStep4(!step4);
        } else {
          setStep5(!step5);
        }
        break;
      case 5:
        setStep5(!step5);
        break;
      case 6:
        setStep6(!step6);
        break;
      case 7:
        setStep7(!step7);
        break;
      case 8:
        setStep8(!step8);
        break;
      case 9:
        setStep9(!step9);
        break;
      case 10:
        setStep10(!step10);
        break;
      case 11:
        setStep11(!step11);
        break;
      case 12:
        setStep12(!step12);
        break;
      default:
        break;
    }
  }

  function handleBackStep(currentStep) {
    const modifiedPreviousStep = [...previousStep];
    const lastStepIndex = modifiedPreviousStep.length - 1;
    const lastStep = modifiedPreviousStep[lastStepIndex];
    modifiedPreviousStep.splice(lastStepIndex, 1);
    setPreviousStep(modifiedPreviousStep);
    switch (currentStep) {
      case 1:
        setStep1(!step1);
        break;
      case 2:
        setStep2(!step2);
        break;
      case 3:
        setStep3(!step3);
        break;
      case 4:
        setStep4(!step4);
        break;
      case 5:
        setStep5(!step5);
        break;
      case 6:
        setStep6(!step6);
        break;
      case 7:
        setStep7(!step7);
        break;
      case 8:
        setStep8(!step8);
        break;
      case 9:
        setStep9(!step9);
        break;
      case 10:
        setStep10(!step10);
        break;
      case 11:
        setStep11(!step11);
        break;
      case 12:
        setStep12(!step12);
        break;
      default:
        break;
    }
    switch (lastStep) {
      case 1:
        setStep1(!step1);
        break;
      case 2:
        setStep2(!step2);
        break;
      case 3:
        setStep3(!step3);
        break;
      case 4:
        setStep4(!step4);
        break;
      case 5:
        setStep5(!step5);
        break;
      case 6:
        setStep6(!step6);
        break;
      case 7:
        setStep7(!step7);
        break;
      case 8:
        setStep8(!step8);
        break;
      case 9:
        setStep9(!step9);
        break;
      case 10:
        setStep10(!step10);
        break;
      case 11:
        setStep11(!step11);
        break;
      case 12:
        setStep12(!step12);
        break;
      default:
        break;
    }
    setCurrentStep(lastStep);
  }

  function createVideo(callback) {
    setLoading(true);
    // console.log({
    //   client,
    //   people,
    //   selectedClips,
    //   message,
    //   logo,
    //   "template_id": template.id,
    //   open,
    //   openDynamic,
    //   openImageStage,
    //   openVideoScreen,
    //   close,
    //   closeDynamic,
    //   closeImageStage,
    //   closeVideoScreen,
    //   docs,
    //   cta,
    //   defaultText,
    //   name
    // });
    const data = new FormData();
    client != null && data.append("client", JSON.stringify(client));
    people != null && data.append("excel", people[0]);
    selectedClips != null && data.append("selectedClips", JSON.stringify(selectedClips));
    message != null && data.append("description", message);
    logo != null && data.append("logo", logo[0]);
    template != null && data.append("template_id", template.id);
    open != null && data.append("open", JSON.stringify(open));
    openDynamic != null && data.append("openDynamic", JSON.stringify(openDynamic));
    openImageStage != null && data.append("openImage", openImageStage);
    openVideoScreen != null && data.append("openVideoScreen", JSON.stringify(openVideoScreen));
    close != null && data.append("close", JSON.stringify(close));
    closeDynamic != null && data.append("closeDynamic", JSON.stringify(closeDynamic));
    closeImageStage != null && data.append("closeImage", closeImageStage);
    closeVideoScreen != null && data.append("closeVideoScreen", JSON.stringify(closeVideoScreen));
    if (docs.length > 0) {
      docs.map((e, i) => {
        return data.append(`docs_${i}`, e);
      })
      data.append("docs_length", docs.length);
    }
    cta.length > 0 && data.append("cta", JSON.stringify(cta));
    defaultText != null && data.append("default_text", defaultText);
    name != null && data.append("title", name);
    landingLogo != null && data.append("landing_logo", landingLogo);
    backgroundImage != null && data.append("background_img", backgroundImage);
    data.append("first_color", titleColor);
    data.append("second_color", secondaryColor);
    setInfo(data);

    mutationVideoCreate.mutate({ token: user.access_token, data: data, url: videoAdd }, {
      onError: (error) => {
        setLoading(false);
        console.log("Status", error);
        useToast("error", t("Message.error"), t("Message.video.error.add"));
        
      },
      onSuccess: (data) => {
        setVideo(data.data.data);
        setTimeout(() => {
          useToast("success", t("Message.info"), t("Message.video.success.add"));
          setLoading(false);
          callback();
        }, 2000);
      }
    });
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>{t("VideoNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content mt-5" >
        {step1 ? (
          <Step1 
            back={handleBackStep} 
            next={handleNextStep} />
        ) : null}

        {step2 ? (
          <Step2 
            back={handleBackStep} 
            next={handleNextStep} 
            client={client}
            setClient={setClient}
          />
        ) : null}

        {step3 ? (
          <Step3 
            back={handleBackStep} 
            next={handleNextStep} 
            people={people} 
            setPeople={setPeople} />
        ) : null}

        {step4 ? (
          <Step4 
            back={handleBackStep} 
            next={handleNextStep}
            setClips={setClips}
            setSelectedClips={setSelectedClips}
            setCta={setCta}
            setReuse={setReuse} />
        ) : null}

        {step5 ? (
          <Step5 
            back={handleBackStep} 
            next={handleNextStep} 
            clips={clips} 
            setClips={setClips}
            originalClips={originalClips}
            setOriginalClips={setOriginalClips}
            selectedClips={selectedClips}
            setSelectedClips={setSelectedClips}
            reuse={reuse} />
        ) : null}

        {step6 ? (
          <Step6 
            back={handleBackStep} 
            next={handleNextStep}
            clips={clips}
            setClips={setClips}
            selectedClips={selectedClips}
            setSelectedClips={setSelectedClips}
            originalClips={originalClips}
            setOriginalClips={setOriginalClips} />
        ) : null}

        {step7 ? (
          <Step7 
            back={handleBackStep} 
            next={handleNextStep} 
            message={message} 
            setMessage={setMessage} />
        ) : null}

        {step8 ? (
          <Step8 
            back={handleBackStep} 
            next={handleNextStep}
            logo={logo}
            setLogo={setLogo}
            template={template}
            setTemplate={setTemplate}
            open={open}
            setOpen={setOpen}
            openDynamic={openDynamic}
            setOpenDynamic={setOpenDynamic}
            setOpenImageStage={setOpenImageStage}
            setOpenVideoScreen={setOpenVideoScreen}
            close={close}
            setClose={setClose}
            closeDynamic={closeDynamic}
            setCloseDynamic={setCloseDynamic}
            setCloseImageStage={setCloseImageStage}
            setCloseVideoScreen={setCloseVideoScreen}
            docs={docs}
            setDocs={setDocs}
            cta={cta}
            setCta={setCta}
            defaultText={defaultText}
            setDefaultText={setDefaultText}
            loading={loading}
            createVideo={createVideo}
            reuse={reuse} />
        ) : null}

        {step9 ? (
          <Step9
            back={handleBackStep}
            next={handleNextStep}
            logo={logo}
            setLogo={setLogo}
            template={template}
            setTemplate={setTemplate}
            open={open}
            setOpen={setOpen}
            openDynamic={openDynamic}
            setOpenDynamic={setOpenDynamic}
            setOpenImageStage={setOpenImageStage}
            setOpenVideoScreen={setOpenVideoScreen}
            close={close}
            setClose={setClose}
            closeDynamic={closeDynamic}
            setCloseDynamic={setCloseDynamic}
            setCloseImageStage={setCloseImageStage}
            setCloseVideoScreen={setCloseVideoScreen}
            docs={docs}
            setDocs={setDocs}
            cta={cta}
            setCta={setCta}
            defaultText={defaultText}
            setDefaultText={setDefaultText}
            landingLogo={landingLogo}
            setLandingLogo={setLandingLogo}
            backgroundImage={backgroundImage}
            setBackgroundImage={setBackgroundImage}
            titleColor={titleColor}
            setTitleColor={setTitleColor}
            secondaryColor={secondaryColor}
            setSecondaryColor={setSecondaryColor}
            loading={loading}
            createVideo={createVideo}
            reuse={reuse} />
        ) : null}

        {step10 ? (
          <Step10 
            back={handleBackStep} 
            next={handleNextStep}
            video={video}
            setVideo={setVideo}
            name={name} 
            setName={setName}
            people={people}
            info={info} />
        ) : null}

        {step11 ? (
          <Step11 
            back={handleBackStep} 
            next={handleNextStep}
            video={video} />
        ) : null}

        {step12 ? (
          <Step12 />
        ) : null}

        <ModalLoading
          title={t("VideoNew.Step8.ModalCreatingVideo.title")}
          subtitle={t("VideoNew.Step8.ModalCreatingVideo.subtitle")}
          showModal={loading}
        />

      </Container>
    </React.Fragment>
  )
}

export default VideoNew;
