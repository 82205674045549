import React from "react";
import { useHistory, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

//SimpleBar
import SimpleBar from "simplebar-react";
// scss
import "components/layout/CommonForBoth/rightbar.scss";
// routes
import { 
  DASHBOARD,
  PROFILE, 
  LOGOUT, 
  TUTORIAL,
  VIDEOS,
  VIDEO_NEW,
  PRESENTATIONS,
  CLIENTS,
  STATISTICS,
  CLIPS,
  USERS,
  USERS_ADMIN,
  CATEGORIES,
  TEMPLATES,
  COMPANIES,
  PRODUCTS
} from 'routes/app/paths';
// images
import home from "assets/images/app/menu/home.svg";
import tutorial from "assets/images/app/menu/tutorial.svg";
import profile from "assets/images/app/menu/profile.svg";
import logout from "assets/images/app/menu/logout.svg";
// hooks
import useAuthContext from 'hooks/useAuthContext';

const RightSidebar = props => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const onCloseRightBar = () => {
    const { onClose } = props
    if (onClose) {
      onClose()
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ height: "100%", overflowY: "auto" }}>
        <div data-simplebar className="h-100">
          <div className="rightbar-title px-3 py-4">
            <Link
              to="#"
              onClick={e => {
                onCloseRightBar();
              }}
              className="float-end"
              style={{marginTop: "-10px"}}
            >
              <i className="mdi mdi-close noti-icon font-size-24 text-black" />
            </Link>
            <h5 className="m-0 MontBold">{t("Menu.title")}</h5>
          </div>

          <hr className="my-0" />
          
          <div style={{maxHeight: 900, overflowY: 'scroll'}}>
            <div className="p-4">
              <Button
                onClick={() => window.location.href = VIDEO_NEW}
                className="btn-shadow btn-general bg-color-primary"
                color="app-green">
                {t("Menu.new")}
              </Button>
            </div>
            <div className="ps-4 pt-3">
              <Link to={DASHBOARD} className="text-decoration-none">
                <img src={home} alt="" width="18" height="18" />
                <h5 className="MontSemiBold text-black d-inline ms-2">{t("Menu.home")}</h5>
              </Link>
            </div>
            {
              user.role == 'Admin' && 
              <div className="ps-4 pt-3">
                <h5 className="MontSemiBold text-black">{t("Menu.admins_opt")}</h5>
                <div className="ps-4">
                  <Link to={COMPANIES} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.companies")}</Link>
                    <Link to={USERS_ADMIN} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.users")}</Link>
                  {/* <Link to={PRODUCTS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">Productos</Link> */}
                </div>
              </div>
            }
            <div className="ps-4 pt-3">
              <h5 className="MontSemiBold text-black">{t("Menu.users_opt")}</h5>
              <div className="ps-4">
                <Link to={VIDEOS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.videos")}</Link>
                <Link to={PRESENTATIONS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.presentations")}</Link>
                <Link to={CLIENTS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.clients")}</Link>
                <Link to={STATISTICS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.statistics")}</Link>
              </div>
            </div>
            <div className="ps-4 pt-3">
              <h5 to="/" className="MontSemiBold text-black">{t("Menu.features_opt")}</h5>
              <div className="ps-4">
                <Link to={CLIPS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.clips")}</Link>
                {
                  user.role != 'Agente' &&
                  <>
                    <Link to={USERS} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.my_users")}</Link>
                    <Link to={CATEGORIES} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.categories")}</Link>
                  </>
                }
                <Link to={TEMPLATES} className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.templates")}</Link>
                <Link to="/" className="mb-2 ms-2 me-2 text-black text-decoration-none d-block">{t("Menu.team")}</Link>
              </div>
            </div>
            <div className="ps-4 pt-3">
              <Link to={TUTORIAL} className="text-decoration-none">
                <img src={tutorial} alt="" width="18" height="18" />
                <h5 className="MontSemiBold text-black d-inline ms-2">{t("Menu.tips")}</h5>
              </Link>
            </div>
            <div className="ps-4 pt-3">
              <Link to={PROFILE} className="text-decoration-none">
                <img src={profile} alt="" width="18" height="18" />
                <h5 className="MontSemiBold text-black d-inline ms-2">{t("Menu.profile")}</h5>
              </Link>
            </div>
            <div className="ps-4 pt-3">
              <Link to={LOGOUT} className="text-decoration-none">
                <img src={logout} alt="" width="18" height="18" />
                <h5 className="MontSemiBold text-black d-inline ms-2">{t("Menu.logout")}</h5>
              </Link>
            </div>
            
          </div>  
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  onClose: PropTypes.func,
}


export default RightSidebar;