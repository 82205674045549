import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


//actions
import {
  changeLayout,
  changeTopbarTheme,
  changeLayoutWidth,
} from "store/layout/actions";

// components
import Header from "components/layout/NonAuthLayout/Header";
import Footer from "components/layout/HorizontalLayout/Footer"


const NonAuthLayout = (props) => {
  const dispatch = useDispatch();

  const {
    topbarTheme, layoutWidth, isPreloader, showRightSidebar
  } = useSelector(state => ({
    topbarTheme: state.Layout.topbarTheme,
    layoutWidth: state.Layout.layoutWidth,
    isPreloader: state.Layout.isPreloader,
    showRightSidebar: state.Layout.showRightSidebar,
  }))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  /*
  layout settings
  */
  useEffect(() => {
    dispatch(changeLayout("horizontal"));
  }, [dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [dispatch, topbarTheme]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [dispatch, layoutWidth]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  }

  return (
    <React.Fragment>
      <Header
        theme={"light"}
        isMenuOpened={false}
        openLeftMenuCallBack={false}
      />
      <div className="" style={{ minHeight: "100vh" }}>{props.children}</div>
      <Footer />
    </React.Fragment>
  );
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
}

// export default NonAuthLayout
export default withRouter(NonAuthLayout)
