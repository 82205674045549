import PropTypes from 'prop-types';
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// routes
import { authProtectedRoutes, publicRoutes } from "routes/app/routes";
import { LANDING_TOKEN, LANDING_PREVIEW } from 'routes/app/paths';
// middleware
import Authmiddleware from "routes/app/Authmiddleware";
// layouts
// import VerticalLayout from "components/layout/VerticalLayout/";
import HorizontalLayout from "components/layout/HorizontalLayout/";
import NonAuthLayout from "components/layout/NonAuthLayout/NonAuthLayout";
import LandingLayout from "components/layout/LandingLayout/LandingLayout";
// scss
import "assets/scss/theme.scss";
import "toastr/build/toastr.min.css";
// contexts
import AuthContextProvider from "contexts/authContext";
// 404
import Page404 from "components/views/Page404";

const queryClient = new QueryClient();

const App = props => {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Suspense fallback={true}>
            <Router>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={(route.path === LANDING_TOKEN || route.path === LANDING_PREVIEW) ? LandingLayout : NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={HorizontalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}

                <Route component={Page404} />
              </Switch>
            </Router>
          </Suspense>
        </AuthContextProvider>
      </QueryClientProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App;
