import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse, Button, Input } from "reactstrap";
import classnames from "classnames";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// hooks
import { useRandomId } from "hooks/useRamdonId";
import useFormatBytes from "hooks/useFormatBytes";

const defaultColor = "#c55654";

const ToolBar = ({
  texts,
  setTexts,
  images,
  setImages,
  dynamic,
  setDynamic,
  dynamicText
}) => {
  const { t } = useTranslation();
  const [viewImages, setViewImages] = useState(false);
  const [viewTexts, setViewText] = useState(false);
  const [viewDynamicText, setViewDynamicText] = useState(false);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/jpeg, image/png'
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const id = useRandomId("image");
      const newImages = [...images];
      const file = acceptedFiles[0];
      newImages.push(
        {
          id,
          x: 10,
          y: 10,
          width: 100,
          height: 100,
          rotation: 0,
          file: Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: useFormatBytes(file.size),
          })
        }
      );
      console.log(newImages);
      setImages(newImages);
    }
  }, [acceptedFiles]);

  const handleViewImages = () => {
    setViewImages(!viewImages);
    setViewText(false);
  }

  const handleViewTexts = () => {
    setViewText(!viewTexts);
    setViewImages(false);
  }

  const handleViewDynamicText = () => {
    setViewDynamicText(!viewDynamicText);
    setViewText(false);
    setViewImages(false);
  }

  function handleAddText() {
    const id = useRandomId("text");
    const newTexts = [...texts];
    newTexts.push(
      {
        id,
        text: t("Edition.ToolBar.text"),
        x: 10,
        y: 10,
        fontSize: 20,
        color: defaultColor,
        rotation: 0
      }
    );
    setTexts(newTexts);
  }

  function handleAddDynamicText() {
    const id = useRandomId("text");
    setDynamic({
      id,
      text: t("Edition.ToolBar.dynamic"),
      x: 10,
      y: 10,
      fontSize: 20,
      color: defaultColor,
      rotation: 0
    });
  }

  function handleChangeText(event, index) {
    const newTexts = [...texts];
    newTexts[index].text = event.target.value;
    setTexts(newTexts);
  }

  function handleChangeColor(event, index) {
    const newTexts = [...texts];
    newTexts[index].color = event.target.value;
    setTexts(newTexts);
  }

  function handleChangeColorDynamic(event) {
    const newDynamic = { ...dynamic };
    newDynamic.color = event.target.value;
    setDynamic(newDynamic);
  }

  function handleDeleteText(index) {
    const newTexts = [...texts];
    setTexts(newTexts.filter((f, i) => i !== index));
  }

  function handleDeleteImage(index) {
    const newImages = [...images];
    setImages(newImages.filter((f, i) => i !== index));
  }

  return (
    document.getElementById('player') &&
    <div className="accordion" id="accordion" style={{ width: "100%", height: document.getElementById('player').clientHeight + 26, overflow: "scroll" }}>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              "MontSemiBold",
              "text-black",
              { collapsed: !viewTexts }
            )}
            type="button"
            onClick={handleViewTexts}
            style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
          >
            {t("Edition.ToolBar.texts")}
          </button>
        </h2>
        <Collapse isOpen={viewTexts} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-center">
              <Button onClick={handleAddText} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("Edition.ToolBar.add_text")}</Button>
            </div>
            {texts.map((text, index) => {
              return (
                <Row key={index} className="g-0 col-md-12 offset-md-0 mt-3 justify-content-center">
                  <Col className="col-2 col-sm-12 col-md-2 p-1 align-self-center">
                    <Input
                      type="color"
                      defaultValue={defaultColor}
                      value={text.color}
                      className="p-0"
                      style={{ height: 34, width: 34 }}
                      onChange={(event) => handleChangeColor(event, index)}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-7 p-1 align-self-center text-center-sm" >
                    <Input type="text" value={text.text} onChange={(event) => handleChangeText(event, index)} />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-3 p-1 align-self-center text-center-sm">
                    <Button id={index} key={index} onClick={() => handleDeleteText(index)} className="btn-shadow btn-delete text-decoration-none border-red-1 text-red" color="app-green">{t("Edition.ToolBar.delete")}</Button>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              "MontSemiBold",
              "text-black",
              { collapsed: !viewImages }
            )}
            type="button"
            onClick={handleViewImages}
            style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
          >
            {t("Edition.ToolBar.images")}
          </button>
        </h2>
        <Collapse isOpen={viewImages} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-center">
              <input {...getInputProps()} />
              <Button className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" onClick={() => open()}>{t("Edition.ToolBar.add_image")}</Button>
            </div>
            {images.map((image, index) => {
              return (
                <Row key={index} className="g-0 col-md-12 offset-md-0 mt-3 justify-content-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      alt={image.file.name}
                      src={image.file.preview}
                    />
                  </Col>
                  <Col className="align-self-center">
                    {image.file.name}
                    <p className="mb-0">
                      <strong>{image.file.formattedSize}</strong>
                    </p>
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-3 p-1 align-self-center text-center-sm">
                    <Button id={index} key={index} onClick={() => handleDeleteImage(index)} className="btn-shadow btn-delete text-decoration-none border-red-1 text-red" color="app-green">{t("Edition.ToolBar.delete")}</Button>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Collapse>
      </div>
      {
        dynamicText &&
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames(
                "accordion-button",
                "fw-medium",
                "MontSemiBold",
                "text-black",
                { collapsed: !viewDynamicText }
              )}
              type="button"
              onClick={handleViewDynamicText}
              style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
            >
              {t("Edition.ToolBar.dynamics")}
            </button>
          </h2>
          <Collapse isOpen={viewDynamicText} className="accordion-collapse">
            <div className="accordion-body">
              <div className="text-center">
                <input {...getInputProps()} />
                <Button className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" onClick={() => handleAddDynamicText()}>{t("Edition.ToolBar.add_dynamic")}</Button>
              </div>
              {
                dynamic &&
                <Row key={dynamic.id} className="g-0 col-md-12 offset-md-0 mt-3 justify-content-center">
                  <Col className="col-2 col-sm-12 col-md-2 p-1 align-self-center">
                    <Input
                      type="color"
                      defaultValue={defaultColor}
                      value={dynamic.color}
                      className="p-0"
                      style={{ height: 34, width: 34 }}
                      onChange={(event) => handleChangeColorDynamic(event)}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-7 p-1 align-self-center text-center-sm" >
                    <Input type="text" value={dynamic.text} readOnly />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-3 p-1 align-self-center text-center-sm">
                    <Button onClick={() => setDynamic(null)} className="btn-shadow btn-delete text-decoration-none border-red-1 text-red" color="app-green">{t("Edition.ToolBar.delete")}</Button>
                  </Col>
                </Row>
              }
            </div>
          </Collapse>
        </div>
      }
    </div>
  );
}

export default ToolBar;
