import React from 'react';
import classnames from "classnames";
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

const WizardStep = ({ step }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile */}
      <Row className="wizard mt-5 d-lg-none">
        <div className="timeline">
          <div className={classnames("tleft", (step == 1) ? "tcontainer" : "tcontainer-gray")}>
            <p className={classnames("m-0", (step == 1) ? "text-red" : "text-wizard-step-gray")} style={{ height: "39px" }}>{t("VideoNew.WizardStep.step1")}</p>
          </div>
          <br />
          <div className={classnames("tright", (step == 2) ? "tcontainer" : "tcontainer-gray")}>
            <p className={classnames("m-0", (step == 2) ? "text-red" : "text-wizard-step-gray")} style={{ height: "39px" }}>{t("VideoNew.WizardStep.step2")}</p>
          </div>
          <br />
          <div className={classnames("tleft", (step == 3) ? "tcontainer" : "tcontainer-gray")}>
            <p className={classnames("m-0", (step == 3) ? "text-red" : "text-wizard-step-gray")} style={{ height: "39px" }}>{t("VideoNew.WizardStep.step3")}</p>
          </div>
          <br />
          <div className={classnames("tright", (step == 4) ? "tcontainer" : "tcontainer-gray")}>
            <p className={classnames("m-0", (step == 4) ? "text-red" : "text-wizard-step-gray")} style={{ height: "39px" }}>{t("VideoNew.WizardStep.step4")}</p>
          </div>
        </div>
      </Row>

      {/* Desktop */}
      <Row className="wizard mt-5 d-lg-flex d-sm-none d-none">
        <Col className="col-sm-3 col-md-3 d-flex pe-0" style={{ height: 13 }}>
          <div className="" style={{ display: "inline-block" }}>
            <span className={classnames("number m-0 ", (step == 1) ? "bg-color-primary" : "bg-color-wizard-gray")}></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center d-flex p-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className={classnames("number m-0 ", (step == 2) ? "bg-color-primary" : "bg-color-wizard-gray")}></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center d-flex p-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className={classnames("number m-0", (step == 3) ? "bg-color-primary" : "bg-color-wizard-gray")}></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-end d-flex ps-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className={classnames("number m-0", (step == 4) ? "bg-color-primary" : "bg-color-wizard-gray")}></span>
          </div>
        </Col>
      </Row>
      <Row className="wizard d-lg-flex d-sm-none d-none">
        <Col className="col-sm-3 col-md-3">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className={classnames("m-0", (step == 1) ? "text-red" : "text-wizard-step-gray")}>{t("VideoNew.WizardStep.step1")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className={classnames("m-0", (step == 2) ? "text-red" : "text-wizard-step-gray")}>{t("VideoNew.WizardStep.step2")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className={classnames("m-0", (step == 3) ? "text-red" : "text-wizard-step-gray")}>{t("VideoNew.WizardStep.step3")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-end">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className={classnames("m-0", (step == 4) ? "text-red" : "text-wizard-step-gray")}>{t("VideoNew.WizardStep.step4")}</p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default WizardStep;
