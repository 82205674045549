import React, { useState } from 'react';
import { Collapse } from "reactstrap";
import classnames from "classnames";

const Create = () => {
  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(false)
  const [col3, setcol3] = useState(false)

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
  }

  return (
    <div className="accordion" id="accordion">
      <div className="accordion-item bg-color-green">
        <h4 className="MontSemiBold text-white text-center mt-2">Creación del vídeo</h4>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              "MontSemiBold",
              "text-black",
              { collapsed: !col1 }
            )}
            type="button"
            onClick={t_col1}
            style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
          >
            Consejos para crear un buen vídeo de presentación
          </button>
        </h2>
        <Collapse isOpen={col1} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
              <strong className="text-dark">
                This is the first item&apos;s accordion body.
              </strong>{" "}
              It is hidden by default, until the collapse plugin
              adds the appropriate classes that we use to style
              each element. These classes control the overall
              appearance, as well as the showing and hiding via
              CSS transitions. You can modify any of this with
              custom CSS or overriding our default variables.
              It&apos;s also worth noting that just about any HTML
              can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              "MontSemiBold",
              "text-black",
              { collapsed: !col2 }
            )}
            type="button"
            onClick={t_col2}
            style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
          >
            Accordion Item #2
          </button>
        </h2>

        <Collapse isOpen={col2} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
              <strong className="text-dark">
                This is the second item&apos;s accordion body.
              </strong>{" "}
              It is hidden by default, until the collapse plugin
              adds the appropriate classes that we use to style
              each element. These classes control the overall
              appearance, as well as the showing and hiding via
              CSS transitions. You can modify any of this with
              custom CSS or overriding our default variables.
              It&apos;s also worth noting that just about any HTML
              can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              "MontSemiBold",
              "text-black",
              { collapsed: !col3 }
            )}
            type="button"
            onClick={t_col3}
            style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
          >
            Accordion Item #3
          </button>
        </h2>
        <Collapse isOpen={col3} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
              <strong className="text-dark">
                This is the third item&apos;s accordion body.
              </strong>{" "}
              It is hidden by default, until the collapse plugin
              adds the appropriate classes that we use to style
              each element. These classes control the overall
              appearance, as well as the showing and hiding via
              CSS transitions. You can modify any of this with
              custom CSS or overriding our default variables.
              It&apos;s also worth noting that just about any HTML
              can go within the <code>.accordion-body</code>,
              though the transition does limit overflow.
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default Create;
