import React from 'react';
import { Row, Col, Card, Form, Button } from "reactstrap";
import Dropzone from "react-dropzone";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import WizardStep from "components/views/Video/components/WizardStep";
// images
import excel from "assets/images/app/video/excel.svg";
// hook
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";
// excel
import file from "assets/download/nuevos-clientes.xlsx";

const Step3 = ({ back, next, people, setPeople }) => {
  const { t } = useTranslation();

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setPeople(files);
  }

  function handleNext() {
    if (people == null) {
      useToast("error", "Alerta", "Cargue un archivo");
      return;
    }
    next(3, 4);
  }

  return (
    <Row className="g-0 justify-content-center">
      <WizardStep step={1} />
      <Col className="col-sm-12 col-md-8 col-12 offset-md-2">
        <Header title={t("VideoNew.Step3.title")} />
        <div className="mb-4 text-center">
          <Button onClick={() => location.href = file} className="btn-shadow btn-excel bg-color-primary" color="app-green">{t("VideoNew.Step3.download")}</Button>
        </div>
        <Form className="form-horizontal">
          <Card className="card-container shadow-none">
            <Row className="g-0 justify-content-center m-5">
              <Col className="col-sm-12 col-md-12 justify-content-center">
                <Row>
                  <Dropzone
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>{t("Message.drop.drag_excel")}</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </Row>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {
                  people &&
                  people.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <div style={{ position: "relative", top: -20, float: "right", left: 20 }}>
                            <i
                              onClick={() => setPeople(null)}
                              className="mdi mdi-close circle"
                              style={{ fontSize: 18, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                          </div>
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={excel}
                              />
                            </Col>
                            <Col>
                              {f.name}
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Card>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => back(3)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button onClick={handleNext} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
          </div>
        </Form>
      </Col>
      <Col className=""></Col>
    </Row>
  );
}

export default Step3;
