import React, { useState } from 'react';
import { useQuery } from "react-query";
import { Row, Col, Modal, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import ImageCard from 'components/views/Utils/ImageCard';
import EditImage from 'components/views/Utils/Edition/EditImage';
import Loading from "components/views/Utils/Loading";
import ModalTemplateNew from "components/views/Template/ModalTemplateNew";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// services
import { TemplatesList } from 'store/template/TemplateService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { COMAPANY, COMAPANY_TEMPLATES } from "routes/server/paths";

const Template = ({ 
  template, 
  setTemplate
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [image, setImage] = useState(null);
  const [templateSelected, setTemplateSelected] = useState(null);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [templates, setTemplates] = useState(null);
  const [originalTemplates, setOriginalTemplates] = useState(null);
  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [showModalEditImage, setShowModalEditImage] = useState(false);
  const [autoload, setAutoload] = useState(false);

  const queryTemplateList = useQuery(['TEMPLATES', { token: user.access_token }], TemplatesList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.template.error.load_all"));
    },
    onSuccess: (data) => {
      const temps = data.data.data;
      const newTemplates = temps.filter(t => t.status === 1).map(e => ({ ...e, checked: false }));
      if (template != null) {
        const index = newTemplates.findIndex(e => e.id == template.id);
        newTemplates[index].checked = !newTemplates[index].checked;
      }
      setOriginalTemplates(newTemplates);
      setTemplates(newTemplates);
      if (templateSelected) {
        handleCheckboxChange(newTemplates[newTemplates.length - 1], true, newTemplates);
        setTemplateSelected(null);
      }
    }
  });

  function handleCheckboxChange(temp, editing = false, lastTemplates = null) {
    debugger;
    if (editing) {
      const index = lastTemplates.findIndex(e => e.id == temp.id);
      const newTemplates = [...lastTemplates];
      const selectedVideo = newTemplates[index];
      if (template == null) {
        setTemplate(temp);
        newTemplates[index].checked = true;
        return;
      } else if (template.id == selectedVideo.id) {
        return;
      } else if (template.id != selectedVideo.id) {
        const oldIndex = originalTemplates.findIndex(e => e.id == template.id);
        newTemplates[oldIndex].checked = !newTemplates[oldIndex].checked;
        setTemplate(temp);
        newTemplates[index].checked = true;
        return;
      }
    }
    const index = originalTemplates.findIndex(e => e.id == temp.id);
    const newTemplates = [...originalTemplates];
    const selectedVideo = originalTemplates[index];
    if (template == null) {
      setTemplate(temp);
    } else if (template.id == selectedVideo.id) {
      setTemplate(null);
    } else {
      const oldIndex = originalTemplates.findIndex(e => e.id == template.id);
      newTemplates[oldIndex].checked = !newTemplates[oldIndex].checked;
      setTemplate(temp);
    }
    newTemplates[index].checked = !newTemplates[index].checked;
    // setTemplates(newTemplates);
    // inputSearchRef.value = "";
  }

  function handleEditImage(template, url, autoload) {
    setTemplateSelected(template);
    setAutoload(autoload);
    setImage({
      id: template.id,
      name: template.title,
      url
    });
    toggleModalEditImage();
  }

  function toggleModalTemplate() {
    setShowModalTemplate(!showModalTemplate);
    document.body.classList.add("no_padding");
  }

  function toggleModalEditImage() {
    setShowModalEditImage(!showModalEditImage);
    document.body.classList.add("no_padding");
  }

  return (
    <>
    <AvForm>
      <Row>
        <Col className="col-sm-12 col-md-12 offset-md-0">
          <div className="mt-1 mb-5 text-center">
            {
              user.role != 'Agente' &&
              <Button onClick={() => toggleModalTemplate(!showModalTemplate)} className="btn-shadow btn-general bg-color-primary" color="app-green">{t("VideoNew.Step8.cover.add")}</Button>
            }
          </div>
          {
            templates &&
            <ToolBar originalList={originalTemplates} list={templates} setList={setTemplates} setInputRef={setInputSearchRef} />
          }
          <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
            {
              queryTemplateList.isLoading &&
              <Loading style="video" />
            }
            {
              templates &&
              templates.map((e, i) => {
                return (
                  <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                    <ImageCard name={e.title} date={e.created_at} srcImage={`${COMAPANY}${e.company_id}${COMAPANY_TEMPLATES}${e.template}`} />
                    <Row className="mb-3 justify-content-between">
                      <Col className="col-md-5 text-center">
                        <i className="mdi mdi-image-edit-outline" onClick={() => handleEditImage(e, `${COMAPANY}${e.company_id}${COMAPANY_TEMPLATES}${e.template}`, false)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                      </Col>
                      <Col className="col-md-5 text-center align-self-center">
                        <div className="d-flex justify-content-center">
                          <input
                            type="checkbox"
                            style={{ height: 25, width: 25, float: "right" }}
                            className="form-clip-check"
                            name="check"
                            onClick={(event) => handleCheckboxChange(e)}
                            checked={e.checked} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )
              })
            }
          </Row>
        </Col>
      </Row>
    </AvForm>
    <ModalTemplateNew
      showModal={showModalTemplate}
      setShowModal={setShowModalTemplate}
      toggleModal={toggleModalTemplate} />
    <EditImage
      image={image}
      autoload={autoload}
      setTemplate={setTemplate}
      showModal={showModalEditImage}
      setShowModal={setShowModalEditImage}
      toggleModal={toggleModalEditImage}
      templateSelected={templateSelected}
      handleCheckboxChange={handleCheckboxChange} />
    </>
  );
}

export default Template;
