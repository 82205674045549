import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Badge } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import Header from 'components/views/Utils/Header';
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import Loading from "components/views/Utils/Loading";
import ModalDeleteWithText from "components/views/Utils/ModalDeleteWithText";
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { VideosList, VideoReuse, VideoToken, VideoDelete } from 'store/video/VideoService';
// routes
import { DOMAIN, COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";
import { VIDEO_NEW, BASE_URL, LANDING } from 'routes/app/paths';
// images
import person from "assets/images/app/video/person.svg";
import people from "assets/images/app/video/people.svg";

const Videos = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [videos, setVideos] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [videoDelete, setVideoDelete] = useState(null);

  const queryVideoList = useQuery(['VIDEOS', { token: user.access_token }], VideosList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.load_all"));
    },
    onSuccess: (data) => {
      setVideos(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  const mutationVideoDelete = useMutation('VIDEO_DELETE', VideoDelete, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.delete"));
      setShowModal(!showModal);
    },
    onSuccess: (data) => {
      if (inputSearchRef.value != "") {
        inputSearchRef.value = "";
      }
      useToast("success", t("Message.info"), t("Message.video.success.delete"));
      queryClient.refetchQueries(['VIDEOS', { token: user.access_token }]);
      setShowModal(!showModal);
    }
  });

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  async function handleReuse(id) {
    debugger;
    const params = {
      token: user.access_token,
      id
    }
    const fetchReuse = await queryClient.fetchQuery([params], VideoReuse);
    if (fetchReuse.status === 200) {
      console.log(fetchReuse.status);
      history.push(VIDEO_NEW, fetchReuse.data);
    }
  }

  function handleDelete(id) {
    setVideoDelete(id);
    toggleModal();
  }

  async function handleEmailLanding(id) {
    const params = {
      token: user.access_token,
      id
    }
    const fetchReuse = await queryClient.fetchQuery([params], VideoToken);
    if (fetchReuse.status === 200) {
      // console.log(fetchReuse.data);
      const data = fetchReuse.data.data[0];
      // history.push(VIDEO_NEW, fetchReuse.data);
      location.href = `mailto:${data.email}?subject=Asunto%20con%20espacios&body=${BASE_URL}${LANDING}/${data.token}`;
    }
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Video.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Video.title")} />
            <ToolBar originalList={originalList} list={videos} setList={setVideos} setInputRef={setInputSearchRef} />
            <Card className="card-container shadow-none mt-4" style={{ maxHeight: 450, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  queryVideoList.isLoading &&
                  <Loading style="video" />
                }
                {
                  videos &&
                  videos.map((e, i) => {
                    return (
                      <Col key={i} className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_OUTPUTS}${e.video}`} clients={e.clients} />
                        <Row className="mb-3 justify-content-between">
                          <Col className="col-md-5 text-center">
                            <Button className="btn-shadow btn-general-reuse bg-color-darkgray ms-3" color="app-green" onClick={() => { handleReuse(e.id) }}>{t("Video.reuse")}</Button>
                          </Col>
                          <Col className="col-md-5 text-center">
                            {
                              e.clients < 2 ?
                                <i className="mdi mdi-email" onClick={() => handleEmailLanding(e.id)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                              :
                                <i className="mdi mdi-download" onClick={() => { window.open(`${DOMAIN}excels/${e.build}.xlsx`, '_blank', 'noopener,noreferrer') }} style={{ fontSize: 24, cursor: "pointer" }}></i>
                            }
                            <i className="mdi mdi-trash-can-outline" onClick={() => { handleDelete(e.id) }} style={{ fontSize: 24, cursor: "pointer" }}></i>
                          </Col>
                        </Row>
                        {
                          e.clients &&
                          <div className="text-center mb-2">
                            <Badge
                              className="badge-soft-success"
                              pill
                            >
                              <div className="text-center d-flex ">
                                <img
                                  width="20"
                                  src={e.clients < 2 ? person : people}
                                  alt=""
                                  className="img-fluid mx-auto d-block"
                                />
                                <p className="mb-0 ms-1 pt-1" style={{ fontSize: 15, color: "black" }}>{e.clients}</p>
                              </div>
                            </Badge>
                          </div>
                        }
                      </Col>
                    );
                  })
                }
              </Row>
            </Card>
          </Col>
        </Row>
        <ModalDeleteWithText 
          showModal={showModal}
          setShowModal={setShowModal}
          dataDelete={videoDelete}
          mutation={mutationVideoDelete}
          model={t("Video.the_video")}
        />
      </Container>
    </div>
  );
}

export default Videos;
