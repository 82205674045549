import React, { useState, useEffect } from 'react';
import { Stage, Layer } from "react-konva";

// components
import VideoPlayer from "components/views/Utils/Edition/components/VideoPlayer";
import Images from "components/views/Utils/Edition/components/Images";
import Texts from "components/views/Utils/Edition/components/Texts";

const EditionView = ({ setStageRef, video = null, image = null, texts, setTexts, images, setImages, dynamic, setDynamic }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedShapeId, setSelectedShapeId] = useState(null);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [selectedShapeId]);

  function handleKeyDown(e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      if (selectedShapeId) {
        const image = images.filter((f, i) => f.id == selectedShapeId);
        const text = texts.filter((f, i) => f.id == selectedShapeId);
        if (image.length > 0) {
          const newImages = [...images];
          setImages(newImages.filter((f, i) => f.id !== image[0].id));
        } else if (text.length > 0) {
          const newTexts = [...texts];
          console.log(newTexts.filter((f, i) => f.id !== text[0].id));
          setTexts(newTexts.filter((f, i) => f.id !== text[0].id));
        }
      }
    }
  };

  function checkDeselect(e) {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedShapeId(null);
    }
  };

  return (
    <>
      {
        video &&
        <VideoPlayer video={video} />
      }
      {
        image &&
        <img
          id="player"
          src={image}
          alt=""
          className="video"
          // width="100%"
          // height="100%"
          style={{width: 585, height: 336}}
        />
      }
      {
        document.getElementById('player') &&
        <Stage
          ref={(ref) => setStageRef(ref)}
          style={{ backgroundColor: "transparent", position: "absolute" }}
          width={document.getElementById('player').clientWidth}
          height={document.getElementById('player').clientHeight}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}
          onMouseLeave={checkDeselect}
        >
          <Layer>
            {images.map((image, i) => {
              console.log(image.file.preview);
              return (
                <Images
                  key={i}
                  image={image.file.preview}
                  shapeProps={image}
                  isSelected={image.id === selectedShapeId}
                  onSelect={() => {
                    setSelectedShapeId(image.id);
                  }}
                  onChange={(newAttrs) => {
                    const imgs = images.slice();
                    imgs[i] = newAttrs;
                    setImages(imgs);
                  }}
                />
              );
            })}
            {texts.map((text, i) => {
              return (
                <Texts
                  key={i}
                  text={text.text}
                  shapeProps={text}
                  isSelected={text.id === selectedShapeId}
                  fill={(isDragging && text.id === selectedShapeId) ? "green" : text.color}
                  onDragStart={() => {
                    setIsDragging(true);
                    setSelectedShapeId(text.id)
                  }}
                  onDragEnd={(e) => {
                    setIsDragging(false);
                    setSelectedShapeId(null);
                    const txts = texts.slice();
                    txts[i] = {
                      ...text,
                      x: e.target.x(),
                      y: e.target.y(),
                      // width: e.target.textWidth,
                      // height: e.target.textHeight
                    };
                    setTexts(txts);
                  }}
                  onSelect={() => {
                    setSelectedShapeId(text.id);
                  }}
                  onChange={(newAttrs) => {
                    const txts = texts.slice();
                    txts[i] = newAttrs;
                    setTexts(txts);
                  }}
                />
              );
            })}
            {
              dynamic &&
              <Texts
                key={dynamic.id}
                text={dynamic.text}
                shapeProps={dynamic}
                rotate={false}
                isSelected={dynamic.id === selectedShapeId}
                fill={(isDragging && dynamic.id === selectedShapeId) ? "green" : dynamic.color}
                onDragStart={() => {
                  setIsDragging(true);
                  setSelectedShapeId(dynamic.id)
                }}
                onDragEnd={(e) => {
                  setIsDragging(false);
                  setSelectedShapeId(null);
                  setDynamic({
                    ...dynamic,
                    x: e.target.x(),
                    y: e.target.y(),
                    // width: e.target.textWidth,
                    // height: e.target.textHeight
                  });
                }}
                onSelect={() => {
                  setSelectedShapeId(dynamic.id);
                }}
                onChange={(newAttrs) => {
                  setDynamic(newAttrs);
                }}
              />
            }
          </Layer>
        </Stage>
      }
      </>
  );
}

export default EditionView;
