import {
  templateList,
  templateGet,
  templateAdd,
  templateUpdate,
  templateDelete,
  templateEdit
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description List templates
 * @param {*} access_token
 * @returns templates
 */
export const TemplatesList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(templateList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get template by id
 * @param {*} access_token
 * @param {*} id
 * @returns 
 */
export const TemplateGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${templateGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add template
 * @param {*} template
 * @param {*} token
 * @returns template
 */
export const TemplateAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.template,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(templateAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update template
 * @param {*} template
 * @param {*} token
 * @returns template
 */
export const TemplateUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.data),
  }
  return fetch(templateUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Edit template
 * @param {*} data
 * @param {*} token
 * @returns template
 */
export const TemplateEdit = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.data,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(templateEdit, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete template
 * @param {*} template
 * @param {*} token
 * @returns template
 */
export const TemplateDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      template_id: params.id
    }),
  }
  return fetch(templateDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}