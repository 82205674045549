import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Row, Col, Modal, Button, Input, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
// services
import { VideoAdd, VideoUpdate } from 'store/video/VideoService';
// hook
import { useToast } from "hooks/useToast";
import useAuthContext from 'hooks/useAuthContext';
// routes
import { videoAddMultiple } from 'routes/api/paths';
import { BASE_URL, LANDING } from 'routes/app/paths';

const ModalEditName = ({ video, showModal, setShowModal, mutation }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleChageName(event) {
    setName(event.target.value);
  }

  function handleContinue() {
    if (video) {
      const updateVideo = {
        ...video,
        "title": name
      }
      mutation.mutate({ token: user.access_token, data: updateVideo });
      setName("");
    }
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header
                modal={true}
                title={t("ModalEditName.title")} />
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <Input type="text" value={name} onChange={(event) => handleChageName(event)} />
                </Col>
              </Row>
              <div className="text-center">
                <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-green m-3" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("ModalEditName.save")} →
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalEditName;
