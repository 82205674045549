import React, { useState } from 'react';
import { useMutation } from "react-query";
import { Row, Col, Modal, Button, Input, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
// services
import { VideoAdd, VideoUpdate } from 'store/video/VideoService';
// hook
import { useToast } from "hooks/useToast";
import useAuthContext from 'hooks/useAuthContext';
// routes
import { videoAddMultiple } from 'routes/api/paths';
import { BASE_URL, LANDING } from 'routes/app/paths';

const ModalVideoName = ({ next, video, name, setName, showModal, setShowModal, toggleModal, people, info }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const mutationVideoCreate = useMutation('CREATE_VIDEO', VideoAdd);

  const mutationVideoUpdate = useMutation('UPDATE_VIDEO', VideoUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.video.error.update"));
    },
    onSuccess: (data) => {
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.video.success.update"));
      next(10, 11);
    }
  });

  function handleChageName(event) {
    setName(event.target.value);
  }

  function handleContinue() {
    if (name != null) {
      setLoading(true);
      debugger;
      if (people != null) {
        info.append("id", video?.video.id);
        info.append("title", name);
        info.append("url", `${BASE_URL}${LANDING}`);
        mutationVideoCreate.mutate({ token: user.access_token, data: info, url: videoAddMultiple }, {
          onError: (error) => {
            setLoading(false);
            console.log("Status", error);
            useToast("error", t("Message.error"), t("Message.video.error.add"));
          },
          onSuccess: (data) => {
            useToast("success", t("Message.info"), t("Message.video.success.add"));
            next(10, 12);
          }
        });
      } else {
        const updateVideo = {
          "id": video?.video.id,
          "title": name
        }
        mutationVideoUpdate.mutate({ token: user.access_token, data: updateVideo });
      }
    } else {
      useToast("error", t("Message.alert"), t("Message.video.error.name"));
    }
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header 
                modal={true}
                title={t("VideoNew.Step10.ModalVideoName.title")}
                subtitle={t("VideoNew.Step10.ModalVideoName.subtitle")} />
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <Input type="text" value={name} onChange={(event) => handleChageName(event)} />
                </Col>
              </Row>
              <div className="text-center">
                <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-green m-3" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("VideoNew.Step10.ModalVideoName.save")} →
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalVideoName;
