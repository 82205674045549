import React, { useState } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import Header from 'components/views/Utils/Header';
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// services
import { ClientGet, ClientUpdate } from 'store/client/ClientService';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { STATISTICS } from 'routes/app/paths';
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";

const ClientDetail = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { client } = useParams();
  const [loading, setLoading] = useState(false);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [clients, setClient] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [videos, setVideos] = useState(null);

  const queryClientGet = useQuery(['CLIENT_GET', { token: user.access_token, id: client }], ClientGet, {
    enabled: !!client,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.client.error.load"));
    },
    onSuccess: (data) => {
      setClient(data.data.data);
      setVideos(data.data.data.client_video);
      setOriginalList(data.data.data.client_video);
    }
  });

  const mutationClientUpdate = useMutation('UPDATE_CLIENT', ClientUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.client.error.update"));
    },
    onSuccess: (data) => {
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.client.success.update"));
    }
  });

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    setLoading(true);

    const client = {
      "id": clients.id,
      "name": values.name,
      "last_name": values.lastname,
      "second_last_name": values.secondLastName,
      "email": values.email,
      "phone": values.phone,
      "society": values.society
    }
    mutationClientUpdate.mutate({ token: user.access_token, client });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("ClientDetail.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("ClientDetail.title")} />
            <Card className="card-container shadow-none">
              <Row className="g-0 mt-5 mb-5">
                <Col lg={12}>
                  <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            value={clients?.name}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.lastname")}
                            type="text"
                            value={clients?.last_name}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.lastname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastName"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            value={clients?.second_last_name}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.second_surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            value={clients?.email}
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            pattern="^\d{9}$"
                            value={clients?.phone}
                            errorMessage={t("Validation.not_phone")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="society"
                            className="form-control"
                            placeholder={t("Placeholder.company")}
                            type="text"
                            value={clients?.society}
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.name") },
                            // }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-5 mb-2 text-center">
                      <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                        <Spinner
                          style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border" />
                        {t("ClientDetail.save")}
                      </Button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <h3 className="MontSemiBold lh-1 text-black text-center pt-5 mb-4">{t("ClientDetail.send")}</h3>
            <ToolBar originalList={originalList} list={videos} setList={setVideos} setInputRef={setInputSearchRef} inside={true} />
            <Card className="card-container shadow-none mt-4" style={{ maxHeight: 450, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-5 justify-content-center col-md-10 offset-md-1">
                {
                  videos &&
                  (videos.length > 0 &&
                    videos.map((v) => {
                      return (<Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={v.video.title} date={v.video.created_at} srcVideo={`${COMAPANY}${v.video.user.company_id}${COMAPANY_OUTPUTS}${v.video.video}`} />
                        <div className="mb-3 mt-2 text-center">
                          <Link
                            to={`${STATISTICS}/${v.video.id}`}
                            className="btn-general bg-color-darkgray text-decoration-none text-white"
                            color="app-green">
                            {t("ClientDetail.statistics")}
                          </Link>
                        </div>
                      </Col>)
                    })
                  )
                }
              </Row>
            </Card>
            <div className="mt-3 mb-4 text-center">
              <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">← {t("VideoNew.back")}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ClientDetail;
