import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Collapse, Label, Input } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

const DefaultText = ({ defaultText, setDefaultText, showView, toggle }) => {
  const { t } = useTranslation();

  function textAreaChange(event) {
    setDefaultText(event.target.value);
  }

  return (
    <Row className="g-0 justify-content-center">
      <Col className="col-12 col-sm-12 col-md-8">
        <Input
          type="textarea"
          id="textarea"
          className="border-black-1"
          onChange={e => { textAreaChange(e); }}
          maxLength="250"
          rows="8"
          placeholder={t("Placeholder.default_text")}
        />
      </Col>
    </Row>
  );
}

export default DefaultText;
