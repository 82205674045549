import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Collapse, Label, Input } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

// hooks
import { useRandomId } from "hooks/useRamdonId";

const Cta = ({ cta, setCta, showView, toggle, reuse }) => {
  const { t } = useTranslation();
  const [select, setSelect] = useState({});
  const optionGroup = [
    {
      options: [
        { label: "URL", value: "URL" },
        { label: "WHATSAPP", value: "WHATSAPP" },
        { label: "PHONE", value: "PHONE" },
        { label: "EMAIL", value: "EMAIL" },
      ]
    },
  ];

  useEffect(() => {
    if (cta.length > 0) {
      let newSelect = {...select};
      cta.map((c) => {
        newSelect = {
          ...newSelect,
          [c.selectId]: { "label": c.type, "value": c.type },
        };
      })
      setSelect(newSelect);
    } else if (reuse !== null && reuse.data.cta.length > 0) {
      const newCta = [...cta];
      let newSelect = {};
      console.log(newCta);
      reuse.data.cta.map((c) => {
        const id = useRandomId("select");
        newSelect = {
          ...newSelect,
          [id]: { "label": c.type.toUpperCase(), "value": c.type.toUpperCase() },
        };
        newCta.push({ type: c.type.toUpperCase(), action: c.action, destiny: c.destiny, isComplete: true, selectId: id });
      });
      setSelect(newSelect);
      setCta(newCta);
    }
  }, [])

  function handleAdd() {
    const id = useRandomId("select");
    const newSelect = {
      ...select,
      [id]: {},
    };
    setSelect(newSelect);

    const newCta = [...cta];
    newCta.push({ type: "", action: "", destiny: "", isComplete: false, selectId: id });
    setCta(newCta);
  }

  function handleDelete(index) {
    const selectId = cta[index].selectId;
    const cloneSelect = {...select};
    delete cloneSelect[selectId]
    setSelect(cloneSelect);

    const cloneCta = [...cta];
    setCta(cloneCta.filter((f, i) => i !== index));
  }

  function handleSelect(selectedGroup, index) {
    const selectId = cta[index].selectId;
    const newSelect = {
      ...select,
      [selectId]: selectedGroup
    };
    setSelect(newSelect);

    const newCta = [...cta];
    newCta[index].type = selectedGroup.value;
    newCta[index].isComplete = isCompleteCta(newCta, index);
    setCta(newCta);
  }

  function handleChageText(event, index) {
    const newCta = [...cta];
    newCta[index].action = event.target.value;
    newCta[index].isComplete = isCompleteCta(newCta, index);
    setCta(newCta);
  }

  function handleChageAction(event, index) {
    const newCta = [...cta];
    newCta[index].destiny = event.target.value;
    newCta[index].isComplete = isCompleteCta(newCta, index);
    setCta(newCta);
  }

  function isCompleteCta(cta, index) {
    if (cta[index].type != "" && cta[index].action != "" && cta[index].destiny != "") {
      return true;
    }
    return false;
  }

  function isFullyComplete() {
    const newArr = cta.filter((f, i) => f.isComplete == false);
    if (cta.length !== 0) {
      if (newArr.length == 0) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  return (
    <Row className="g-0 col-md-12 offset-md-0 mb-3 mt-1">
      <Col className="col-12 col-sm-12 col-md-12 p-1 text-center">
        <Button className="btn-shadow btn-general bg-color-primary border-none" onClick={() => handleAdd()}>Añadir botón</Button>
      </Col>
      {
        cta.length > 0 &&
        cta.map((e, index) => {
          return (
            <Row key={index} className="g-0 col-md-10 offset-md-1 mt-2">
              <Col className="col-12 col-sm-12 col-md-2 p-1 align-self-center">
                <Label>{t("VideoNew.Step8.CTA.type")}</Label>
                <Select
                  value={select[cta[index].selectId]}
                  placeholder={t("Placeholder.select")}
                  onChange={(selectValue) => {
                    handleSelect(selectValue, index);
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col className="col-12 col-sm-12 col-md-3 p-1 align-self-center text-center-sm" >
                <Label>{t("VideoNew.Step8.CTA.action")}</Label>
                <Input type="text" value={e.action} onChange={(event) => handleChageText(event, index)}/>
              </Col>
              <Col className="col-12 col-sm-12 col-md-4 p-1 align-self-center text-center-sm" >
                <Label>{t("VideoNew.Step8.CTA.destiny")}</Label>
                <Input type="text" value={e.destiny} onChange={(event) => handleChageAction(event, index)} />
              </Col>
              <Col className="col-12 col-sm-12 col-md-1 p-1 align-self-center text-center-sm mt-4">
                <Button id={index} key={index} onClick={() => handleDelete(index)} className="btn-shadow btn-delete text-decoration-none border-red-1 text-red" color="app-green">{t("VideoNew.Step8.delete")}</Button>
              </Col>
              <Col className="col-12 col-sm-12 col-md-2 p-1 align-self-center text-center mt-4" >
                <div className="figure bg-color-white p-0 avatar-xs rounded-circle">
                  <span className="avatar-title bg-transparent text-reset">
                    <i
                      className={classnames(
                        "mdi mdi-close",
                        cta[index].isComplete ? "text-gray" : "text-red",
                      )}
                      style={{ fontSize: 28 }}></i>
                  </span>
                </div>
                <div className="figure bg-color-white p-0 avatar-xs rounded-circle">
                  <span className="avatar-title bg-transparent text-reset">
                    <i
                      className={classnames(
                        "mdi mdi-check-circle-outline",
                        cta[index].isComplete ? "text-green" : "text-gray",
                      )}
                      style={{ fontSize: 26 }}></i>
                  </span>
                </div>
              </Col>
            </Row>
          );
        })
      }
    </Row>
  );
}

export default Cta;
