import React, { useState } from "react"
import PropTypes from 'prop-types'
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "store/layout/actions";

// Import menuDropdown
import LanguageDropdown from "components/layout/CommonForBoth/TopbarDropdown/LanguageDropdown";
import RightSidebar from "components/layout/CommonForBoth/RightSidebar";

import logoLight from "assets/images/logo-light.png"
import logoLightSvg from "assets/images/logo-light.svg"
import logoDark from "assets/images/logo-dark.png"

import logo from "assets/images/app/logo/logo.png";

const Header = () => {
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-lg">
                  <img src={logo} alt="" height="61" width="146" />
                </span>
              </Link>
            </div>
            <div className="d-lg-none ms-2">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} height="41" width="98" />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <LanguageDropdown />
            <div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer} disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

export default Header;
