import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from "react-query";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

// components
import WizardStep from "components/views/Video/components/WizardStep";
import VideoCard from "components/views/Utils/VideoCard";
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ModalClipNew from "components/views/Clip/ModalClipNew";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import CategoryBar from 'components/views/Utils/CategoryBar/CategoryBar';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { ClipsList } from 'store/clip/ClipService';
// routes
import { COMAPANY, COMAPANY_CLIPS } from "routes/server/paths";

const Step5 = ({ back, next, clips, setClips, setOriginalClips, setSelectedClips, selectedClips, originalClips, reuse }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [selectCategoryList, setSelectCategoryList] = useState(null);

  const queryClipList = useQuery(['CLIPS', { token: user.access_token }], ClipsList, {
    refetchOnWindowFocus: false,
    enabled: selectedClips == null,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.clip.error.load_all"));
    },
    onSuccess: (data) => {
      const newClips = data.data.data.filter((e) => (e.status == 1)).map(clip => ({ ...clip, checked: false }));
      if (reuse !== null && reuse.data.make.length > 0) {
        console.log("reuse", reuse);
        reuse.data.make.map(clip => {
          const index = data.data.data.findIndex((e) => e.id === clip.clip_id);
          const updateClips = [...newClips];
          if (index >= 0) {
            updateClips[index].checked = !newClips[index].checked;
          }
        });
      }
      setOriginalClips(newClips);
      setClips(newClips);
    }
  });

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleNext() {
    const selectedClips = [...originalClips].filter(c => (c.checked == true));
    if (selectedClips.length > 0) {
      setSelectedClips(selectedClips);
      setClips(originalClips);
      next(5, 6);
    } else {
      useToast("error", t("Message.error"), t("Message.clip.error.check_clip"));
    }
  }

  function handleCheckboxChange(e) {
    // debugger;
    const indexO = originalClips.findIndex((c) => c.id === e.id);
    const newClipsO = [...originalClips];
    newClipsO[indexO].checked = !originalClips[indexO].checked;
    setOriginalClips(newClipsO);

    const index = clips.indexOf(e);
    const newClips = [...clips];
    newClips[index].checked = newClipsO[indexO].checked;
    setClips(newClips);
  };

  return (
    <Row className="g-0 justify-content-center">
      <Col className="col-sm-12 col-12 col-md-10 offset-md-1">
        <WizardStep step={2} />
        <Header title={t("VideoNew.Step5.title")} subtitle={t("VideoNew.Step5.subtitle")} />
        {
          clips &&
          <>
            <CategoryBar originalList={originalClips} setList={setClips} inputSearchRef={inputSearchRef} setSelectCategoryList={setSelectCategoryList} hideByStatus={true} />
            <ToolBar originalList={originalClips} list={clips} setList={setClips} setInputRef={setInputSearchRef} selectCategoryList={selectCategoryList} />
          </>
        }
        <AvForm>
          <Card className="card-container shadow-none mt-5">
            {/* <div className="mt-5 mb-3 text-center">
              {
                user.role != 'Agente' &&
                <Button onClick={() => setShowModal(!showModal)} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("VideoNew.Step5.load")}</Button>
              }    
            </div> */}
            <div style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-12 offset-md-0">
                {
                  queryClipList.isLoading &&
                  <Loading style="video" />
                }
                {
                  clips &&
                  clips.map((e, i) => {
                    return (
                      <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.company_id}${COMAPANY_CLIPS}${e.clip}`} thumbnail={(e.thumbnail === null || e.thumbnail === 'null') ? null : `${COMAPANY}${e.company_id}${COMAPANY_CLIPS}${e.thumbnail}`} />
                        <Row className="mb-3 justify-content-between">
                          <Col className="col-md-5 text-center"></Col>
                          <Col className="col-md-5 text-center align-self-center">
                            <div className="d-flex justify-content-center">
                              <input
                                type="checkbox"
                                style={{ height: 25, width: 25, float: "right" }}
                                className="form-clip-check"
                                name={e.title}
                                onClick={(event) => handleCheckboxChange(e)}
                                value={e.id}
                                checked={e.checked} />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })
                }
              </Row>
            </div>
          </Card>
          <div className="mt-3 mb-4 text-center">
            <Button onClick={() => back(5)} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
            <Button onClick={handleNext} className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">{t("VideoNew.continue")} →</Button>
          </div>
        </AvForm>
      </Col>
      <Col className="col-sm-12 col-lg-1 col-12"></Col>
      <ModalClipNew
        showModal={showModal}
        setShowModal={setShowModal}
        toggleModal={toggleModal}
      />
    </Row>
  );
}

export default Step5;
