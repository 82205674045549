import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

// config
import { encryptStorage } from 'config/encryptStorage';

const AUTH_APP = 'AUTH_APP';
const USER = 'USER';

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  
  const [isAuthenticated, setIsAuthenticated] = useState(encryptStorage.getItem(AUTH_APP));
  const [user, setUser] = useState(encryptStorage.getItem(USER));

  const login = useCallback(async (user) => {
    setUser(user);
    encryptStorage.setItem(AUTH_APP, true);
    encryptStorage.setItem(USER, JSON.stringify(user));
    setIsAuthenticated(true);
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    encryptStorage.removeItem(AUTH_APP);
    encryptStorage.removeItem(USER);
    setIsAuthenticated(false);
  }, []);

  const value = useMemo(() => ({
    login,
    logout,
    isAuthenticated,
    user
  }), [login, logout, isAuthenticated, user]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.any
}