import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Collapse, Label, Input } from "reactstrap";
import classnames from "classnames";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const Docs = ({ docs, setDocs, showView, toggle }) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: "application/pdf"
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles);
      const newDocs = [...docs];
      setDocs(newDocs.concat(acceptedFiles));
    }
  }, [acceptedFiles]);

  function handleDelete(index) {
    const cloneDocs = [...docs];
    setDocs(cloneDocs.filter((f, i) => i !== index));
  }

  return (
    <>
    <Row className="g-0 col-md-12 offset-md-0 mb-3 mt-1">
      <Col className="col-12 col-sm-12 col-md-12 p-1 text-center">
        <input {...getInputProps()} />
          <Button className="btn-shadow btn-general bg-color-primary border-none" onClick={() => open()}>{t("VideoNew.Step8.Docs.add")}</Button>
      </Col>
    </Row>
    {
      docs.length > 0 &&
      docs.map((file, index) => {
        return (
          <Row className="g-0 col-md-8 offset-md-2 mt-2">
            <Col className="col-12 col-sm-12 col-md-8 p-1 align-self-center">
              <Input value={file.name} readOnly />
            </Col>
            <Col className="col-12 col-sm-12 col-md-4 p-1 align-self-center text-center-sm" >
              <Button id={index} key={index} onClick={() => handleDelete(index)} className="btn-shadow btn-delete text-decoration-none border-red-1 text-red" color="app-green">{t("VideoNew.Step8.delete")}</Button>
            </Col>
          </Row>
        );
      })
    }
    </>
  );
}

export default Docs;
