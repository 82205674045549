import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Card, Button } from "reactstrap";
import { useQuery } from "react-query";
import { useTranslation } from 'react-i18next';

// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { ValidateEmailService } from 'store/auth/AuthService';
// routes
import { VIDEOS, LOGIN } from 'routes/app/paths';

const ValidateEmail = () => {
  const history = useHistory();
  const { token } = useParams();
  const { t } = useTranslation();
  const { login, isAuthenticated } = useAuthContext();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(t("Auth.ValidateEmail.title"));
  const [subtitle, setSubtitle] = useState(t("Auth.ValidateEmail.subtitle"));

  const queryValidateEmail = useQuery(['VALIDATE_EMAIL', { token: token }], ValidateEmailService, {
    onError: (error) => {
      setTitle(t("Message.problem"));
      if (error.status === 430) {
        setSubtitle(t("Message.auth.error.token_error"));
        useToast("error", t("Message.error"), t("Message.auth.error.token_error"));
      } else if (error.status === 403) {
        setSubtitle(t("Message.auth.error.token_expired"));
        useToast("error", t("Message.error"), t("Message.auth.error.token_expired"));
      } else {
        setSubtitle(t("Message.problem"));
        useToast("error", t("Message.error"), t("Message.problem"));
      }
      setLoading(true);
    },
    onSuccess: (data) => {
      if (data.status === 200) {
        setLoading(true);
      }
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push(VIDEOS);
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <div className="main-content bg-image-register-success">
        <MetaTags>
          <title>{t("Auth.Register.meta")}</title>
        </MetaTags>
        <Container fluid className="content" style={{ height: "80vh" }}>
          <Card className="border-radius-10 col-sm-12 col-md-6 offset-md-3 text-center mb-5 mt-5">
            <Row className="g-0 p-5">
              {
                loading &&
                <>
                  <h1 className="MontSemiBold font-size-md lh-1 text-black ">{title}</h1>
                  <h4 className="mb-5 mt-5 lh-1 text-black">{subtitle}</h4>
                  <div className="text-center mt-3">
                    <Button onClick={() => history.push(LOGIN)} className="btn-shadow btn-main-block" color="app-primary">{t("Auth.Login.signin")}</Button>
                  </div>
                </>
              }
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ValidateEmail;
