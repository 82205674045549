import { EncryptStorage } from 'encrypt-storage';

// config
import { STORAGE_SECRET_KEY } from "config/env";

const options = {
  prefix: "@vvv",
  storageType: "localStorage",
  stateManagementUse: false
}

export const encryptStorage = EncryptStorage(STORAGE_SECRET_KEY, options);