import React, { useState } from 'react';
import { useMutation, useQueryClient } from "react-query";
import { Container, Row, Col, Modal, Card, Button, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import EditionView from "components/views/Utils/Edition/components/EditionView";
import ToolBar from "components/views/Utils/Edition/components/ToolBar";
import Header from 'components/views/Utils/Header';
import ModalVideoName from "components/views/Utils/Edition/components/ModalVideoName";
// services
import { VideoEdit } from 'store/video/VideoService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import useDataURLtoFile from 'hooks/useDataURLtoFile';
import { useToast, useClearToasts } from "hooks/useToast";

const EditVideo = ({ 
  video, 
  showModal, 
  setShowModal, 
  toggleModal, 
  dynamicText = false, 
  imagesP = [], 
  setImagesP = null,
  textsP = [], 
  setTextsP = null,
  dynamicP = null,
  setDynamicP = null,
  setImageStage = null,
  setVideoScreen = null,
  videoSelected = null,
  handleCheckboxChange = () => {}
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [stageRef, setStageRef] = useState(null);
  const [title, setTitle] = useState(null);
  const [images, setImages] = useState(imagesP);
  const [texts, setTexts] = useState(textsP);
  const [dynamic, setDynamic] = useState(dynamicP);
  const [loading, setLoading] = useState(false);
  const [showModalName, setShowModalName] = useState(false);

  const mutationVideoEdit = useMutation('EDIT_VIDEO', VideoEdit, {
    onError: (error) => {
      setLoading(false);
      useClearToasts();
      useToast("error", t("Message.error"), t("Message.video.error.convert"));
    },
    onSuccess: (data) => {
      setLoading(false);
      setTexts([]);
      setImages([]);
      useToast("success", t("Message.info"), t("Message.video.success.add"));
      queryClient.refetchQueries(['PRESENTATIONS', { token: user.access_token }]);
      setShowModal(false);
    }
  });

  function toggleModalName() {
    setShowModalName(!showModalName);
    document.body.classList.add("no_padding");
  }

  const downloadURI = (uri, name) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function handleFinish() {
    setShowModalName(false);
    // const x = (dynamicText && dynamic != null) ? dynamic.x : null;
    // const y = (dynamicText && dynamic != null) ? dynamic.y : null;
    let image = null;

    // console.log("dinamyc", dynamic);

    if (dynamicText) {
      handleCheckboxChange(videoSelected, true);
      setLoading(true);
      console.log(dynamic);
      let d = null;
      if (dynamic != null) {
        d = {
          ...dynamic,
          x: (document.getElementById('player').children[0].videoWidth / document.getElementById('player').children[0].offsetWidth) * dynamic.x,
          y: (document.getElementById('player').children[0].videoHeight / document.getElementById('player').children[0].offsetHeight) * dynamic.y
        };
      }
      
      // console.log("new dinamyc", d);
      setDynamic(null);
      setTimeout(() => {
        setImagesP(images);
        setTextsP(texts);
        // setDynamicP(dynamic);
        setDynamicP(d);
        setVideoScreen({
          "width": document.getElementById('player').children[0].videoWidth,
          "height": document.getElementById('player').children[0].videoHeight
        });
        setImageStage(useDataURLtoFile(stageRef.toDataURL({
          quality: 1,
          pixelRatio: 4,
        }), "image"));
        // setDynamic(d);

        // downloadURI(stageRef.toDataURL({
        //   quality: 1,
        //   pixelRatio: 4,
        // }), "test");

        setDynamic(dynamic);
        setLoading(false);
        setShowModal(false);
      }, 1000);

    } else {
      setTimeout(() => {
        image = useDataURLtoFile(stageRef.toDataURL({
          quality: 1,
          pixelRatio: 4,
        }), "image");

        const data = new FormData();
        data.append("video_id", video.id);
        data.append("title", title);
        data.append("image", image);
        data.append("width", document.getElementById('player').children[0].videoWidth);
        data.append("height", document.getElementById('player').children[0].videoHeight);
        // if (dynamicText && dynamic != null) {
        //   data.append("x", x);
        //   data.append("y", y);
        // }

        const json = {
          "video_id": video.id,
          "title": title,
          "width": document.getElementById('player').children[0].videoWidth,
          "height": document.getElementById('player').children[0].videoHeight,
          // "x": x,
          // "y": y
        }

        console.log(json);

        setLoading(true);
        useToast("warning", t("Message.info"), t("Message.video.success.wait"));
        mutationVideoEdit.mutate({ token: user.access_token, data });
      }, 1000);
    }
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Header title={t("Edition.title_video")} modal={true} />
        <Container fluid className="content">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Card className="card-container shadow-none">
                <Row className="g-0 col-md-12 offset-md-0">
                  <Col className="col-12 col-sm-12 col-md-8 bg-color-red d-flex p-2">
                    <EditionView 
                      setStageRef={setStageRef}
                      video={video?.url}
                      texts={texts}
                      setTexts={setTexts}
                      images={images}
                      setImages={setImages}
                      dynamic={dynamic}
                      setDynamic={setDynamic}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-4 bg-color-blue d-flex p-2">
                    <ToolBar
                      texts={texts}
                      setTexts={setTexts}
                      images={images}
                      setImages={setImages}
                      dynamic={dynamic}
                      setDynamic={setDynamic}
                      dynamicText={dynamicText}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div className="mt-2 mb-4 text-center">
            {
              !dynamicText ?
                <Button onClick={() => toggleModalName()} className="btn-shadow btn-general bg-color-primary" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("Edition.edit_video")}
                </Button>
              : 
                <Button onClick={() => handleFinish()} className="btn-shadow btn-general bg-color-primary" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("Edition.save")}
                </Button>
            }
            
          </div>
        </Container>
        </div>
      </div>
      <ModalVideoName
        name={title}
        setName={setTitle}
        handleFinish={handleFinish}
        showModal={showModalName}
        setShowModal={setShowModalName}
        toggleModal={toggleModalName} />
    </Modal>
  );
}

export default EditVideo;
