import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
// services
import { ClipsList } from 'store/clip/ClipService';
import { CategoryClipsList, CategoryClipsUpdate } from 'store/category/CategoryService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// routes
import { COMAPANY, COMAPANY_CLIPS } from "routes/server/paths";

const SubcategoryDetail = (props) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const history = useHistory();
  const { subcategory } = useParams();
  const [loading, setLoading] = useState(false);
  const [originalList, setOriginalList] = useState(null);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [clips, setClips] = useState(null);

  const queryClipList = useQuery(['CLIPS', { token: user.access_token }], ClipsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_clips"));
    },
    onSuccess: (data) => {
      const clips = data.data.data;
      setClips(clips.map((e) => ({...e, "checked": false})));
      setOriginalList(clips.map((e) => ({ ...e, "checked": false })));
    }
  });

  const queryCategoryClipsList = useQuery(['CATEGORY_CLIPS', { token: user.access_token, id: subcategory }], CategoryClipsList, {
    refetchOnWindowFocus: false,
    enabled: !!clips,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_clips_by_category"));
    },
    onSuccess: (data) => {
      const checked = data.data.data;
      console.log("CATEGORY_CLIPS", checked);
      const newCheckedClips = clips.map(clip => {
        if (checked.find(check => (clip.id == check.clip_id))) {
          return { ...clip, checked: true };
        } else {
          return { ...clip, checked: false };
        }
      });
      setClips(newCheckedClips);
    }
  });

  const mutationCategoryClipsUpdate = useMutation('UPDATE_CATEGORY_CLIPS', CategoryClipsUpdate, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.category.error.update"));
    },
    onSuccess: (data) => {
      setLoading(false);
      useToast("success", t("Message.info"), t("Message.category.success.update"));
    }
  });

  function handleCheckboxChange(e) {
    const index = clips.indexOf(e);
    const newClips = [...clips];
    newClips[index].checked = !newClips[index].checked;
    setClips(newClips);
  };

  function handleSubmit() {
    const selectClips = clips.filter(c => (c.checked == true)).map((e) => (e.id));
    // if (selectClips.length > 0) {
      const info = {
        category_id: subcategory,
        clip_id: selectClips
      }
      setLoading(true);
      mutationCategoryClipsUpdate.mutate({ token: user.access_token, info });
    // }
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("SubCategoryDetail.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={props.location.state.name} subtitle={t("SubCategoryDetail.subtitle")} />
            <div className="text-center">
              <Button onClick={() => handleSubmit()} className="btn-shadow btn-general bg-color-primary" color="app-green">
                <Spinner
                  style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                  as="span"
                  variant="dark"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border" />
                {t("SubCategoryDetail.load_clips")}
              </Button>
            </div>
            <div className="my-4">
              <ToolBar originalList={originalList} list={clips} setList={setClips} setInputRef={setInputSearchRef} />
            </div>
            <AvForm className="form-horizontal">
              <Card className="card-container shadow-none" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
                <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                  {
                    queryClipList.isLoading &&
                    <Loading style="video" />
                  }
                  {
                    clips &&
                    clips.map((e, i) => {
                      return (
                        <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                          <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.company_id}${COMAPANY_CLIPS}${e.clip}`} />
                          <Row className="mb-3">
                            <Col className="col-md-10 offset-md-1">
                              <input
                                type="checkbox"
                                style={{ height: 25, width: 25, float: "right" }}
                                className="form-clip-check"
                                name="check"
                                onClick={(event) => handleCheckboxChange(e)}
                                value={e.id}
                                checked={e.checked} />
                            </Col>
                          </Row>
                        </Col>
                      );
                    })
                  }
                </Row>
              </Card>
            </AvForm>
            <div className="mt-3 mb-4 text-center">
              <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">← {t("VideoNew.back")}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SubcategoryDetail;
