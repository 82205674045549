import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useLocation, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// images
import logoPreview from "assets/images/app/landing/logo_preview.png";
import oval from "assets/images/app/landing/ford/oval/oval.png";

const LandingPreview = () => {
  const { t } = useTranslation();
  const state = useLocation();
  const [titleColor, setTitleColor] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState(null);
  const [logo, setLogo] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [bgHeight, setBgHeight] = useState(500);

  useEffect(() => {
    debugger;
    const params = (state.hash.split('?'));
    if (params.length > 0) {
      setTitleColor(params[0]);
      setSecondaryColor(params[1]);
      setLogo(params[2]);
      setBackgroundImage(params[3]);
      setBgHeight(params[4]);
    }
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{t("Landing.meta")}</title>
      </MetaTags>
      <div className="main-content" style={{
        backgroundImage: `url(${(backgroundImage === null || backgroundImage === "undefined") ? oval : backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: parseInt(bgHeight),
        position: "absolute",
        bottom: 1,
        width: '100%'
      }}></div>
      <Container fluid className="content">
        <Row className="g-0 text-center">
          <span className="logo-lg">
            <img src={(logo === null || logo === "undefined") ? logoPreview : logo} alt="" height="100" width="100" />
          </span>
        </Row>
        <Card className="rounded-3" style={{ marginTop: 150 }}>
          <Row className="g-0 d-content">
            <Col className="col-lg-6 col-md-6 col-sm-12 mx-auto" style={{ width: 720 }}>
              <Row className="g-0 text-center" style={{ marginTop: -120 }}>
                <div className="col-lg-12 col-md-12 col-sm-12" id="outer" style={{ padding: 0 }}>
                  <video src={``} className="landing-video" controls></video>
                </div>
              </Row>
              <Row className="g-0 mb-5 text-center">
                <h1 className="MontBold font-size-lg lh-1 pt-5 mb-4" style={{ color: titleColor }}>{`${t("Landing.hello")} Jesús!`}</h1>
                <h4 className="lh-2" style={{ color: titleColor }}>
                  {t("Landing.title")}
                  <br />
                  {t("Landing.message")}
                </h4>
              </Row>
              <Row className="g-0 mb-4 justify-content-center col-md-12">
                <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                  <Button className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: secondaryColor }}>{t("Landing.link")}</Button>
                </Col>
                <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                  <Button className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: secondaryColor }}>{t("Landing.email")}</Button>
                </Col>
                <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                  <Button className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: secondaryColor }}>{t("Landing.whatsapp")}</Button>
                </Col>
                <Col className="col-12 col-sm-12 col-md-4 text-center mt-2">
                  <Button className="MontSemiBold p-3 border-none" style={{ fontSize: 16, minWidth: 220, backgroundColor: secondaryColor }}>{t("Landing.phone")}</Button>
                </Col>
              </Row>
              <Row className="g-0 ms-5 me-5 mb-5">
                {/* <p className="line-100-black mt-5"></p> */}
              </Row>
              <Row className="g-0 ms-5 me-5 mb-5 text-center justify-content-center">
                <h5 className="MontSemiBold lh-2" style={{ color: secondaryColor }}>{t("Landing.zip_message")}</h5>
                <button 
                  className="MontSemiBold bg-color-white align-self-center mt-2 p-2" 
                  style={{ fontSize: 16, width: 220, border: `1px solid ${secondaryColor}`, 'color': secondaryColor, borderRadius: '0.25rem' }}>
                  {t("Landing.download")}
                  <i className="mdi mdi-download ms-1" style={{ fontSize: 24, cursor: "pointer" }}></i>
                </button>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default LandingPreview;
