import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQueryClient } from "react-query";

// hooks
import { useToast } from "hooks/useToast";
// services
import { ResetPasswordService } from 'store/auth/AuthService';
// components
import Success from "components/views/Auth/components/Success";

const PasswordChange = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);

  console.log(token);

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    const data = {
      "token": token,
      "password": values.password,
      "confirmed": values.confirmed
    }

    console.log(data);

    setLoading(true);
    const fetchToken = await queryClient.fetchQuery([data], ResetPasswordService);
    setLoading(false);
    if (fetchToken.status === 200) {
      useToast("success", t("Message.info"), "Contraseña actualizada");
    } else if (error.status === 433) {
      useToast("error", t("Message.error"), "EL link ya expiró, vuelvalo a intentar");
    } else {
      useToast("error", t("Message.error"), t("Message.problem"));
    }
    
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>{t("Auth.PasswordChange.meta")}</title>
        </MetaTags>
        <Container fluid className="content">
          <Row>
            <Col className="col-sm-12 col-md-6 offset-md-3">
              <h1 className="MontSemiBold font-size-lg lh-1 text-black text-center pt-5 mb-5">{t("Auth.PasswordChange.title")}</h1>
              <Card className="card-container">
                <Row className="g-0">
                  <Col xl={12}>
                    <div className="m-lg-5 p-lg-5 m-sm-1 p-sm-3">
                      <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                        <div className="mb-4">
                          <AvField
                            name="password"
                            type="password"
                            placeholder={t("Placeholder.new_password")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.new_password") },
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <AvField
                            name="confirmed"
                            type="password"
                            placeholder={t("Placeholder.confirmed_password")}
                            validate={{ 
                              match: { value: 'password', errorMessage: t("Validation.not_match_password") },
                              required: { value: true, errorMessage: t("Validation.confirmed_password") }
                            }}
                          />
                        </div>
                        <div className="mt-5 text-center">
                          <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                            <Spinner
                              style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border" />
                            {t("Auth.PasswordChange.save")}
                          </Button>
                        </div>
                      </AvForm>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            </Row>
          <Success />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PasswordChange;
