import React, { useState } from 'react';
import { Row, Col, Modal, Collapse, Button } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

const ModalCookieSetting = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();
  
  const [col1, setcol1] = useState(false);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);

  const [necessary, setNecessary] = useState(true);
  const [priority, setPriority] = useState(true);
  const [analysis, setAnalysis] = useState(true);
  const [performance, setPerformance] = useState(true);

  const t_col1 = () => {
    setcol1(!col1)
    setcol2(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col2 = () => {
    setcol2(!col2)
    setcol1(false)
    setcol3(false)
    setcol4(false)
  }

  const t_col3 = () => {
    setcol3(!col3)
    setcol1(false)
    setcol2(false)
    setcol4(false)
  }

  const t_col4 = () => {
    setcol4(!col4)
    setcol1(false)
    setcol2(false)
    setcol3(false)
  }

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  const handleSaveCookie = () => {
    setShowModal(!showModal);
  }

  return (
    <Modal
      size="lg"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 col-12">
              <h4 className='MontSemiBold'>{t("Cookie.necessary.title")}</h4>
              <p>{t("Cookie.necessary.subtitle")}</p>
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <div className="form-check form-check-right form-switch form-switch-md mb-3" style={{ position: "absolute", zIndex: 1000, right: 70, marginTop: '0.75rem' }} >
                      <input
                        type="checkbox"
                        className="form-check-input ms-3"
                        id="neccesary"
                        checked={necessary}
                        onClick={e => { setNecessary(!necessary) }}
                      />
                      <label htmlFor="neccesary" class="mt-1">
                        {necessary ? t("Cookie.necessary.enable") : t("Cookie.necessary.disable")}
                      </label>
                    </div>
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "MontSemiBold",
                        "text-black",
                        { collapsed: !col1 }
                      )}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
                    >
                      {t("Cookie.necessary.neccesary.title")}
                    </button>
                  </div>
                  <Collapse isOpen={col1} className="accordion-collapse">
                    <div className="accordion-body">
                      <p>{t("Cookie.necessary.neccesary.subtitle")}</p>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <div className="form-check form-check-right form-switch form-switch-md mb-3" style={{ position: "absolute", zIndex: 1000, right: 70, marginTop: '0.75rem' }} >
                      <input
                        type="checkbox"
                        className="form-check-input ms-3"
                        id="priority"
                        checked={priority}
                        onClick={e => { setPriority(!priority) }}
                      />
                      <label htmlFor="priority" class="mt-1">
                        {priority ? t("Cookie.necessary.enable") : t("Cookie.necessary.disable")}
                      </label>
                    </div>
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "MontSemiBold",
                        "text-black",
                        { collapsed: !col2 }
                      )}
                      type="button"
                      onClick={t_col2}
                      style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
                    >
                      {t("Cookie.necessary.priority.title")}
                    </button>
                  </div>
                  <Collapse isOpen={col2} className="accordion-collapse">
                    <div className="accordion-body">
                      <p>{t("Cookie.necessary.priority.subtitle")}</p>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <div className="form-check form-check-right form-switch form-switch-md mb-3" style={{ position: "absolute", zIndex: 1000, right: 70, marginTop: '0.75rem' }} >
                      <input
                        type="checkbox"
                        className="form-check-input ms-3"
                        id="analysis"
                        checked={analysis}
                        onClick={e => { setAnalysis(!analysis) }}
                      />
                      <label htmlFor="analysis" class="mt-1">
                        {analysis ? t("Cookie.necessary.enable") : t("Cookie.necessary.disable")}
                      </label>
                    </div>
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "MontSemiBold",
                        "text-black",
                        { collapsed: !col3 }
                      )}
                      type="button"
                      onClick={t_col3}
                      style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
                    >
                      {t("Cookie.necessary.analysis.title")}
                    </button>
                  </div>
                  <Collapse isOpen={col3} className="accordion-collapse">
                    <div className="accordion-body">
                      <p>{t("Cookie.necessary.analysis.subtitle")}</p>
                    </div>
                  </Collapse>
                </div>
                <div className="accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <div className="form-check form-check-right form-switch form-switch-md mb-3" style={{ position: "absolute", zIndex: 1000, right: 70, marginTop: '0.75rem' }} >
                      <input
                        type="checkbox"
                        className="form-check-input ms-3"
                        id="performance"
                        checked={performance}
                        onClick={e => { setPerformance(!performance) }}
                      />
                      <label htmlFor="performance" class="mt-1">
                        {performance ? t("Cookie.necessary.enable") : t("Cookie.necessary.disable")}
                      </label>
                    </div>
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "MontSemiBold",
                        "text-black",
                        { collapsed: !col4 }
                      )}
                      type="button"
                      onClick={t_col4}
                      style={{ cursor: "pointer", backgroundColor: "#f7f5f5" }}
                    >
                      {t("Cookie.necessary.performance.title")}
                    </button>
                  </div>
                  <Collapse isOpen={col4} className="accordion-collapse">
                    <div className="accordion-body">
                      <p>{t("Cookie.necessary.performance.subtitle")}</p>
                    </div>
                  </Collapse>
                </div>
              </div>
            </Col>
          </Row>
          <Button className="btn-shadow btn-general bg-color-green color-white border-none float-end mt-3" onClick={handleSaveCookie}>
            {t("Cookie.save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalCookieSetting;
