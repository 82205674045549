import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useQueryClient, useQuery, useMutation } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

// services
import { CompaniesList } from 'store/company/CompanyService';
import { UserAdd } from 'store/user/UserService';
// components
import Header from 'components/views/Utils/Header';
// hook
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const UserNew = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formRef, setFormRef] = useState(null);

  useQuery(['COMPANIES', { token: user.access_token }], CompaniesList, {
    enabled: user.role == 'Admin',
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.company.error.load_all"));
    },
    onSuccess: (data) => {
      const newCompanies = data.data.data.map((e, i) => {
        return {
          label: e.name,
          value: e.id
        }
      });
      setCompanies(newCompanies);
    }
  });

  const mutationUserAdd = useMutation('CREATE_USER', UserAdd, {
    onError: (error) => {
      setLoading(false);
      useToast("error", t("Message.error"), t("Message.user.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      formRef.reset();
      setSelectedCompany(null);
      setSelectedRole(null);
      useToast("success", t("Message.info"), t("Message.user.success.add"));
    }
  })

  function handleSelectCompany(data) {
    setSelectedCompany(data);
  }

  function handleSelectRole(data) {
    setSelectedRole(data);
  }

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (user.role == 'Admin' && selectedCompany == null) {
      useToast("error", t("Message.alert"), t("Message.company.error.select_company"));
      return;
    }

    if (user.role == 'Admin' && selectedRole == null) {
      useToast("error", t("Message.alert"), t("Message.company.error.select_role"));
      return;
    }

    const data = {
      "name": values.name,
      "last_name": values.lastname,
      "second_last_name": values.secondLastName,
      "email": values.email,
      "phone": values.phone,
      "department": values.department,
      "company_id": user.role == 'Admin' ? selectedCompany.value : user.company_id,
      "role": user.role == 'Admin' ? selectedRole.value : "Agente",
      "password": "codeals",
    }
    setLoading(true);
    mutationUserAdd.mutate({ token: user.access_token, user: data });
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("UserNew.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("UserNew.title")} />
            <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
              <Card className="card-container shadow-none">
                <Row className="g-0 mt-5 mb-5">
                  <Col lg={12}>
                    <Row>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.surname")}
                            type="text"
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastName"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            // validate={{
                            //   required: { value: true, errorMessage: t("Validation.second_surname") },
                            // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.not_email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          <AvField
                            name="phone"
                            className="form-control"
                            placeholder={t("Placeholder.phone")}
                            type="number"
                            pattern="^\d{9}$"
                            errorMessage={t("Validation.not_phone")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.phone") },
                            }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          <AvField
                            name="department"
                            className="form-control"
                            placeholder={t("Placeholder.department")}
                            type="text"
                          // validate={{
                          //   required: { value: true, errorMessage: t("Validation.company") },
                          // }}
                          />
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                        <div className="mb-4">
                          {
                            user.role == 'Admin' &&
                            (companies &&
                              <Select
                                value={selectedCompany}
                                placeholder={t("Placeholder.select_company")}
                                onChange={(data) => {
                                  handleSelectCompany(data)
                                }}
                                options={companies}
                                classNamePrefix="select2-selection"
                              />
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-0">
                        <div className="mb-4">
                          {
                            user.role == 'Admin' ?
                              <Select
                                value={selectedRole}
                                placeholder={t("Placeholder.select_role")}
                                onChange={(data) => {
                                  handleSelectRole(data)
                                }}
                                options={[{ label: "Admin", value: "Admin" }, { label: "Manager", value: "Manager" }, { label: "Agente", value: "Agente" }]}
                                classNamePrefix="select2-selection"
                              />
                              :
                              <Select
                                value={selectedRole}
                                placeholder={t("Placeholder.select_role")}
                                onChange={(data) => {
                                  handleSelectRole(data)
                                }}
                                options={[{ label: "Manager", value: "Manager" }, { label: "Agente", value: "Agente" }]}
                                classNamePrefix="select2-selection"
                              />
                          }
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <div className="mt-3 mb-4 text-center">
                <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white mb-2" color="app-green">← {t("VideoNew.back")}</Button>
                <Button className="btn-shadow btn-general bg-color-green color-white ms-0 ms-lg-3 ms-md-3 ms-sm-3 mb-2" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("UserNew.save")}
                </Button>
              </div>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserNew;