import React, { useState } from 'react';
import { Row, Col, Modal, Button, Input, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
// hook
import { useToast } from "hooks/useToast";
import useAuthContext from 'hooks/useAuthContext';

const ModalDeleteWithText = ({ showModal, setShowModal, dataDelete, mutation, model }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  function handleChageText(event) {
    setText(event.target.value);
  }

  function handleContinue() {
    if (text != "") {
      if (text === t("ModalDeleteWithText.key_word")) {
        mutation.mutate({ token: user.access_token, id: dataDelete });
      } else {
        useToast("error", t("Message.error"), t("Message.video.error.key_word_wrong"));
      }
    } else {
      useToast("error", t("Message.error"), t("Message.video.error.key_word"));
    }
    
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header 
                modal={true}
                title={t("ModalDeleteWithText.title", { text: model })}
                subtitle={t("ModalDeleteWithText.subtitle", { text: model })} />
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <Input type="text" value={text} onChange={(event) => handleChageText(event)} />
                </Col>
              </Row>
              <div className="text-center">
                <Button onClick={() => handleContinue()} className="btn-shadow btn-general bg-color-primary m-3" color="app-green">
                  <Spinner
                    style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                    as="span"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    animation="border" />
                  {t("ModalDeleteWithText.delete")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalDeleteWithText;
