import React, { useState } from 'react';
import { Card, CardBody } from "reactstrap";
import Select from "react-select";
import { useTranslation } from 'react-i18next';

// hooks
import useSort from "hooks/useSort";

const Sort = ({ list, setList, inside=false }) => {
  const { t } = useTranslation();
  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      options: [
        { label: t("Sort.name_asc"), value: "title_asc", key: "title", inverse: true },
        { label: t("Sort.name_des"), value: "title_des", key: "title", inverse: false },
        { label: t("Sort.date_asc"), value: "create_at_asc", key: "created_at", inverse: true },
        { label: t("Sort.date_des"), value: "create_at_des", key: "created_at", inverse: false },
      ]
    }
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
    setList(useSort(selectedGroup.key, list, selectedGroup.inverse, inside));
  }

  const modStyles = () => ({
    border: "1 !important",
    // This line disable the blue border
    boxShadow: "0 !important",
    "&:hover": {
      border: "1 !important",
      borderColor: "hsl(0, 0%, 80%)"
    },
    "&:focus": {
      border: "1 !important",
      borderColor: "hsl(0, 0%, 80%)"
    }
  });

  const styles = {
    control: (styles) => ({ ...styles, ...modStyles() }),
  };

  return (
    <Card className="shadow-none mb-0 bg-transparent">
      {/* <CardBody> */}
        <Select
          value={selectedGroup}
          placeholder={t("Placeholder.sort")}
          onChange={(e) => {
            handleSelectGroup(e)
          }}
          options={optionGroup}
          classNamePrefix="select2-selection"
          style={styles}
        />
      {/* </CardBody> */}
    </Card>
  );
}

export default Sort;
