import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button, Label } from "reactstrap";
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation";
import { useQueryClient } from "react-query";
import { useTranslation } from 'react-i18next';

// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
// services
import { ConfirmRegisterService } from 'store/auth/AuthService';
// routes
import { DASHBOARD, REGISTER_SUCCESS } from 'routes/app/paths';
import { BASE_URL } from 'routes/app/paths';

const Register = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [checkClasses, setCheckClasses] = useState("");
  const { login, isAuthenticated } = useAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(DASHBOARD);
    }
  }, [isAuthenticated]);

  async function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (!values.politics) {
      setCheckClasses("text-danger");
      return;
    } else {
      setCheckClasses("");
    }

    if (values.password !== values.confirmPassword) {
      useToast("error", t("Message.error"), t("Message.auth.error.confirm_password"));
      return;
    }

    const userInfo = {
      name: values.name,
      last_name: values.lastname,
      second_last_name: values.secondLastname,
      email: values.email,
      password: values.password,
      ads: values.ads,
      url: BASE_URL
    }

    try {
      const fetchToken = await queryClient.fetchQuery([userInfo], ConfirmRegisterService);
      if (fetchToken.status === 201) {
        history.push(REGISTER_SUCCESS);
      }
    } catch (error) {
      if (error.status === 404) {
        useToast("error", t("Message.error"), t("Message.auth.error.not_email"));
      } else {
        useToast("error", t("Message.error"), t("Message.problem"));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="main-content bg-image-register">
        <MetaTags>
          <title>{t("Auth.Register.meta")}</title>
        </MetaTags>
        <Container fluid className="content ">
          <Card className="border-radius-10 col-sm-12 col-md-6 offset-md-3">
            <Row className="g-0 p-5">
              <h1 className="MontSemiBold font-size-md lh-1 text-black">{t("Auth.Register.title")}</h1>
              <h6 className="mb-5 lh-1 text-black">{t("Auth.Register.subtitle")}</h6>
              <Col xl={12}>
                <div className="">
                  <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)}>
                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="name"
                            className="form-control"
                            placeholder={t("Placeholder.name")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.name") },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="5" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="lastname"
                            className="form-control"
                            placeholder={t("Placeholder.surname")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.surname") },
                            }}
                          />
                        </div>
                      </Col>
                      
                      <Col xl="5" sm="12" className="offset-lg-2 p-0">
                        <div className="mb-4">
                          <AvField
                            name="secondLastname"
                            className="form-control"
                            placeholder={t("Placeholder.second_surname")}
                            type="text"
                            validate={{
                              required: { value: true, errorMessage: t("Validation.second_surname") },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="email"
                            className="form-control"
                            placeholder={t("Placeholder.email")}
                            type="email"
                            errorMessage={t("Validation.email")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.email") },
                              email: true
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="password"
                            type="password"
                            placeholder={t("Placeholder.password")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.password") },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="col-12 col-sm-12 col-md-12 col-lg-12 offset-lg-0">
                      <Col xl="12" sm="12" className="p-0">
                        <div className="mb-4">
                          <AvField
                            name="confirmPassword"
                            type="password"
                            placeholder={t("Placeholder.confirm_password")}
                            validate={{
                              required: { value: true, errorMessage: t("Validation.confirm_password") },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>

                    <p className="mb-0 font-size-10">{t("Auth.Register.text")}</p>

                    <AvGroup check className="mt-3 MontBold text-black">
                      <Label check className={checkClasses}>
                        <AvInput type="checkbox" name="politics" />
                        {t("Auth.Register.politic")} <a href="https://virtualvendorvideo.com/aviso-legal" target="_blank" className="MontBold text-black">{t("Footer.conditions")}</a> {t("Auth.Login.politic3")} <a href="https://virtualvendorvideo.com/politica-de-privacidad" target="_blank" className="MontBold text-black">{t("Auth.Login.politic4")}</a>
                      </Label>
                    </AvGroup>

                    <AvGroup check className="mt-3 MontBold text-black">
                      <Label check>
                        <AvInput type="checkbox" name="ads" onClick={() => {}} checked={false} />
                        {t("Auth.Register.subscribe")}
                      </Label>
                    </AvGroup>

                    <div className="text-center mt-3">
                      <Button className="btn-shadow btn-main-block" color="app-primary">{t("Auth.Register.register")}</Button>
                    </div>

                    <div className="mt-5 text-center">
                      <p className="mb-0 MontBold text-black">{t("Auth.Register.has_account")}</p>
                      <Link
                        to="login"
                        className="text-green MontSemiBold"
                      >
                        {t("Auth.Register.login")}
                      </Link>
                    </div>

                  </AvForm>
                </div>
              </Col>
            </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register;
