import React from 'react';

const AutoComplete = ({ filteredSuggestions, onClick }) => {

  return filteredSuggestions.length > 0 &&
      (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <li key={index} onClick={onClick.bind(this, index)}>
                {suggestion.name}
              </li>
            );
          })}
        </ul>
      )
};

export default AutoComplete;
