import React, { useState } from 'react';
import { Row, Col, Modal, Container, Button, Input } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';

const ModalVideoName = ({ name, setName, handleFinish, showModal, setShowModal, toggleModal, isVideo=true }) => {
  const { t } = useTranslation();

  function handleChageName(event) {
    setName(event.target.value);
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header 
                modal={true}
                title={isVideo ? t("Edition.ModalVideoName.video") : t("Edition.ModalVideoName.image") } />
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <Input type="text" value={name} onChange={(event) => handleChageName(event)} />
                </Col>
              </Row>
              <div className="text-center">
                <Button onClick={() => handleFinish()} className="btn-shadow btn-general bg-color-green m-3" color="app-green">{t("Edition.ModalVideoName.save")}</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalVideoName;
