import React from 'react';
import { Row, Col } from "reactstrap";

// components
import Search from 'components/views/Utils/ToolBar/components/Search';
import Sort from 'components/views/Utils/ToolBar/components/Sort';

const ToolBar = ({ originalList, list, setList, setInputRef, selectCategoryList, inside = false }) => {
  return (
    <Row className="g-0 justify-content-center ">
      <Col className="col-12 col-sm-12 col-md-4 ps-1">
        <Sort list={list} setList={setList} inside={inside}/>
      </Col>
      <Col className="col-12  col-sm-12 col-md-4 ps-1">
        <Search originalList={originalList} list={list} setList={setList} setInputRef={setInputRef} selectCategoryList={selectCategoryList} inside={inside} />
      </Col>
    </Row>
  );
}

export default ToolBar;
