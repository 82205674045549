import React, { useState} from 'react';
import Select from "react-select";
import { Row, Col, Card, CardBody } from "reactstrap";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from "react-bootstrap-table2-editor"
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// scss
import "assets/scss/custom/datatables.scss";

const Table = ({ data, columns, editing = false, editRow = () => {}, sorted=true }) => {
  const [selectedGroup, setselectedGroup] = useState(null);

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Mustard", value: "Mustard" },
        { label: "Ketchup", value: "Ketchup" },
        { label: "Relish", value: "Relish" }
      ]
    },
    {
      label: "Camping",
      options: [
        { label: "Tent", value: "Tent" },
        { label: "Flashlight", value: "Flashlight" },
        { label: "Toilet Paper", value: "Toilet Paper" }
      ]
    }
  ];

  const defaultSorted = [
    sorted ?
    {
      dataField: 'id',
      order: 'asc'
    }
    : {}
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: data?.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (data)?.length }];


  // Select All Button operation
  // const selectRow = {
  //   mode: 'checkbox'
  // }

  const { SearchBar } = Search;

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      keyField='id'
      columns={columns}
      data={data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField='id'
          columns={columns}
          data={data}
          search
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-4">
                  <Card className="shadow-none mb-0 mt-4">
                    <div className="select2-selection__indicators css-1hb7zxy-IndicatorsContainer table-search">
                      <SearchBar
                        {...toolkitProps.searchProps}
                        style={{ border: 'none' }}
                        placeholder={"Buscar..."}
                      />
                      <span className="select2-selection__indicator-separator css-1okebmr-indicatorSeparator"></span>
                      <div className="select2-selection__indicator select2-selection__dropdown-indicator css-tlfecz-indicatorContainer align-items-center" aria-hidden="true">
                        <span className="bx bx-search-alt text-black" onClick={() => { }}></span>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
                <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1 mt-5">
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"id"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          rowClasses={"table-row"}
                          headerClasses={"table-header align-middle"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          data-editable-emptytext="Default empty text."
                          cellEdit={
                            editing &&
                            cellEditFactory({
                              mode: "click",
                              blurToSave: true,
                              afterSaveCell: (oldValue, newValue, row, column) => {
                                editRow(oldValue, newValue, row, column);
                              }
                            })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="inner-custom-pagination d-flex">
                      {/* <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </div> */}
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </Row>
              </Card>

            </React.Fragment>
          )
          }
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
}

export default Table;
