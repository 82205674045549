import React from 'react';
import classnames from "classnames";
import { Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

const VideoStep = ({ step }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* Mobile */}
      <Row className="wizard mt-0 d-lg-none">
        <div className="timeline">
          <div className="tcontainer tleft">
            <p className="m-0 text-red" style={{ height: "39px" }}>{t("VideoNew.Step8.ModalVideoStructure.video_step.1")}</p>
          </div>
          <br />
          <div className="tcontainer tright">
            <p className="m-0 text-red" style={{ height: "39px" }}>{t("VideoNew.Step8.ModalVideoStructure.video_step.2")}</p>
          </div>
          <br />
          <div className="tleft tcontainer-black">
            <p className="m-0 text-black" style={{ height: "39px" }}>{t("VideoNew.Step8.ModalVideoStructure.video_step.3")}</p>
          </div>
          <br />
          <div className="tcontainer tright">
            <p className="m-0 text-red" style={{ height: "39px" }}>{t("VideoNew.Step8.ModalVideoStructure.video_step.4")}</p>
          </div>
        </div>
      </Row>

      {/* Desktop */}
      <Row className="wizard mt-0 mx-3 d-lg-flex d-sm-none d-none">
        <Col className="col-sm-3 col-md-3 d-flex pe-0" style={{ height: 13 }}>
          <div className="" style={{ display: "inline-block" }}>
            <span className="number m-0 bg-color-primary"></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center d-flex p-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className="number m-0 bg-color-primary"></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center d-flex p-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className="number m-0 bg-color-black"></span>
          </div>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
        </Col>
        <Col className="col-sm-3 col-md-3 text-end d-flex ps-0" style={{ height: 13 }}>
          <p className="line m-0 align-self-center bg-color-wizard-gray"></p>
          <div className="text-center" style={{ display: "inline-block" }}>
            <span className="number m-0 bg-color-primary"></span>
          </div>
        </Col>
      </Row>
      <Row className="wizard d-lg-flex d-sm-none d-none">
        <Col className="col-sm-3 col-md-3">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className="m-0 text-red">{t("VideoNew.Step8.ModalVideoStructure.video_step.1")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className="m-0 text-red">{t("VideoNew.Step8.ModalVideoStructure.video_step.2")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-center">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className="m-0 text-black">{t("VideoNew.Step8.ModalVideoStructure.video_step.3")}</p>
          </div>
        </Col>
        <Col className="col-sm-3 col-md-3 text-end">
          <div className="text-center" style={{ display: "inline-block" }}>
            <p className="m-0 text-red">{t("VideoNew.Step8.ModalVideoStructure.video_step.4")}</p>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default VideoStep;
