import {
  clientList,
  clientGet,
  clientAdd,
  clientUpdate,
  clientDelete
} from 'routes/api/paths';
import { headers } from 'config/headers';

/**
 * @description List all client
 * @param {*} access_token
 * @returns clients
 */
export const ClientsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(clientList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get client by id
 * @param {*} access_token
 * @param {*} id
 * @returns 
 */
export const ClientGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${clientGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add client
 * @param {*} client
 * @param {*} token
 * @returns client
 */
export const ClientAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.client),
  }
  return fetch(clientAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update client
 * @param {*} client
 * @param {*} token
 * @returns client
 */
export const ClientUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.client),
  }
  console.log(requestOptions);
  return fetch(clientUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete client
 * @param {*} client_id
 * @param {*} token
 * @returns client
 */
export const ClientDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      client_id: params.id
    }),
  }
  return fetch(clientDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}