import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import StatisticCard from "components/views/Statistic/components/StatisticCard";
import VideoCard from 'components/views/Utils/VideoCard';
import Header from 'components/views/Utils/Header';
// services
import { StatisticsGet } from 'store/statistics/StatisticsService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
// routes
import { COMAPANY, COMAPANY_OUTPUTS } from "routes/server/paths";

const StatisticsDetail = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { video } = useParams();
  const queryClient = useQueryClient();
  const [statistics, setStatistics] = useState(null);

  useEffect( async () => {
    const params = {
      token: user.access_token,
      id: video
    }
    const fetchStatistics = await queryClient.fetchQuery([params], StatisticsGet);
    if (fetchStatistics.status === 200) {
      setStatistics(fetchStatistics.data.data);
    }
  }, []);

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("StatisticsDetail.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("StatisticsDetail.title")} />
            <Card className="card-container shadow-none">
              <Row className="g-0 mb-5 justify-content-center col-md-10 offset-md-1">
                {
                  statistics &&
                  <>
                    <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                      <VideoCard name={statistics.video.title} date={statistics.video.created_at} srcVideo={`${COMAPANY}${statistics.video.user.company_id}${COMAPANY_OUTPUTS}${statistics.video.video}`} />
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-3">
                      <StatisticCard title={t("StatisticsDetail.open")} data={statistics.amount_opend} />
                      <StatisticCard title={t("StatisticsDetail.click")} data={statistics.amount_cta} />
                      <StatisticCard title={t("StatisticsDetail.download")} data={statistics.amount_download > 0 ? "Sí" : "No"} />
                    </Col>
                  </>
                }
              </Row>
            </Card>
            <div className="mt-3 mb-4 text-center">
              <Button onClick={() => history.goBack()} className="btn-shadow btn-general bg-color-darkgray color-white" color="app-green">← {t("VideoNew.back")}</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StatisticsDetail;