import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Container, Row, Col, Card, CardBody, Button, Spinner, Modal } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useTranslation } from 'react-i18next';
import Select from "react-select";

// components
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ModalCategoryAdd from "components/views/Clip/ModalCategoryAdd";
import ModalLoading from "components/views/Utils/ModalLoading";
// services
import { ClipAdd, ClipCategoriesUpdate } from 'store/clip/ClipService';
import { CategoriesList } from 'store/category/CategoryService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";
import useFormatBytes from "hooks/useFormatBytes";

const ModalClipNew = ({ showModal, setShowModal, toggleModal }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [loadingVideo, setLoadingVideo] = useState(false);
  const [formRef, setFormRef] = useState(false);
  const [showModalCategory, setShowModalCategory] = useState(null);
  const { getRootProps, getInputProps, open, acceptedFiles, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/png",
    maxFiles: 1
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const products = [
    {
      options: [
        { label: "VVV", value: "1", key: "1" },
        { label: "Danngos", value: "2", key: "2" }
      ]
    }
  ];

  const categories1 = [
    {
      options: [
        { label: "VVV", value: "1", key: "1" },
        { label: "Danngos", value: "2", key: "2" }
      ]
    }
  ];

  useEffect(() => {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_image"));
      return;
    }
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];
      const image = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
      setImage(image);
    }
  }, [acceptedFiles]);

  const queryCategoriesList = useQuery(['CATEGORIES', { token: user.access_token }], CategoriesList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.category.error.load_all"));
    },
    onSuccess: (data) => {
      setCategories(data.data.data);
    }
  });

  const mutationClipAdd = useMutation('CREATE_CLIP', ClipAdd, {
    onError: (error) => {
      setLoading(false);
      // setLoadingVideo(false);
      useToast("error", t("Message.error"), t("Message.clip.error.add"));
    },
    onSuccess: (data) => {
      if (selectedCategories.length > 0) {
        const clip = data.data.data;
        const info = {
          category_id: selectedCategories,
          clip_id: clip.id
        }
        mutationClipCategoriesUpdate.mutate({ token: user.access_token, info });
      } else {
        setLoading(false);
        // setLoadingVideo(false);
        setVideo(null);
        setImage(null);
        formRef.reset();
        setSelectedCategories([]);
        useToast("success", t("Message.info"), t("Message.clip.success.add"));
        queryClient.refetchQueries(['CLIPS', { token: user.access_token }]);
        setShowModal(false);
      }
    }
  });

  const mutationClipCategoriesUpdate = useMutation('UPDATE_CLIP_CATEGORY', ClipCategoriesUpdate, {
    onError: (error) => {
      setLoading(false);
      // setLoadingVideo(false);
      useToast("error", t("Message.error"), t("Message.clip.error.add"));
    },
    onSuccess: (data) => {
      setLoading(false);
      // setLoadingVideo(false);
      setVideo(null);
      setImage(null);
      formRef.reset();
      setSelectedCategories([]);
      useToast("success", t("Message.info"), t("Message.clip.success.add"));
      queryClient.refetchQueries(['CLIPS', { token: user.access_token }]);
      setShowModal(false);
    }
  });

  function toggleModalCategory() {
    setShowModalCategory(!showModalCategory);
    document.body.classList.add("no_padding");
  }

  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      useToast("error", t("Message.error"), t("Message.drop.error.single_video"));
      return;
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: useFormatBytes(file.size),
      })
    )
    setVideo(files[0]);
  }

  function handleCheckboxChange(event) {
    // console.log(event.target.id);
    if (selectedCategories.includes(event.target.id)) {
      const newArray = selectedCategories.filter(e => e !== event.target.id);
      setSelectedCategories(newArray);
    } else {
      const newArray = [...selectedCategories, parseInt(event.target.id)];
      setSelectedCategories(newArray);
    }
  };

  function handleSubmit(event, errors, values) {
    event.preventDefault();

    if (errors.length > 0) {
      return;
    }

    if (video == null) {
      useToast("error", t("Message.alert"), t("Message.clip.error.load_video"));
      return;
    }

    // if (image == null) {
    //   useToast("error", t("Message.alert"), t("Message.clip.error.load_image"));
    //   return;
    // }

    setLoading(true);
    // setLoadingVideo(true);

    const data = new FormData();
    data.append("clip", video);
    data.append("thumbnail", image);
    data.append("title", values.name);
    data.append("description", "");

    if (document.getElementById('video_player').videoHeight > document.getElementById('video_player').videoWidth) {
      data.append("portrait", 1);
    } else {
      data.append("portrait", 0);
    }

    mutationClipAdd.mutate({ token: user.access_token, clip: data });
  }

  function handleSelectProduct(selectedProduct) {
    setSelectedProduct(selectedProduct);
    // setList(useSort(selectedGroup.key, list, selectedGroup.inverse, inside));
  }

  function handleSelectCategory(selectedCategory) {
    setSelectedCategory(selectedCategory);
    // setList(useSort(selectedGroup.key, list, selectedGroup.inverse, inside));
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Header title={t("ClipNew.title")} modal={true} />
          <Container fluid className="content">
            <Row>
              <Col className="col-sm-12 col-md-12 offset-md-0">
                <Card className="card-container shadow-none">
                  <Row className="g-0 justify-content-evenly mt-4">
                    <Col className="col-12 col-sm-12 col-md-5">
                      {
                        video ?
                          <div className="col-lg-12 col-md-12 col-sm-12 mb-2" id="outer" style={{ padding: 0 }}>
                            <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                              <i
                                onClick={() => setVideo(null)}
                                className="mdi mdi-close circle-video"
                                style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                            </div>
                            <video id="video_player" src={video.preview} className="video" controls width="100%" height="300px"></video>
                          </div>
                        :
                          <Dropzone
                            maxFiles={1}
                            accept="video/mp4"
                            onDrop={(acceptedFiles, fileRejections) => {
                              handleAcceptedFiles(acceptedFiles, fileRejections)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{t("Message.drop.drag_video")}</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                      }
                    </Col>
                    <Col className="col-12 col-sm-12 col-md-5">
                      <AvForm className="form-horizontal" onSubmit={handleSubmit.bind(this)} ref={(r) => setFormRef(r)}>
                        <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                          <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.name")}</h5>
                          <CardBody>
                            <Row>
                              <Col className="col-12">
                                <div className="mb-4">
                                  <AvField
                                    name="name"
                                    className="form-control"
                                    placeholder={t("Placeholder.name")}
                                    type="text"
                                    validate={{
                                      required: { value: true, errorMessage: t("Validation.name") },
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                          <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.categories")}</h5>
                          <CardBody>
                            <Row>
                              {
                                queryCategoriesList.isLoading &&
                                <Loading style="list" />
                              }
                              <Row className="g-0 justify-content-center mb-2">
                                <Col className="col-12 col-sm-12 col-md-12 ps-1 mb-1">
                                  {/* <Sort list={list} setList={setList} inside={inside}/> */}
                                  <Card className="shadow-none mb-0 bg-transparent">
                                    {/* <CardBody> */}
                                    <Select
                                      value={selectedProduct}
                                      placeholder={t("Placeholder.products")}
                                      onChange={(e) => {
                                        handleSelectProduct(e)
                                      }}
                                      options={products}
                                      classNamePrefix="select2-selection"
                                    />
                                    {/* </CardBody> */}
                                  </Card>
                                </Col>
                                <Col className="col-12  col-sm-12 col-md-12 ps-1">
                                  {/* <Search list={originalList} setList={setList} setInputRef={setInputRef} inside={inside} /> */}
                                  <Card className="shadow-none mb-0 bg-transparent">
                                    {/* <CardBody> */}
                                    <Select
                                      value={selectedCategory}
                                      placeholder={t("Placeholder.categories")}
                                      onChange={(e) => {
                                        handleSelectCategory(e)
                                      }}
                                      options={categories1}
                                      classNamePrefix="select2-selection"
                                    />
                                    {/* </CardBody> */}
                                  </Card>
                                </Col>
                              </Row>
                              {/* {
                                categories?.length > 0 ?
                                  categories.map((e, i) => {
                                    return (
                                      <Col className="col-12">
                                        <div className="mb-4 d-flex align-items-center">
                                          <input
                                            id={e.id}
                                            type="checkbox"
                                            style={{ height: 25, width: 25, float: "right" }}
                                            className="form-clip-check mt-0"
                                            name={e.id}
                                            onClick={(event) => handleCheckboxChange(event)}
                                            value={e.id} />
                                          <p className="mb-0 MontSemiBold ms-2">{e.name}</p>
                                        </div>
                                      </Col>
                                    );
                                  })
                                  :
                                  <Col className="col-12 col-sm-12 col-md-12 bg-color-white d-flex p-2 justify-content-center">
                                    <Button onClick={() => toggleModalCategory()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("ClipNew.add_category")}</Button>
                                  </Col>
                              } */}
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="mb-0 shadow-lg border-bottom border-top mb-4">
                          <h5 className="MontSemiBold card-header bg-color border-top text-center">{t("ClipNew.image")}</h5>
                          <CardBody>
                            {
                              image ?
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-2" style={{ padding: 0 }}>
                                  <div style={{ position: "relative", top: 30, float: "right", left: 15, zIndex: 2 }}>
                                    <i
                                      onClick={() => setImage(null)}
                                      className="mdi mdi-close circle-video"
                                      style={{ fontSize: 32, backgroundColor: "#c55654", color: "white", cursor: "pointer" }}></i>
                                  </div>
                                  <img
                                    style={{ width: '100%' }}
                                    className="bg-light"
                                    alt={image.name}
                                    src={image.preview}
                                  />
                                </div>
                                :
                                <Row className="justify-content-center">
                                  <Col className="col-12 col-sm-12 col-md-8 border-dashed border-bottom border-top m-3 bg-color-white d-flex p-5 justify-content-center align-items-center">
                                    <div className="text-center">
                                      <input {...getInputProps()} />
                                      <Button onClick={() => open()} style={{ height: "auto" }} className="btn-shadow btn-table-option bg-color-gray text-decoration-none text-black" color="app-green">{t("PresentationNew.select")}</Button>
                                    </div>
                                  </Col>
                                </Row>
                            }
                          </CardBody>
                        </Card>
                        <div className="mb-5 text-center">
                          <Button onClick={() => { }} className="btn-general-black bg-color-white m-3 border-bottom-black-1 border-radius-0" color="app-green">{t("ClipNew.cancel")}</Button>
                          <Button className="btn-shadow btn-general bg-color-green" color="app-green">
                            <Spinner
                              style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                              as="span"
                              variant="light"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="border" />
                            {t("ClipNew.save")}
                          </Button>
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <ModalLoading
                title={t("Message.video.loading.title")}
                subtitle={t("Message.video.loading.subtitle")}
                showModal={loading}
              />
              <ModalCategoryAdd
                showModal={showModalCategory}
                setShowModal={setShowModalCategory}
                toggleModal={toggleModalCategory} />
            </Row>
          </Container>
        </div>
      </div>
    </Modal>
  );
}

export default ModalClipNew;
