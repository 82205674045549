import {
  videoList,
  videoUpdate,
  videoEdit,
  videoDelete,
  videoReuse,
  videoToken,
  statisticsGet
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description List all clips
 * @param {*} access_token
 * @returns clips
 */
export const VideosList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(videoList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Create video
 * @param {*} video
 * @param {*} token
 * @returns video
 */
export const VideoAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.data,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(params.url, requestOptions)
  // return fetch(videoAddMultiple, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update video title
 * @param {*} video id
 * @param {*} token
 * @returns user
 */
export const VideoUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.data),
  }
  return fetch(videoUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Edit video
 * @param {*} data
 * @param {*} token
 * @returns video
 */
export const VideoEdit = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.data,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(videoEdit, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete video
 * @param {*} video_id
 * @param {*} token
 * @returns 
 */
export const VideoDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      id: params.id
    }),
  }
  return fetch(videoDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update video title
 * @param {*} videoId
 * @param {*} token
 * @returns user
 */
export const VideoReuse = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[0].token),
  }
  return fetch(`${videoReuse}/${params.queryKey[0].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update video title
 * @param {*} videoId
 * @param {*} token
 * @returns user
 */
export const VideoToken = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[0].token),
  }
  return fetch(`${videoToken}/${params.queryKey[0].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}