import {
  userList,
  userListCompany,
  userGet,
  userAdd,
  userUpdate,
  userDelete
} from 'routes/api/paths';
import { headers } from 'config/headers';

/**
 * @description Users list
 * @param {*} access_token
 * @returns users
 */
export const UsersList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(userList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Users list by company
 * @param {*} access_token
 * @returns users
 */
export const UsersListCompany = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(userListCompany, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get user by id
 * @param {*} access_token
 * @param {*} id
 * @returns user
 */
export const UserGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${userGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add user
 * @param {*} user
 * @param {*} token
 * @returns user
 */
export const UserAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(params.token),
    body: JSON.stringify(params.user),
  }
  return fetch(userAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update user
 * @param {*} user
 * @param {*} token
 * @returns user
 */
export const UserUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.data),
  }
  console.log(requestOptions);
  return fetch(userUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete user
 * @param {*} user
 * @param {*} token
 * @returns user
 */
export const UserDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      user_id: params.id
    }),
  }
  return fetch(userDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}