import React from 'react';
import { useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

const Header = ({ title, subtitle="", subClass=null, back=false, modal=false }) => {
  const history = useHistory();

  return (
    <Row>
      {
        back &&
        <Col className="col-sm-12 col-lg-2 col-12 text-center align-self-center p-sm-4 position-absolute-lg">
          <button type="button" onClick={() => history.goBack()} style={{ zIndex: 1000 }} className="btn btn-shadow bg-color-white position-relative p-0 avatar-xs rounded-circle mt-3">
            <span className="avatar-title bg-transparent text-reset">
              <i className="bx bx-chevron-left" style={{ fontSize: 24 }}></i>
            </span>
          </button>
        </Col>
      }
      <Col className="col-sm-12 col-lg-12 col-12 text-center align-self-center p-sm-4">
        <h3
          className={classnames(
            "MontSemiBold",
            "lh-1",
            "text-black",
            "text-center",
            !modal && "pt-5",
            "mb-4"
          )}>
          {
            title.split('\n').map((text, i) => {
              return (<>{text}<br/></>)
            })
          }
        </h3>
        {
          subtitle != "" && 
          <h5 className={`text-black text-center mb-2 ${subClass && subClass}`}>
            {
              subtitle.split('\n').map((text, i) => {
                return (<>{text}<br/></>)
              })
            }
          </h5>
        }
      </Col>
    </Row>
  );
}

export default Header;
