import React from "react";
import { Redirect } from "react-router-dom";

import {
  HOME,
  DASHBOARD,
  LOGIN,
  REGISTER,
  REGISTER_SUCCESS,
  VALIDATE_EMAIL,
  LOGOUT,
  PASSWORD_RECOVERY,
  PASSWORD_CHANGE,
  PROFILE,
  TUTORIAL,
  VIDEOS,
  VIDEO_NEW,
  PRESENTATIONS,
  PRESENTATION_NEW,
  CLIENTS,
  CLIENT_NEW,
  CLIENT_DETAIL_ID,
  STATISTICS,
  STATISTIC_DETAIL_ID,
  CLIPS,
  CLIP_NEW,
  USERS,
  USERS_ADMIN,
  USER_NEW,
  USER_DETAIL_ID,
  CATEGORIES,
  CATEGORY_DETAIL_ID,
  SUBCATEGORY_DETAIL_ID,
  TEMPLATES,
  TEMPLATE_NEW,
  COMPANIES,
  COMPANY_NEW,
  PRODUCTS,
  PRODUCT_NEW,
  LANDING_TOKEN,
  LANDING_PREVIEW
} from 'routes/app/paths';

// AUTH
import Login from 'components/views/Auth/Login';
import Register from 'components/views/Auth/Register';
import RegisterSuccess from 'components/views/Auth/RegisterSuccess';
import Logout from 'components/views/Auth/Logout';
import PasswordRecovery from 'components/views/Auth/PasswordRecovery';
import PasswordChange from 'components/views/Auth/PasswordChange';
import ValidateEmail from 'components/views/Auth/ValidateEmail';

// DASHBOARD
import Dashboard from 'components/views/Dashboard';

// PROFILE
import Profile from 'components/views/Profile/Profile';

// TUTORIAL
import Tutorial from 'components/views/Tutorial/Tutorial';

// VIDEOS
import Videos from 'components/views/Video/Videos';
import VideoNew from 'components/views/Video/VideoNew';

// PRESENTATIONS
import Presentations from 'components/views/Presentation/Presentations';
import PresentationNew from 'components/views/Presentation/PresentationNew';

// CLIENTS
import Clients from 'components/views/Client/Clients';
import ClientNew from 'components/views/Client/ClientNew';
import ClientDetail from 'components/views/Client/ClientDetail';

// STATISTIC
import Statistics from 'components/views/Statistic/Statistics';
import StatisticsDetail from 'components/views/Statistic/StatisticsDetail';

// CLIPS
import Clips from 'components/views/Clip/Clips';
import ClipNew from 'components/views/Clip/ClipNew';

// USERS
import Users from 'components/views/User/Users';
import UsersAdmin from 'components/views/User/UsersAdmin';
import UserNew from 'components/views/User/UserNew';
import UserDetail from 'components/views/User/UserDetail';

// CATEGORIES
import Categories from 'components/views/Category/Categories';
import CategoryDetail from 'components/views/Category/CategoryDetail';

// // SUBCATEGORY
import SubCategoryDetail from 'components/views/Category/SubCategoryDetail';

// TEMPLATES
import Templates from 'components/views/Template/Templates';
import TemplateNew from 'components/views/Template/TemplateNew';

// COMPANIES
import Companies from 'components/views/Company/Companies';
import CompanyNew from 'components/views/Company/CompanyNew';

// PRODUCTS
import Products from 'components/views/Product/Products';
import ProductNew from 'components/views/Product/ProductNew';

// LANDING
import Landing from 'components/views/Landing/Landing';
import LandingPreview from 'components/views/Landing/LandingPreview';

const authProtectedRoutes = [
  { path: DASHBOARD, component: Dashboard },
  { path: PROFILE, component: Profile },
  { path: TUTORIAL, component: Tutorial },
  { path: VIDEOS, component: Videos },
  { path: VIDEO_NEW, component: VideoNew },
  { path: PRESENTATIONS, component: Presentations },
  { path: PRESENTATION_NEW, component: PresentationNew },
  { path: CLIENTS, component: Clients },
  { path: CLIENT_NEW, component: ClientNew },
  { path: CLIENT_DETAIL_ID, component: ClientDetail },
  { path: STATISTICS, component: Statistics },
  { path: STATISTIC_DETAIL_ID, component: StatisticsDetail },
  { path: CLIPS, component: Clips },
  { path: CLIP_NEW, component: ClipNew },
  { path: USERS, component: Users },
  { path: USERS_ADMIN, component: UsersAdmin },
  { path: USER_NEW, component: UserNew },
  { path: USER_DETAIL_ID, component: UserDetail },
  { path: CATEGORIES, component: Categories },
  { path: CATEGORY_DETAIL_ID, component: CategoryDetail },
  { path: SUBCATEGORY_DETAIL_ID, component: SubCategoryDetail },
  { path: TEMPLATES, component: Templates },
  { path: TEMPLATE_NEW, component: TemplateNew },
  { path: COMPANIES, component: Companies },
  { path: COMPANY_NEW, component: CompanyNew },
  { path: PRODUCTS, component: Products },
  { path: PRODUCT_NEW, component: ProductNew },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: HOME, exact: true, component: () => <Redirect to={DASHBOARD} /> },
]

const publicRoutes = [
  { path: LOGIN, component: Login },
  { path: REGISTER, component: Register },
  { path: REGISTER_SUCCESS, component: RegisterSuccess },
  { path: VALIDATE_EMAIL, component: ValidateEmail },
  { path: LOGOUT, component: Logout },
  { path: PASSWORD_RECOVERY, component: PasswordRecovery },
  { path: PASSWORD_CHANGE, component: PasswordChange },
  { path: LANDING_TOKEN, component: Landing },
  { path: LANDING_PREVIEW, component: LandingPreview },
]

export { authProtectedRoutes, publicRoutes }
