import React, { useState } from 'react';
import { useQueryClient, useMutation } from "react-query";
import { Row, Col, Modal, Button, Input, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import Header from 'components/views/Utils/Header';
import VideoStep from "components/views/Video/components/Setting/components/Modals/VideoStep";
// hook
import useAuthContext from 'hooks/useAuthContext';
// config
import { encryptStorage } from 'config/encryptStorage';

const VIDEO_STRUCTURE_MODAL = 'VIDEO_STRUCTURE_MODAL';

const ModalVideoStructure = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }

  function handleNotShowAgain() {
    encryptStorage.setItem(VIDEO_STRUCTURE_MODAL, true);
    setShowModal(!showModal)
  }

  return (
    <Modal
      size="md"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Header
                modal={true}
                title={t("VideoNew.Step8.ModalVideoStructure.title")}
                subtitle={t("VideoNew.Step8.ModalVideoStructure.subtitle")} />
              <Row className="g-0 justify-content-center">
                <Col className="col-12 col-sm-12 col-md-10 justify-content-center" >
                  <VideoStep />
                </Col>
              </Row>
              <div className="text-center">
                <Button onClick={() => handleNotShowAgain()} className="btn-shadow btn-general bg-color-primary m-3" color="app-green">
                  {t("VideoNew.Step8.ModalVideoStructure.not_show")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}

export default ModalVideoStructure;