import React from 'react';
import ContentLoader from "react-content-loader"
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

const Table = ({ props }) => {
  return (
    <ContentLoader
      speed={2}
      style={{ width: '100%', marginTop: 20}}
      height={230}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect style={{ width: '100%' }} x="0" y="8" rx="3" ry="3" width="124" height="33" />
      <rect style={{ width: '100%' }} x="0" y="56" rx="3" ry="3" height="6" />
      <rect style={{ width: '100%' }} x="0" y="76" rx="3" ry="3" height="6" />
      <rect style={{ width: '100%' }} x="0" y="96" rx="3" ry="3" height="6" />
      <rect style={{ width: '100%' }} x="0" y="116" rx="3" ry="3" height="6" />
      <rect style={{ width: '100%' }} x="0" y="136" rx="3" ry="3" height="6" />
      <rect style={{ width: '100%' }} x="0" y="156" rx="3" ry="3" height="6" />
    </ContentLoader>
  );
}

const Video = ({ props }) => {
  return (
    <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
      <Col className="col-12 col-sm-12 col-md-3">
        <ContentLoader
          speed={2}
          style={{ width: '100%', marginTop: 20 }}
          height={230}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="8" rx="3" ry="3" width="124" height="50" />
          <rect x="6" y="65" rx="3" ry="3" width="110" height="90" />
          <rect x="93" y="165" rx="3" ry="3" width="20" height="20" />
        </ContentLoader>
      </Col>
      <Col className="col-12 col-sm-12 col-md-3">
        <ContentLoader
          speed={2}
          style={{ width: '100%', marginTop: 20 }}
          height={230}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="8" rx="3" ry="3" width="124" height="50" />
          <rect x="6" y="65" rx="3" ry="3" width="110" height="90" />
          <rect x="93" y="165" rx="3" ry="3" width="20" height="20" />
        </ContentLoader>
      </Col>
      <Col className="col-12 col-sm-12 col-md-3">
        <ContentLoader
          speed={2}
          style={{ width: '100%', marginTop: 20 }}
          height={230}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="8" rx="3" ry="3" width="124" height="50" />
          <rect x="6" y="65" rx="3" ry="3" width="110" height="90" />
          <rect x="93" y="165" rx="3" ry="3" width="20" height="20" />
        </ContentLoader>
      </Col>
      <Col className="col-12 col-sm-12 col-md-3">
        <ContentLoader
          speed={2}
          style={{ width: '100%', marginTop: 20 }}
          height={230}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="8" rx="3" ry="3" width="124" height="50" />
          <rect x="6" y="65" rx="3" ry="3" width="110" height="90" />
          <rect x="93" y="165" rx="3" ry="3" width="20" height="20" />
        </ContentLoader>
      </Col>
    </Row>
  );
}

const List = ({ props }) => {
  return (
    <ContentLoader
      speed={2}
      style={{ width: '100%', marginTop: 10 }}
      height={130}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="8" rx="3" ry="3" width="20" height="20" />
      <rect style={{ width: '80%' }} x="35" y="14" rx="3" ry="3" height="6" />
      <rect x="0" y="38" rx="3" ry="3" width="20" height="20" />
      <rect style={{ width: '80%' }} x="35" y="44" rx="3" ry="3" height="6" />
      <rect x="0" y="68" rx="3" ry="3" width="20" height="20" />
      <rect style={{ width: '80%' }} x="35" y="74" rx="3" ry="3" height="6" />
      <rect x="0" y="98" rx="3" ry="3" width="20" height="20" />
      <rect style={{ width: '80%' }} x="35" y="104" rx="3" ry="3" height="6" />
    </ContentLoader>
  )
}

const Loading = ({ style="table" }) => {
  switch (style) {
    case "table":
      return <Table />;
    case "video":
      return <Video />;
    case "list":
      return <List />;
    default:
      break;
  }
}

export default Loading;
