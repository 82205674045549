import {
  presentationList,
  presentationGet,
  presentationAdd,
  presentationUpdate,
  presentationDelete
} from 'routes/api/paths';
import { headers, headersFile } from 'config/headers';

/**
 * @description List all clips
 * @param {*} access_token
 * @returns clips
 */
export const PresentationsList = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(presentationList, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Get clip by id
 * @param {*} access_token
 * @param {*} id
 * @returns 
 */
export const PresentationGet = async (params) => {
  const requestOptions = {
    method: 'GET',
    headers: headers(params.queryKey[1].token)
  }
  return fetch(`${presentationGet}/${params.queryKey[1].id}`, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 200) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Add clip
 * @param {*} clip
 * @param {*} token
 * @returns clip
 */
export const PresentationAdd = async (params) => {
  const requestOptions = {
    method: 'POST',
    headers: headersFile(params.token),
    body: params.presentation,
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  }
  return fetch(presentationAdd, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Update presentation
 * @param {*} presentation
 * @param {*} token
 * @returns presentation
 */
export const PresentationUpdate = async (params) => {
  const requestOptions = {
    method: 'PUT',
    headers: headers(params.token),
    body: JSON.stringify(params.data),
  }
  return fetch(presentationUpdate, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}

/**
 * @description Delete clip
 * @param {*} clip
 * @param {*} token
 * @returns clip
 */
export const PresentationDelete = async (params) => {
  const requestOptions = {
    method: 'DELETE',
    headers: headers(params.token),
    body: JSON.stringify({
      id: params.id
    }),
  }
  return fetch(presentationDelete, requestOptions)
    .then(async response => {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      const data = await response.json();
      if (response.status === 201) {
        return Promise.resolve({
          status: response.status,
          data: data
        });
      }
    })
    .catch(error => {
      return Promise.reject({
        status: error
      });
    });
}