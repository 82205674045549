import React, { useState, useRef } from 'react';
import { useMutation, useQueryClient } from "react-query";
import { Container, Row, Col, Modal, Card, Button, Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import EditionView from "components/views/Utils/Edition/components/EditionView";
import ToolBar from "components/views/Utils/Edition/components/ToolBar";
import Header from 'components/views/Utils/Header';
import ModalVideoName from "components/views/Utils/Edition/components/ModalVideoName";
// services
import { TemplateEdit } from 'store/template/TemplateService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import useDataURLtoFile from 'hooks/useDataURLtoFile';
import { useToast, useClearToasts } from "hooks/useToast";

const EditImage = ({ 
  image, 
  autoload = false, 
  setTemplate = () => {}, 
  showModal, 
  setShowModal, 
  toggleModal,
  templateSelected = null,
  handleCheckboxChange = () => { }
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [stageRef, setStageRef] = useState(null);
  const [title, setTitle] = useState(null);
  const [images, setImages] = useState([]);
  const [texts, setTexts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalName, setShowModalName] = useState(false);

  const mutationTemplateEdit = useMutation('EDIT_TEMPLATE', TemplateEdit, {
    onError: (error) => {
      setLoading(false);
      useClearToasts();
      useToast("error", t("Message.error"), t("Message.template.error.convert"));
    },
    onSuccess: (data) => {
      if (autoload) {
        const newTemplate = data.data.data;
        setTemplate(newTemplate);
      }
      setLoading(false);
      setTexts([]);
      setImages([]);
      useToast("success", t("Message.info"), t("Message.template.success.add"));
      queryClient.refetchQueries(['TEMPLATES', { token: user.access_token }]);
      setShowModal(false);
    }
  });

  function toggleModalName() {
    setShowModalName(!showModalName);
    document.body.classList.add("no_padding");
  }

  // const downloadURI = (uri, name) => {
  //   const link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  function handleFinish() {
    setShowModalName(false);
    const imageEdition = useDataURLtoFile(stageRef.toDataURL({
      quality: 1,
      pixelRatio: 4,
    }), "image");

    // downloadURI(stageRef.toDataURL({
    //   quality: 1,
    //   pixelRatio: 4,
    // }), "test");

    const data = new FormData();
    data.append("template_id", image.id);
    data.append("title", title);
    data.append("image", imageEdition);
    data.append("width", "1920");
    data.append("height", "1080");
    // data.append("width", document.getElementById('player').naturalWidth);
    // data.append("height", document.getElementById('player').naturalHeight);

    setLoading(true);
    useToast("warning", t("Message.info"), t("Message.template.success.wait"));
    mutationTemplateEdit.mutate({ token: user.access_token, data });
  }

  return (
    <Modal
      size="xl"
      isOpen={showModal}
      toggle={() => {
        toggleModal()
      }}
      // className="modal-fullscreen bg-color-gray"
      scrollable={true}
    >
      <div className="modal-content bg-color-gray">
        <div className="modal-header border-0">
          <button
            onClick={() => {
              setShowModal(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        <Header title={t("Edition.title_image")} modal={true} />
        <Container fluid className="content">
          <Row>
            <Col className="col-sm-12 col-md-12 offset-md-0">
              <Card className="card-container shadow-none">
                <Row className="g-0 col-md-12 offset-md-0">
                  <Col className="col-12 col-sm-12 col-md-8 bg-color-red d-flex p-2">
                    <EditionView 
                      setStageRef={setStageRef}
                      image={image?.url}
                      texts={texts}
                      setTexts={setTexts}
                      images={images}
                      setImages={setImages}
                    />
                  </Col>
                  <Col className="col-12 col-sm-12 col-md-4 bg-color-blue d-flex p-2">
                    <ToolBar
                      texts={texts}
                      setTexts={setTexts}
                      images={images}
                      setImages={setImages}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div className="mt-2 mb-4 text-center">
            <Button onClick={() => toggleModalName()} className="btn-shadow btn-general bg-color-primary" color="app-green">
              <Spinner
                style={{ marginRight: 10, display: loading ? 'inline-block' : 'none' }}
                as="span"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
                animation="border" />
              {t("Edition.edit_image")}
            </Button>
          </div>
        </Container>
        </div>
      </div>
      <ModalVideoName
        name={title}
        setName={setTitle}
        handleFinish={handleFinish}
        showModal={showModalName}
        setShowModal={setShowModalName}
        toggleModal={toggleModalName}
        isVideo={false} />
    </Modal>
  );
}

export default EditImage;
