import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Container, Row, Col, Card, Button, Badge } from "reactstrap";
import { useTranslation } from 'react-i18next';

// components
import VideoCard from 'components/views/Utils/VideoCard';
import EditVideo from 'components/views/Utils/Edition/EditVideo';
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
import ToolBar from 'components/views/Utils/ToolBar/ToolBar';
import ModalDeleteWithText from "components/views/Utils/ModalDeleteWithText";
import ModalEditName from "components/views/Utils/ModalEditName";
// routes
import { PRESENTATION_NEW } from 'routes/app/paths';
import { COMAPANY, COMAPANY_VIDEOS } from "routes/server/paths";
// services
import { PresentationsList, PresentationUpdate, PresentationDelete } from 'store/presentation/PresentationService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Presentations = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [video, setVideo] = useState(null);
  const [inputSearchRef, setInputSearchRef] = useState(null);
  const [originalList, setOriginalList] = useState(null);
  const [presentations, setPresentations] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdateName, setShowModalUpdateName] = useState(false);
  const [videoDelete, setVideoDelete] = useState(null);
  const [videoEdit, setVideoEdit] = useState(null);

  const queryPresentationList = useQuery(['PRESENTATIONS', { token: user.access_token }], PresentationsList, {
    refetchOnWindowFocus: false,
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.video.error.load_all"));
    },
    onSuccess: (data) => {
      setPresentations(data.data.data);
      setOriginalList(data.data.data);
    }
  });

  const mutationPresentationUpdate = useMutation('PRESENTATIONS_UPDATE', PresentationUpdate, {
    onError: (error) => {
      // setLoading(false);
      setVideoEdit(null);
      if (error.status === 405) {
        setShowModalUpdateName(!showModalUpdateName);
        useToast("error", t("Message.error"), t("Message.auth.error.access_denied"));
        return;
      }
      useToast("error", t("Message.error"), t("Message.video.error.update"));
    },
    onSuccess: (data) => {
      // setLoading(false);
      useToast("success", t("Message.info"), t("Message.video.success.update"));
      queryClient.refetchQueries(['PRESENTATIONS', { token: user.access_token }]);
      setShowModalUpdateName(!showModalUpdateName);
      setVideoEdit(null);
    }
  });

  const mutationPresentationDelete = useMutation('DELETE_PRESENTATION', PresentationDelete, {
    onError: (error) => {
      // setLoading(false);
      useToast("error", t("Message.error"), t("Message.video.error.delete"));
      setShowModalDelete(!showModalDelete);
    },
    onSuccess: (data) => {
      if (inputSearchRef.value != "") {
        inputSearchRef.value = "";
      }
      useToast("success", t("Message.info"), t("Message.video.success.delete"));
      queryClient.refetchQueries(['PRESENTATIONS', { token: user.access_token }]);
      setShowModalDelete(!showModalDelete);
    }
  });

  function toggleModal() {
    setShowModal(!showModal);
    document.body.classList.add("no_padding");
  }
  
  function toggleModalDelete() {
    setShowModalDelete(!showModalDelete);
    document.body.classList.add("no_padding");
  }

  function handleEditVideo(id, name, url) {
    setVideo({
      id,
      name,
      url
    });
    setShowModal(!showModal);
  }

  function handleChangeNameVideo(video) {
    setVideoEdit(video);
    setShowModalUpdateName(!showModalUpdateName);
  }

  function handleDelete(id) {
    setVideoDelete(id);
    toggleModalDelete();
  }

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("Presentation.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("Presentation.title")} />
            <ToolBar originalList={originalList} list={presentations} setList={setPresentations} setInputRef={setInputSearchRef} />
            <div className="mt-4 text-center">
              <Button onClick={() => history.push(PRESENTATION_NEW)} className="btn-shadow btn-general bg-color-primary border-none" color="app-green">{t("Presentation.new")}</Button>
            </div>
            <Card className="card-container shadow-none mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}>
              <Row className="g-0 mb-4 justify-content-center col-md-10 offset-md-1">
                {
                  queryPresentationList.isLoading &&
                  <Loading style="video" />
                }
                {
                  presentations &&
                  presentations.map((e, i) => {
                    console.log(e);
                    return (
                      <Col className="col-12 col-sm-12 col-md-3 shadow-lg border-bottom border-top m-3 bg-color-white" style={{ maxWidth: 200 }}>
                        <VideoCard name={e.title} date={e.created_at} srcVideo={`${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`} thumbnail={(e.thumbnail === null || e.thumbnail === 'null') ? null : `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.thumbnail}`} />
                        <Row className="mb-2 justify-content-between">
                          <Col className="col-md-5 text-center">
                            <i className="mdi mdi-pencil-outline ms-3" onClick={() => handleChangeNameVideo(e)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                            <i className="mdi mdi-movie-edit-outline ms-1" onClick={() => handleEditVideo(e.id, e.title, `${COMAPANY}${e.user.company_id}${COMAPANY_VIDEOS}${e.video}`)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                          </Col>
                          <Col className="col-md-5 text-center">
                            <i className="mdi mdi-trash-can-outline" onClick={() => handleDelete(e.id)} style={{ fontSize: 24, cursor: "pointer" }}></i>
                          </Col>
                        </Row>
                        <div className="text-center mb-2">
                          <Badge
                            className="badge-soft-success"
                            pill
                          >
                            <div className="text-center d-flex ">
                              <p className="mb-0 ms-1 pt-1" style={{ fontSize: 15, color: "black" }}>{e.type === "Open" ? t("Presentation.open") : t("Presentation.close")}</p>
                            </div>
                          </Badge>
                        </div>
                      </Col>
                    )
                  })
                }
              </Row>
            </Card>
          </Col>
        </Row>
        <EditVideo
          video={video}
          showModal={showModal}
          setShowModal={setShowModal}
          toggleModal={toggleModal} />
        <ModalDeleteWithText
          showModal={showModalDelete}
          setShowModal={setShowModalDelete}
          dataDelete={videoDelete}
          mutation={mutationPresentationDelete}
          model={t("Presentation.the_presentation")} />
        <ModalEditName
          video={videoEdit}
          showModal={showModalUpdateName}
          setShowModal={setShowModalUpdateName}
          mutation={mutationPresentationUpdate} />
      </Container>
    </div>
  );
}

export default Presentations;
