import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, Badge, Button } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from 'react-i18next';

// components
import Table from "components/views/Utils/Table";
import Header from 'components/views/Utils/Header';
import Loading from "components/views/Utils/Loading";
// routes
import { USER_DETAIL, USER_NEW } from 'routes/app/paths';
// services
import { UsersListCompany } from 'store/user/UserService';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import { useToast } from "hooks/useToast";

const Users = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const [users, setUsers] = useState(null);

  const queryUserList = useQuery(['USERS', { token: user.access_token }], UsersListCompany, {
    onError: (error) => {
      useToast("error", t("Message.error"), t("Message.user.error.load_all"));
    },
    onSuccess: (data) => {
      setUsers(data.data.data);
    }
  });

  const columns = [
    {
      dataField: 'name',
      text: t("User.table.name"),
      sort: true,
      formatter: (cell, row) => (
        <div>{`${row.name} ${row.last_name === null ? "" : row.last_name}`}</div>
      )
    },
    {
      dataField: 'company.name',
      text: t("User.table.company"),
      sort: true
    },
    {
      dataField: 'videos',
      text: t("User.table.send"),
      sort: true,
      formatter: (cell, row) => (
        <Badge
          className={classnames(
            "font-size-12 text-center",
            (cell.length >= 1) ? "badge-soft-success" : "badge-soft-danger",
          )}
          color={(cell.length >= 1) ? "green" : "danger"}
          pill
        >
          {cell.length}
        </Badge>
      )
    }, {
      dataField: 'id',
      text: t("User.table.profile"),
      sort: false,
      formatter: (cell, row) => {
        return (
          <Link
            to={`${USER_DETAIL}/${cell}`}
            className="btn-general-black bg-color-gray text-decoration-none text-black"
            color="app-green">
            {t("User.profile")}
          </Link>
        );
      },
    }
  ];

  return (
    <div className="main-content">
      <MetaTags>
        <title>{t("User.meta")}</title>
      </MetaTags>
      <Container fluid className="content">
        <Row>
          <Col className="col-sm-12 col-md-12 offset-md-0">
            <Header title={t("User.title")} />
            <div className="text-center">
              <Button
                onClick={() => history.push(USER_NEW)}
                className="btn-shadow btn-general bg-color-primary"
                color="app-green">
                {t("User.new")}
              </Button>
            </div>
              {/* {
                queryUserList.isLoading &&
                <Loading style="table" />
              } */}
              {
                users ?
                  <Table data={users} columns={columns} />
                :
                  <Card className="card-container shadow-none bg-color-white mt-4" style={{ maxHeight: 530, minHeight: 400, overflowY: 'scroll' }}></Card>
              }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Users;
