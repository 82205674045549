import React from 'react';
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardText } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

// routes
import {
  TUTORIAL,
  VIDEOS,
  VIDEO_NEW,
  PRESENTATIONS,
  CLIENTS,
  STATISTICS,
  CLIPS,
} from 'routes/app/paths';
// hooks
import useAuthContext from 'hooks/useAuthContext';
import useSplitText from 'hooks/useSplitText';
// images
import video from "assets/images/app/dashboard/video.svg";
import reuse from "assets/images/app/dashboard/reuse.svg";
import clip from "assets/images/app/dashboard/clip.svg";
import presentation from "assets/images/app/dashboard/presentation.svg";
import client from "assets/images/app/dashboard/client.svg";
import statistic from "assets/images/app/dashboard/statistic.svg";

const Dashboard = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  
  return (
    <div className="main-content bg-image-dashboard">
      <MetaTags>
        <title>{t("Video.meta")}</title>
      </MetaTags>
      <Container className="content">
        <Row className="g-0 justify-content-center">
          <Col className="col-sm-12 col-md-8 offset-md-0">
            <Card className="shadow-none mt-5 bg-color-transparent">
              <Row className="g-0 mb-2" style={{ justifyContent: 'center' }}>
                <h1 className="MontSemiBold font-size-md lh-1 text-black text-center">{t("Dashboard.title")} {user.name}</h1>
                <h4 className="MontSemiBold font-size-md lh-3 text-black text-center mt-3">{t("Dashboard.subtitle1")}</h4>
                <h6 className="MontSemiBold font-size-md lh-1 text-black text-center mt-3">{t("Dashboard.subtitle2")}</h6>
              </Row>
              <Row className="g-0 mb-2" style={{ justifyContent: 'center' }}>
                <Card className="shadow-none bg-color-primary-strong mb-1" style={{  }}>
                  <Row className="g-0 justify-content-center m-3" style={{ alignItems: "flex-end"}}>
                    <Col className="col-sm-12 col-md-4 col-12 justify-content-center">
                      <h4 className="font-size-16 lh-1 text-white">{useSplitText(t("Dashboard.text"))}</h4>
                      <h4 className="MontSemiBold font-size-md lh-1 text-white ">{useSplitText(t("Dashboard.manage_videos"))}</h4>
                    </Col>
                    <Col className="col-sm-12 col-md-6 justify-content-center" style={{ display: "contents"}}>
                      <div className="mx-4 mb-1" onClick={() => history.push(VIDEO_NEW)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93}}>
                              <img
                                src={video}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.new_video")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="mx-4 mb-1" onClick={() => history.push(VIDEOS)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93 }}>
                              <img
                                src={reuse}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.reuse_video")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="shadow-none bg-color-primary mb-1" style={{}}>
                  <Row className="g-0 justify-content-center m-3" style={{ alignItems: "flex-end" }}>
                    <Col className="col-sm-12 col-md-4 col-12 justify-content-center">
                      <h4 className="font-size-16 lh-1 text-white">{useSplitText(t("Dashboard.text"))}</h4>
                      <h4 className="MontSemiBold font-size-md lh-1 text-white ">{useSplitText(t("Dashboard.manage_clips"))}</h4>
                    </Col>
                    <Col className="col-sm-12 col-md-6 justify-content-center" style={{ display: "contents" }}>
                      <div className="mx-4 mb-1" onClick={() => history.push(CLIPS)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93 }}>
                              <img
                                src={clip}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.clips")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="mx-4 mb-1" onClick={() => history.push(PRESENTATIONS)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93 }}>
                              <img
                                src={presentation}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.presentations")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card className="shadow-none bg-color-primary-light mb-1" style={{}}>
                  <Row className="g-0 justify-content-center m-3" style={{ alignItems: "flex-end" }}>
                    <Col className="col-sm-12 col-md-4 col-12 justify-content-center">
                      <h4 className="font-size-16 lh-1 text-white">{useSplitText(t("Dashboard.text"))}</h4>
                      <h4 className="MontSemiBold font-size-md lh-1 text-white ">{useSplitText(t("Dashboard.manage_clients"))}</h4>
                    </Col>
                    <Col className="col-sm-12 col-md-6 justify-content-center" style={{ display: "contents" }}>
                      <div className="mx-4 mb-1" onClick={() => history.push(CLIENTS)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93 }}>
                              <img
                                src={client}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.clients")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="mx-4 mb-1" onClick={() => history.push(STATISTICS)} style={{ cursor: "pointer" }}>
                        <Card className="shadow-lg rounded-3 mb-0 bg-color-white" style={{ minWidth: 170, minHeight: 120, maxWidth: 170 }}>
                          <CardBody className="d-flex align-items-center justify-content-center p-1">
                            <CardText className="text-center MontSemiBold" style={{ height: 93 }}>
                              <img
                                src={statistic}
                                alt=""
                                className="img-fluid mx-auto d-block mb-2"
                              />
                              {t("Dashboard.statistics")}
                            </CardText>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Row>
              <div className="text-center">
                <Link
                  to="tutorial"
                  className="MontBold text-black font-size-18"
                >
                  {t("Dashboard.tutorials")}
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
