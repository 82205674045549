import React from "react"
import PropTypes from "prop-types"
import { Row, Col, FormGroup } from "reactstrap"

//SimpleBar
import SimpleBar from "simplebar-react"

import { Link } from "react-router-dom"

import "components/layout/CommonForBoth/rightbar.scss"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "constants/layout"

const RightSidebar = props => {
  const onCloseRightBar = () => {
    const { onClose } = props
    if (onClose) {
      onClose()
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ height: "900px" }}>
        <div data-simplebar className="h-100">
          <div className="rightbar-title px-3 py-4">
            <Link
              to="#"
              onClick={e => {
                onCloseRightBar();
              }}
              className="right-bar-toggle float-end"
            >
              <i className="mdi mdi-close noti-icon" />
            </Link>
            <h5 className="m-0 MontBold">Menú</h5>
          </div>

          <hr className="my-0" />

          <div className="p-4">
            <Link to="/" className="MontSemiBold mb-2 ms-2 me-2 text-black text-decoration-none d-block">Inicio</Link>
            <Link to="/" className="MontSemiBold mb-2 ms-2 me-2 text-black text-decoration-none d-block">La plataforma</Link>
            <Link to="/" className="MontSemiBold mb-2 ms-2 me-2 text-black text-decoration-none d-block">Blog</Link>
            <Link to="/" className="MontSemiBold mb-2 ms-2 me-2 text-black text-decoration-none d-block">Tarifas</Link>
            <Link to="/" className="MontSemiBold mb-2 ms-2 me-2 text-black text-decoration-none d-block">Contacto</Link>
          </div>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

RightSidebar.propTypes = {
  onClose: PropTypes.func,
}


export default RightSidebar;